import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { ApiService } from './api.service';

@Injectable()
export class MailService {

  constructor(
    private apiService: ApiService) { }

  sendContactMail(contatcData): Promise<any[]> {
    return this.apiService.post('/mail/contact-form', contatcData, [])
      .then((response: any[]) => {
        return response;
      });
  }

  async verifyEmail(data): Promise<{ verified: boolean }> {
    return await this.apiService.post("/user/email-verification", data, [])
  }
}
