import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { VenueService } from './venue.service';
import { GenericFormType, GenericFormComponent } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';
import { UserService } from '../user/user.service';
import { UserRoles } from '../data/user.roles.data';
import { NotificationService } from '../common/notification.service';
import { retypePasswordValidator } from '../models/user.model';

import { ActivatedRoute, Router } from '@angular/router';
import { VenueModel } from '../models/venue.model';
import { StorageService } from '../common/storage.service';
@Component({
  selector: 'app-venue-claim',
  templateUrl: './venue.claim.component.html',
  styleUrls: ['./venue.claim.component.scss']
})
export class VenueClaimComponent implements OnInit, AfterViewInit {

  @ViewChild('claimVenueForm') claimVenueForm: GenericFormComponent;

  venue: VenueModel;
  claimInProgress: boolean;
  currentUser: UserModel;
  claimVenueFormRules: GenericFormType[];

  constructor(
    private venueService: VenueService,
    private userService: UserService,
    private authService: AuthService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
  ) { }

  ngAfterViewInit() {
    setTimeout(() => {
      this.updateFormWithStoredData();
      this.claimVenueForm.myForm.valueChanges.subscribe((val) => {
        this.storageService.setItem(this.venue.slug, val);
      });
    }, 1000);
  }

  async ngOnInit() {
    this.claimInProgress = false;
    if (this.authService.isLoggedIn().value) {
      this.authService.logout();
    }
    this.currentUser = this.authService.getCurrentUser();
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.venue = await this.venueService.getBySlug(slug);

    if (this.venue.ownerId) {
      this.navigateBack();
      return;
    }

    this.claimVenueFormRules = [
      // tslint:disable: max-line-length
        GenericFormBuilder.group('authorizer', 'Authorizer \nInformation (Owner, GM, Manager...etc.)', [], null, 'row', 'col-md-12 inline-block claim-restaurant section-heading align-self-center', 'Email and Password will be your Login information. It can be changed later.', 'seaction-heading description'),
        GenericFormBuilder.text('firstName', 'First Name', [Validators.required], !!this.currentUser, null, 'col-md-6 inline-block claim-restaurant required', this.currentUser ? this.currentUser.firstName : ''),
        GenericFormBuilder.text('lastName', 'Last Name', [Validators.required], !!this.currentUser, null, 'col-md-6 inline-block claim-restaurant required', this.currentUser ? this.currentUser.lastName : ''),
        GenericFormBuilder.datepicker('birthDay', 'Date of Birth', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant required'),
        GenericFormBuilder.text('ssn', 'Last 4 SSN', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant required', null, '1234'),
        GenericFormBuilder.text('email', 'Email', [Validators.required], !!this.currentUser, null, 'col-md-6 inline-block claim-restaurant required', this.currentUser ? this.currentUser.email : null, 'example@email.com'),
        GenericFormBuilder.password('password', 'Password', [Validators.required], null, 'col-md-6 inline-block claim-restaurant required'),
        GenericFormBuilder.password('repeatPassword', 'Retype Password', [Validators.required, retypePasswordValidator('password')], null, 'col-md-6 inline-block claim-restaurant required', 'Invalid passwords. Please check your inputs.'),
        GenericFormBuilder.text('referredBy', 'Referred to Cuculi by', [], false, null, 'col-md-6 inline-block claim-restaurant', null, 'Name of the person that introduced you'),

        GenericFormBuilder.group('venue', 'Restaurant \nInformation', [], null, 'row', 'col-md-12 inline-block claim-restaurant section-heading align-self-center'),
        GenericFormBuilder.text('name', 'Restaurant Name', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.name, 'Restaurant Name'),
        GenericFormBuilder.phone('phone', 'Phone Number', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', '', true),
        GenericFormBuilder.text('address', 'Address', [Validators.required], true, null, 'col-md-6 inline-block claim-restaurant', this.venue.location ? this.venue.location.address : '', 'Address'),
        GenericFormBuilder.text('website', 'Website', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.siteUrl),
        GenericFormBuilder.text('taxId', 'Tax ID (numbers only)', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.taxId, '123456789'),
        GenericFormBuilder.text('companyName', 'Legal Business Name (Associated with the Tax ID)', [], null, null, 'col-md-6 inline-block claim-restaurant', this.venue.companyName, 'Skip if same as Restaurant Name'),

        GenericFormBuilder.group('bankAccount', 'Transaction \nInformation', [], null, 'row', 'col-md-12 inline-block claim-restaurant section-heading align-self-center', 'Transaction will be processed and securely protected by STRIPE.', 'section-heading description clickable-tooltip', true, 'fas fa-question', '<p>STRIPE is a secure way to transact payment. For more information visit <a href="https://stripe.com" target="_blank">stripe.com</a></p>'),
        GenericFormBuilder.text('accountHolder', 'Account Holder Name', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', null, 'Account Holder Name'),
        GenericFormBuilder.text('accountRoutingNumber', 'Account Routing Number', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', null, '123456789'),
        GenericFormBuilder.text('accountNumber', 'Account Number', [Validators.required], null, null, 'col-md-6 inline-block claim-restaurant', null, '000987654321'),
      ];
  }

  updateFormWithStoredData() {
    const storedForm = this.storageService.getItem(this.venue.slug);
    console.log(storedForm, 'storedForm');
    if (!!storedForm) {
        this.claimVenueForm.myForm.setValue({
          ... storedForm,
          phone: storedForm.phone.number,
          birthDay: new Date(storedForm.birthDay)
        });
      }
  }

  removeUnwantedCharacters(formData: any) {
    formData.email = formData.email ? formData.email.replace(/\s+/g, '') : null;
    formData.website = formData.website ? formData.website.replace(/\s+/g, '') : null;
    formData.firstName = formData.firstName ? formData.firstName.replace(/\s+/g, '') : null;
    formData.lastName = formData.lastName ? formData.lastName.replace(/\s+/g, '') : null;

    formData.ssn = formData.ssn ? formData.ssn.replace(/\D/g, '') : null;
    formData.taxId = formData.taxId ? formData.taxId.replace(/\D/g, '') : null;
    formData.accountNumber = formData.accountNumber ? formData.accountNumber.replace(/\D/g, '') : null;
    formData.accountRoutingNumber = formData.accountRoutingNumber ? formData.accountRoutingNumber.replace(/\D/g, '') : null;

    return formData;
  }

  customFormValidation(formData: any) {
    if (formData.ssn && formData.ssn.length !== 4) {
      this.notificationService.error('SSN has to have 4 digits');
      return false;
    }
    if (formData.taxId && formData.taxId.length !== 9) {
      this.notificationService.error('Tax ID has to have 9 digits');
      return false;
    }
    if (formData.accountRoutingNumber && formData.accountRoutingNumber.length !== 9) {
      this.notificationService.error('Account Routing Number has to have 9 digits');
      return false;
    }

    return true;
  }

  hasNumber(value: string) {
    return /\d/.test(value);
  }

  async doClaim() {
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.venue = await this.venueService.getBySlug(slug);
    if (this.venue.ownerId) {
      this.notificationService.error('Error', 'This venue has already been claimed');
      this.navigateBack();
    }
    if (this.claimVenueForm.isValid()) {

      let formData = this.claimVenueForm.toObject();
      formData = this.removeUnwantedCharacters(formData);
      if (!this.customFormValidation(formData)) {
        return;
      }
      try {
        this.claimInProgress = true;
        if (!this.authService.isLoggedIn().value) {
          const user: UserModel = new UserModel();
          user.firstName = formData.firstName;
          user.lastName = formData.lastName;
          user.username = formData.email;
          user.email = formData.email;
          user.password = formData.password;
          user.role = UserRoles.REGULAR;
          await this.userService.createTemporaryUser(user);
          await this.authService.login(user.email, user.password);
        }

        let phoneNumber;
        if (formData.phone.number.indexOf(formData.phone.dialCode ) === -1) {
          phoneNumber = formData.phone.dialCode + formData.phone.number;
        } else {
          phoneNumber = formData.phone.number;
        }
        phoneNumber = phoneNumber.replace(/ /g, '');
        if (!formData.companyName) {
          formData.companyName = this.venue.name;
        }
        await this.venueService.claimVenue(
          this.venue,
          formData.accountHolder,
          formData.accountNumber,
          formData.taxId,
          formData.ssn,
          phoneNumber,
          formData.accountRoutingNumber,
          formData.birthDay,
          formData.companyName,
          formData.website,
          formData.referredBy,
          );
        this.claimInProgress = false;
        this.navigateBack();
        this.notificationService.success('Venue succesfully claimed.');
        this.storageService.deleteItem(this.venue.slug);
      } catch (err) {
        this.claimInProgress = false;
        this.currentUser = this.authService.getCurrentUser();
        if (this.currentUser && this.currentUser.role === UserRoles.TEMP) {
          this.authService.logout();
        }
        console.log(err, 'this is err');
        this.notificationService.error('Something went wrong. Failed to claim venue.');
      }
    }
  }

  review = () => window.scroll({ left: 0, top: 0, behavior: 'smooth' });

  navigateBack = () => this.router.navigateByUrl(`/venues/${this.venue.slug}`);

  private generatePass(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
