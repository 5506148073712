import { Component, OnInit, ViewChild, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { EventCreateConstraints, EventModel } from '../models/event.model';
import { EventService } from './event.service';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';
import { EventTypes } from '../data/event.types.data';

@Component({
  selector: 'app-event-create',
  templateUrl: './event.create.component.html'
})
export class EventCreateComponent implements OnInit, AfterViewInit {

  @Input() venueId: string;
  @Output() created: EventEmitter<EventModel> = new EventEmitter();
  @ViewChild('genericForm') genericForm: GenericFormComponent;

  createRules: GenericFormType[] = EventCreateConstraints;
  createInProgress: boolean;
  currentUser: UserModel;

  constructor(
    private eventService: EventService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.createInProgress = false;
    this.currentUser = this.authService.getCurrentUser();
  }

  ngAfterViewInit() {
    this.genericForm.myForm.get('name').setValue(`${this.currentUser.firstName} ${this.currentUser.lastName}`);
    this.genericForm.myForm.get('email').setValue(this.currentUser.email);
    this.genericForm.myForm.get('phone').setValue(this.currentUser.phone);
    this.genericForm.myForm.get('minParticipants').setValue(2);
    this.genericForm.myForm.get('maxParticipants').setValue(8);
    this.genericForm.myForm.get('startTime').setValue('T17:00:00');
  }

  async create() {
    if (this.genericForm.isValid()) {
      this.createInProgress = true;
      try {
        let event: EventModel = this.genericForm.toModel<EventModel>('EventCreateForm', 'EventModel');
        event.type = EventTypes.PRIVATE;
        event.ownerId = this.currentUser.id;
        event.venueId = this.venueId;
        event = await this.eventService.create(event);
        this.created.emit(event);
        this.createInProgress = false;
      } catch (err) {
        this.createInProgress = false;
      }
    }
  }
}
