import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { EventModel } from '../models/event.model';
import { VenueModel } from '../models/venue.model';

@Component({
  selector: 'app-venue-booking-edit',
  templateUrl: './venue.booking.edit.component.html'
})
export class VenueBookingEditComponent implements OnInit {

  venue: VenueModel;
  event: EventModel;

  constructor(public bsModalRef: BsModalRef, private router: Router) {
  }

  ngOnInit() {
    console.log(this.event);
  }

  eventUpdated(event) {
    console.log(event);
    this.bsModalRef.hide();
    this.router.navigateByUrl(`/venues/${this.venue.slug}/bookings`);
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
