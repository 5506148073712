import { Component, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { GenericFormComponent, GenericFormType } from 'src/app/generic-form/generic.form.component';
import { PaymentCreateComponent } from 'src/app/payment/payment.create.component';
import { GenericFormBuilder } from 'src/app/generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { StripeCard } from 'stripe-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueModel } from 'src/app/models/venue.model';
import { VenueService, ITipsRule } from 'src/app/venue/venue.service';
import { BasketService, IBasket } from '../basket/basket.service';
import { UserService } from 'src/app/user/user.service';
import { AuthService } from 'src/app/common/auth.service';
import { OrderService } from 'src/app/order/order.service';
import { NotificationService } from 'src/app/common/notification.service';
import { UserModel } from 'src/app/models/user.model';
import { Countries } from 'src/app/data/countries.data';
import { AutomapperService } from 'src/app/common/automapper.service';
import { MobileService } from '../mobile.service';

@Component({
  selector: 'app-mobile-ordering-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class MobileOrderingCheckoutComponent implements OnInit, AfterViewInit {

  @ViewChild('mayInfoForm') mayInfoForm: GenericFormComponent;
  @ViewChild('addressForm') addressForm: GenericFormComponent;
  @ViewChild('stripeCard') stripeCard: StripeCard;

  options: any = {
    hidePostalCode: true,
    disableLink:true
  };
  venue: VenueModel;
  taxValue = 8.875;
  table: string;
  eventId: string;
  selectedPayment: string;
  availableTips = [18, 20, 25];
  selectedTip: number;
  customTipAmount: string;
  basket: IBasket;
  payInProgress: boolean;
  loggedInUser: UserModel;
  selectedCard: any;
  paymentOptions: any[];
  tipsRule: ITipsRule;
  saveCard = false;
  tableNumberMandatory: boolean = false;

  myInfoRules: GenericFormType[] = [
    GenericFormBuilder.text('firstName', 'First Name', [Validators.required], false, null),
    GenericFormBuilder.text('lastName', 'Last Name', [Validators.required], false, null),
    GenericFormBuilder.text('email', 'Email', [Validators.required], false, null),
    GenericFormBuilder.phone('phone', 'Phone', [Validators.required], false, null),
  ];

  addressFormRules: GenericFormType[] = [
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown('state', 'Country', [Validators.required], [{ value: null, label: 'Please select country' }, ...Countries.map((x) => ({ value: x.code, label: x.name }))], false, null),
    GenericFormBuilder.text('postalCode', 'Zip Code', [Validators.required], false, null),
  ];

  constructor(
    private basketService: BasketService,
    private activeRoute: ActivatedRoute,
    private venueService: VenueService,
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private orderService: OrderService,
    private notificationService: NotificationService,
    private autoMapper: AutomapperService,
    private mobileService: MobileService,
  ) { }

  async ngOnInit() {
    const venueSlug = this.activeRoute.snapshot.paramMap.get('slug');
    this.table = this.activeRoute.snapshot.paramMap.get('table');
    // this.eventId = this.activeRoute.snapshot.paramMap.get('id');
    this.eventId = this.mobileService.eventId;
    this.tableNumberMandatory = await this.mobileService.getEventTableNumberMandatory(this.eventId);
    this.selectedPayment = 'card';
    this.basketService.getBasket().subscribe((value) => {
      this.basket = value;
    });
    this.payInProgress = false;
    await this.loadVenue(venueSlug);
    this.tipsRule = await this.venueService.getVenueTipsRule(this.eventId, 1);
    this.availableTips = this.tipsRule.percentages ? this.tipsRule.percentages : this.availableTips;
    const selectTip = Math.min(...this.availableTips);
    this.selectTip(selectTip);
  }

  async ngAfterViewInit() {
    if (this.mayInfoForm && this.basket) {
      this.mayInfoForm.myForm.get('firstName').setValue(this.basket.firstName);
      this.mayInfoForm.myForm.get('lastName').setValue(this.basket.lastName);
      this.mayInfoForm.myForm.get('phone').setValue(this.basket.phone);
      this.mayInfoForm.myForm.get('email').setValue(this.basket.email);
    }
    this.loggedInUser = this.authService.getCurrentUser();
    if (this.loggedInUser) {
      this.mayInfoForm.myForm.get('firstName').setValue(this.loggedInUser.firstName);
      this.mayInfoForm.myForm.get('lastName').setValue(this.loggedInUser.lastName);
      this.mayInfoForm.myForm.get('phone').setValue(this.loggedInUser.phone);
      this.mayInfoForm.myForm.get('email').setValue(this.loggedInUser.email);
      this.loggedInUser = await this.authService.refreshUserData();
      // tslint:disable-next-line:max-line-length
      this.paymentOptions = this.loggedInUser.payment && this.loggedInUser.payment.sources ? this.loggedInUser.payment.sources.filter(x => !x.hidden) : [];
      this.selectedCard = this.paymentOptions && this.paymentOptions.length ? this.paymentOptions[0] : null;
    }
  }
  @HostListener('window:unload')
  unRecharge() {
    this.deletePaymentInfo();
  }

  selectCard(card: any) {
    this.selectedCard = card;
  }

  selectPaymentOption(option: string) {
    this.selectedPayment = option;
  }

  getItemsSubtotal() {
    if (!this.basket || !this.basket.items || !this.basket.items.length) {
      return 0;
    }
    let sum = 0;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.basket.items.length; i++) {
      sum += this.basket.items[i].price;
    }
    return sum;
  }

  getItemsTax() {
    const sum = this.getItemsSubtotal();
    return Number(sum * (this.taxValue / 100));
  }

  getTipsAmount() {
    if (this.tipsRule.noTipsAllowed) {
      return 0;
    }
    if (!this.selectedTip) {
      return 0;
    }
    if (this.selectedTip === -1) {
      return this.customTipAmount ? Number(this.customTipAmount) : 0;
    }
    const sum = this.getItemsSubtotal();
    return Number(sum * (this.selectedTip / 100));
  }

  getTotalAmount() {
    const subTotal = this.getItemsSubtotal();
    const tax = this.getItemsTax();
    const tips = this.getTipsAmount();
    return Number(subTotal + tax + tips);
  }

  selectTip(tip) {
    if (this.selectedTip !== tip) {
      this.selectedTip = tip;
    }
    this.customTipAmount = '0';
  }

  toggleSaveCard() {
    this.saveCard = !this.saveCard;
  }

  async payOrder() {
    if (!this.mayInfoForm.isValid()) {
      this.notificationService.error('There are some information missing');
      window.scroll(0, 150);
      return;
    }
    const infoData = this.mayInfoForm.toRaw();
    try {
      this.payInProgress = true;

      if (!this.loggedInUser || this.loggedInUser.role.toLowerCase() === 'guest') {
        const user = await this.userService.createGuestUser(infoData.firstName, infoData.lastName, infoData.email, infoData.phone.number);
        this.loggedInUser = this.authService.setCurrentUser(user);
      }
    } catch (err) {
      console.log(err, 'mobile ordering error');
      this.notificationService.error('Failed to create order');
      this.payInProgress = false;
    }

    try {
      let source = null;
      const tips = this.getTipsAmount();
      if (!this.selectedCard) {
        const address = this.addressForm.toRaw();
        source = await this.stripeCard.createToken({
          name: `${infoData.firstName} ${infoData.lastName}`,
          address_state: `${address.state}`,
          address_zip: `${address.postalCode}`
        });
      } else {
        source = this.selectedCard;
      }

      if (!source) {
        this.notificationService.error('Please check your credit card data');
        window.scroll(0, 150);
        this.payInProgress = false;
        return;
      }
      if (this.eventId) {
        if (this.mobileService.tableNumber) {
          await this.orderService.createOrder(this.venue.id, this.table, this.eventId , null, this.mobileService.tableNumber, this.basket.items, source, tips, !this.saveCard);
        } else {
          await this.orderService.createOrder(this.venue.id, this.table, this.eventId , null, null, this.basket.items, source, tips, !this.saveCard);
        }
        // await this.orderService.createOrder(this.venue.id, this.table, this.eventId , null, this.basket.items, source, tips, !this.saveCard);
        console.log('app order');
      }else{
        await this.orderService.createMobileOrders(this.venue.id, this.table, null, this.basket.items, source, tips, !this.saveCard);
        console.log('mobile order');
      }

      this.notificationService.success('Order created, thank you for using Cuculi');
      this.payInProgress = false;
      // tslint:disable-next-line:max-line-length
      this.basketService.updateContact(infoData.firstName, infoData.lastName, infoData.email, infoData.phone ? infoData.phone.number : null);
      this.basketService.clearBasket();
      setTimeout(() => {
        this.router.navigateByUrl(`/mobile-ordering/menu/${this.venue.slug}/${this.table}`);
      }, 3000);
    } catch (err) {
      console.log(err, 'mobile ordering error');
      this.payInProgress = false;
    }
  }
  private deletePaymentInfo () {
    window.localStorage.removeItem("user")
  }

  goBack() {
    const infoData = this.mayInfoForm.toRaw();
    this.basketService.updateContact(infoData.firstName, infoData.lastName, infoData.email, infoData.phone ? infoData.phone.number : null);
    this.router.navigateByUrl(`/mobile-ordering/basket/${this.venue.slug}/${this.table}`);
  }

  private async loadVenue(slug) {
    this.venue = await this.venueService.getBySlug(slug);
  }
}
