import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../common/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '../common/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  allowPasswordChange: boolean;
  resetInProgress: boolean;
  updateInProgress: boolean;
  resetPasswordEmailSent: boolean;
  resetForm: FormGroup;
  updateForm: FormGroup;

  token: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
  ) { }

  async ngOnInit() {
    this.resetForms();
    const rawToken = this.route.snapshot.paramMap.get('token');
    if (rawToken) {
      this.token = encodeURIComponent(rawToken);
      await this.checkTokenValidity(this.token);
    }
  }

  async resetPassword() {
    try {
      await this.authService.resetPassword({email: this.resetForm.get('email').value}, []);
      this.resetPasswordEmailSent = true;
    } catch (err) {
      err.statusCode === 401
        ? this.notificationService.error('Error', 'Found no users matching that e-mail address.')
        : this.notificationService.error('Error', 'Oops, something went wrong.');
      console.log(err);
    }
  }

  async checkTokenValidity(token) {
    try {
      const isPasswordTokenValid = await this.authService.isTemporaryPasswordTokenValid(token, []);
      isPasswordTokenValid
        ? this.allowPasswordChange = true
        : this.allowPasswordChange = false;
    } catch (err) {
      console.log(err);
    }
  }

  async updatePassword() {
    if (this.updateForm.controls.password.value !== this.updateForm.controls.repeatedPassword.value) {
      return this.notificationService.error('Error', 'Passwords do not match');
    }
    const data = {
      password: this.updateForm.controls.password.value,
    };
    const newPassword = await this.authService.updatePassword(this.token, data, []);
    if (newPassword) {
      this.notificationService.success('Success', 'Password succesfully changed.');
      setTimeout(() => this.router.navigateByUrl(''), 300);
    }
  }

  async forgotPassword() {
    this.token = null;
    this.resetPasswordEmailSent = false;
    this.resetForms();
    this.router.navigateByUrl('/reset-password');
  }

  resetForms() {
    this.resetForm = this.fb.group({
      email: [null, Validators.required],
    });

    this.updateForm = this.fb.group({
      password: [null, Validators.required],
      repeatedPassword: [null, Validators.required],
    });
  }

}
