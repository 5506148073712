import { Component, OnInit } from '@angular/core';
import { AuthService } from '../common/auth.service';
import { VenueService } from '../venue/venue.service';
import { UserModel } from '../models/user.model';
import { VenueModel } from '../models/venue.model';
import { ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';
import { WaiterCreateComponent } from './waiter.create.component';
import { WaiterService } from './waiter.service';

@Component({
    selector: 'app-waiters-list',
    templateUrl: './waiters.list.component.html',
    styleUrls: ['./waiters.list.component.scss']
})
export class WaitersListComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private venueService: VenueService,
        private route: ActivatedRoute,
        private modalService: BsModalService,
        private confirmationDialogService: ConfirmationDialogService,
        private waiterService: WaiterService,
    ) { }

    currentUser: UserModel;
    venue: VenueModel;
    waiters: any;
    bsModalRef: BsModalRef;

    async ngOnInit() {
        this.currentUser = await this.authService.getCurrentUser();
        const slug: string = this.route.snapshot.paramMap.get('slug');
        this.venue = await this.venueService.getBySlug(slug);
        this.getAllWaiters();
    }

    async getAllWaiters() {
        this.waiters = await this.waiterService.getAll(this.venue.id);
    }

    openAddNewWaiterModal() {
        const initialState = {
            venue: this.venue,
            onClaimed: this.getAllWaiters.bind(this)
        };
        this.bsModalRef = this.modalService.show(WaiterCreateComponent, { initialState });
        this.bsModalRef.setClass('modal-lg');
    }

    async deleteWaiter(waiter: any) {
        const res = await this.confirmationDialogService.popup(
            `Delete waiter`,
            `Are you sure you want to delete  waiter ${waiter.firstName} ${waiter.lastName}?`
        );
        if (res) {
            await this.waiterService.delete(waiter.id);
            await this.getAllWaiters();
        }
    }

}
