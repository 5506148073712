import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutPageHeaderComponent } from './layout.page.header.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
  ],
  declarations: [LayoutPageHeaderComponent],
  exports: [LayoutPageHeaderComponent]
})
export class LayoutModule { }
