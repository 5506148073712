import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe  implements PipeTransform {
  transform(array: any[], field: string): any[] {
    let order = 'asc';
    let property = field;
    const splitedField = field.split('|');
    if (splitedField.length && splitedField.length > 1) {
      order = splitedField[1];
      property = splitedField[0];
    }
    array.sort((a: any, b: any) => {
      if (a[property] > b[property]) {
        return order === 'asc' ? 1 : -1;
      } else if (a[property] < b[property]) {
        return order === 'asc' ? -1 : 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
