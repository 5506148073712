import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from '../models/user.model';
import { AutomapperService } from './automapper.service';

@Injectable()
export class AuthService {
  user: UserModel;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.hasToken());
  private host: string;

  constructor(private http: HttpClient, private automapper: AutomapperService) {
    this.host = environment.api.host;
  }

  isLoggedIn(): BehaviorSubject<boolean> {
    return this.loggedIn;
  }

  async login(email: string, password: string): Promise<UserModel> {
    const payload: any = {
      email,
      password
    };
    const response = await this.postLogin(payload, []);
    this.user = this.automapper.map('UserApi', 'User', response);
    this.loggedIn.next(true);
    localStorage.setItem('user', JSON.stringify(this.user));
    return this.user;
  }

  async refreshUserData() {
    const response = await this.fetchUser();
    this.user = this.automapper.map('UserApi', 'User', response);
    this.loggedIn.next(true);
    localStorage.setItem('user', JSON.stringify(this.user));
    return this.user;
  }

  getCurrentUser(): UserModel {
    if (!this.user) {
      const localStorageUser = JSON.parse(localStorage.getItem('user'));
      this.user = this.automapper.map('UserStorage', 'User', localStorageUser);
    }
    return this.user;
  }

  setCurrentUser(user: UserModel): UserModel {
    this.user = user;
    this.loggedIn.next(true);
    localStorage.setItem('user', JSON.stringify(this.user));
    return this.user;
  }

  async logout(): Promise<any> {
    this.user = null;
    localStorage.removeItem('user');
    this.loggedIn.next(false);
    return Promise.resolve();
  }

  private hasToken(): boolean {
    this.user = JSON.parse(localStorage.getItem('user'));
    return !!this.user;
  }

  fetchUser() {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = this.setAuthToken(httpHeaders);
    // tslint:disable-next-line:prefer-for-of
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.host + '/user/token', {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  postLogin(data: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.host + '/token', data, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  isTemporaryPasswordTokenValid(token: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.host + `/reset-password/${token}`, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  updatePassword(token: string, data: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.host + `/reset-password/${token}`, data, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  resetPassword(data: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.host + '/reset-password', data, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error.error);
        });
    });
  }

  private setAuthToken(httpHeaders: HttpHeaders): HttpHeaders {
    if (this.isLoggedIn().getValue()) {
      return httpHeaders.set('Authorization', `Bearer ${this.user.token}`);
    }
    return httpHeaders;
  }
}
