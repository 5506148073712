import { Component, OnInit } from '@angular/core';
import { VenueService } from './venue.service';
import { VenueModel } from '../models/venue.model';

@Component({
  selector: 'app-venue-list',
  templateUrl: './venue.list.component.html',
  styleUrls: ['./venue.list.component.scss']
})
export class VenueListComponent implements OnInit {

  venues: VenueModel[];
  nameSearch: string;
  day: string;

  private originalResults: VenueModel[];

  constructor(
    private venueService: VenueService,
    ) { }

  async ngOnInit() {
    this.originalResults = await this.venueService.getAll();
    this.day = this.getDayName(new Date());
    this.search();
  }

  search() {
    if (this.nameSearch) {
      this.venues = this.originalResults
        .filter((x) => x.name.toLowerCase().indexOf(this.nameSearch.toLowerCase()) !== -1);
    } else {
      this.venues = this.originalResults;
    }
  }

  getVenueAlias(name: string): string {
    return null;
  }

  getWorkingHours(venue: VenueModel): string {
    const day = this.day || 'monday';

    if (!venue.workingHours) {
      return 'N/A';
    }
    if (!venue.workingHours[day]) {
      return 'N/A';
    }
    if (!venue.workingHours[day].open) {
      return 'Closed';
    }
    if (!venue.workingHours[day].secondStart && !venue.workingHours[day].secondEnd) {
      return `${venue.workingHours[day].start} - ${venue.workingHours[day].end}`;
    }
    // tslint:disable-next-line:max-line-length
    return `${venue.workingHours[day].start} - ${venue.workingHours[day].end} / ${venue.workingHours[day].secondStart} - ${venue.workingHours[day].secondEnd}`;
  }

  getAddress(venue: VenueModel): string {
    return venue.location.address;
  }

  private getDayName(date) {
    const day = date.getDay();
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[day];
  }
}
