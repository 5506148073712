import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WaitersListComponent } from './waiters.list.component';
import { WaiterCreateComponent } from './waiter.create.component';
import { WaiterService } from './waiter.service';
import { LayoutModule } from '../layout/layout.module';


@NgModule({
    declarations: [
        WaitersListComponent,
        WaiterCreateComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GenericFormModule,
        RouterModule,
        LaddaModule,
        LayoutModule
    ],
    entryComponents: [
        WaitersListComponent,
        WaiterCreateComponent
    ],
    providers: [WaiterService]
})
export class WaiterModule { }
