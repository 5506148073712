import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-ordering-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class MobileOrderingLoadingComponent {

  loadingIcons = [
    'c_American.png',
    'c_Argentinian.png',
    'c_Asian.png',
    'c_Bagels.png',
    'c_Bar Food.png',
    'c_Bar.png',
    'c_Barbecue.png',
    'c_Belgian.png',
    'c_Brazilian.png',
    'c_Burgers.png',
    'c_Caribbean.png',
    'c_Chinese.png',
    'c_Cuban.png',
    'c_Egyptian.png',
    'c_French.png',
    'c_German.png',
    'c_Gluten-Free.png',
    'c_Greek.png',
    'c_Halal.png',
    'c_Indian.png',
    'c_Italian.png',
    'c_Japanese.png',
    'c_Korean.png',
    'c_Kosher.png',
    'c_Latin.png',
    'c_Mediterranean.png',
    'c_Mexican.png',
    'c_Noodles.png',
    'c_Pizza.png',
    'c_Vegan.png',
    'c_Thai.png',
  ];
  currentIcon: string;

  constructor() {
    const d = new Date();
    const n = d.getSeconds();
    if (n > 30) {
      this.currentIcon = this.loadingIcons[n-30];
    } else {
      this.currentIcon = this.loadingIcons[n];
    }
  }
}
