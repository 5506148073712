import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CaptureFromSocialMediaService {
  private host: string;
  constructor(private readonly httpClient: HttpClient) {
    this.host = environment.api.host;
  }

  addSocialMediaUser(
    email: string,
    firstName: string,
    lastName: string,
    socialMedia: string,
    tableName: string
  ) {
    return this.httpClient.post(this.host + "/capture-social-media-user", {
      email,
      firstName,
      lastName,
      socialMedia,
      tableName: tableName || 'join_button_search_tickets'
    });
  }
}
