import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { UserCreateComponent } from './user.create.component';
import { UserService } from './user.service';
import { CommonModule } from '@angular/common';
import { UserBookingsComponent } from './user.bookings.component';
import { EventModule } from '../event/event.module';
import { UserInvitationsComponent } from './user.invitations.component';
import { EventParticipantsComponent } from '../event/event.participants.component';
import { OrderModule } from '../order/order.module';
import { Module as StripeModule } from 'stripe-angular';
import { UserCreateFromInviteComponent } from './user.create.from.invite.component';
import { UserSettingsComponent } from './user.settings.component';
import { PaymentCreateComponent } from '../payment/payment.create.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { LayoutModule } from '../layout/layout.module';
import { PaymentModule } from '../payment/payment.module';
import { UnsubscribeFormComponent } from './unsubscribe-form/unsubscribe.form.component'

@NgModule({
    declarations: [
        UserCreateComponent,
        UserBookingsComponent,
        UserInvitationsComponent,
        UserCreateFromInviteComponent,
        UserSettingsComponent,
        UnsubscribeFormComponent,
        // PaymentCreateComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GenericFormModule,
        AccordionModule,
        LaddaModule,
        EventModule,
        OrderModule,
        StripeModule,
        NgxUsefulSwiperModule,
        LayoutModule,
        PaymentModule
    ],
    providers: [UserService],
    entryComponents: [EventParticipantsComponent, UserCreateFromInviteComponent]
})
export class UserModule { }
