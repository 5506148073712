import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password.component';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
  declarations: [
    ResetPasswordComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, LaddaModule, GenericFormModule, LayoutModule],
  providers: [],
})
export class ResetPasswordModule { }
