import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { CommonModule } from '@angular/common';
import { Module as StripeModule } from 'stripe-angular';
import { PaymentCreateComponent } from '../payment/payment.create.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
    declarations: [
        PaymentCreateComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        GenericFormModule,
        AccordionModule,
        LaddaModule,
        StripeModule,
        NgxUsefulSwiperModule,
        LayoutModule
    ],
    providers: [],
    entryComponents: [PaymentCreateComponent],
    exports: [PaymentCreateComponent]
})
export class PaymentModule { }
