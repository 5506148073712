import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
    selector: 'app-notification-popup',
    templateUrl: './notification.popup.component.html',
    styleUrls: ['./notification.popup.component.scss']
})
export class NotificationPopupComponent implements OnInit {
  title: string;
  message: string;
  messageBold: string;
  imgSrc: string;
  buttonText: string;
  text?: string;
  textTwo?: string;
  confirmText?: string;
  buttonCallback: () => void;

  constructor(
    private bsModalRef: BsModalRef,
  ) { }
  async ngOnInit() {
  }

  async confirmation() {
    this.bsModalRef.hide();
  }

  confirmDialog() {
    if (this.buttonCallback) {
      this.buttonCallback();
    }
    this.bsModalRef.hide();
  }

  async cancel() {
    this.bsModalRef.hide();
  }
}
