import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SearchService, Filters, SearchFilter } from '../search.service';
import { EnumService } from 'src/app/common/enum.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cuisine',
  templateUrl: './cuisine.component.html',
  styleUrls: ['./cuisine.component.scss']
})
export class CuisineComponent implements OnInit, OnDestroy {

  slides: any;
  slideConfig: any;
  cuisineSubscription: Subscription;

  allCuisines: any[];

  filters: Filters;
  cuisineFilter: SearchFilter;

  constructor(
    private searchService: SearchService,
    private enumService: EnumService
  ) { }

  ngOnDestroy(): void {
    this.cuisineSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.allCuisines = await this.enumService.getByType('vendor-categories');

    this.cuisineSubscription = this.searchService.getFiltersObj()
      .subscribe((v: Filters) => {
        this.filters = v;

        if (!this.slides) {
          this.mapCuisinesToSlides(this.allCuisines);
        }

        const existing = this.filters.filters.find((x) => x.name === 'categories');
        if (!existing) {
          this.unselectAllCuisines();
          this.cuisineFilter = {
            label: 'Cuisines',
            type: 'array',
            name: 'categories',
            selected: [],
            options: this.allCuisines,
          };
          this.filters.filters.push(this.cuisineFilter);
        } else {
          this.cuisineFilter = existing;
        }
      });

    this.slideConfig = {
      infinite: false,
      speed: 300,
      slidesToShow: 8,
      slidesToScroll: 3,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 850,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
  }

  mapCuisinesToSlides(cuisines) {
    this.slides = cuisines.map((x) => {
      return {
        key: x.key,
        value: x.value,
        // NOTE(ap): remove once the design for Taiwanese category icon is ready
        img: x.value !== 'Taiwanese' ? `/assets/images/c_${x.value}.png` : "/assets/images/c_Noodles.png",
        selected: false
      };
    });

    this.slides.sort((a, b) => {
      if (a.value < b.value) { return -1; }
      if (a.value > b.value) { return 1; }
      return 0;
    });
  }

  unselectAllCuisines() {
    if (this.slides) {
      this.slides.forEach(element => {
        if (element.selected) {
          element.selected = false;
        }
      });
      return this.slides;
    }
  }

  onCuisineClick(item) {
    item.selected = !item.selected;
    const cuisine = item.key;
    // tslint:disable-next-line:max-line-length
    (item.selected) ? this.cuisineFilter.selected.push(cuisine) : this.cuisineFilter.selected.splice(this.cuisineFilter.selected.findIndex(i => i.key === cuisine.key), 1);
    this.searchService.updateFilters(this.filters);
  }
}
