import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import { Injectable } from '@angular/core';

@Injectable()
export class BasketMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('BasketModel', 'BasketApi')
                .forMember('menuItemId', (opts) => opts.sourceObject.menuItemId)
                .forMember('optionId', (opts) => opts.sourceObject.optionId)
                .forMember('quantity', (opts) => opts.sourceObject.quantity)
                .forMember('addonsIDs', (opts) => opts.sourceObject.addons ? opts.sourceObject.addons.map((x) => x.id) : null)
                .forMember('specialInstructions', (opts) => {
                  return opts.sourceObject.specialInstructions ? opts.sourceObject.specialInstructions : null;
                })
                .ignoreAllNonExisting();
        });
    }
}
