import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { TabsModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { LayoutModule } from '../layout/layout.module';
import { HappyHourService } from './happy-hour.service';
import { HappyHourlistComponent } from './happy-hour.list/happy-hour.list.component';
import { HappyHourCreateComponent } from './happy-hour.create/happy-hour.create.component';
import { HappyHourEditComponent } from './happy-hour.edit/happy-hour.edit.component';

@NgModule({
  declarations: [
    HappyHourlistComponent,
    HappyHourCreateComponent,
    HappyHourEditComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    FileUploadModule,
    TabsModule,
    BsDropdownModule,
    AutoCompleteModule,
    FormsModule,
    LayoutModule,
    TooltipModule.forRoot(),
  ],
  providers: [HappyHourService],
  entryComponents: [
    HappyHourlistComponent,
    HappyHourCreateComponent,
    HappyHourEditComponent
  ],
  exports: [
  ],
  bootstrap: []
})
export class HappyHourModule { }
