import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-payment-method",
  templateUrl: "./payment.method.component.html",
})
export class PaymentMethodComponent implements OnInit {
  stripe: any;
  elements: any;
  isPaymentAvailable: boolean = true;
  publish_key='pk_test_51Pb2fdK1GjPKw2ktU0hV8CLw6s3gTmQLvYRn1Hh0h96DzXF5vJ0RWqyR6rjWfUkVUxByXWDN3uhuerb3IRgb8wgi00cS0rba1w';
  ngOnInit(): void {
    if (typeof window !== 'undefined' && (window as any).Stripe) {
      this.initializeStripe();
    } else {
      // Cargar el SDK de Stripe de forma asincrónica si no está disponible inmediatamente
      const script = document.createElement('script');
      script.src = 'https://js.stripe.com/v3/';
      script.onload = () => this.initializeStripe();
      document.body.appendChild(script);
    }
  }

  initializeStripe(): void {
    const stripe = (window as any).Stripe(this.publish_key);
    this.stripe = stripe;
    this.elements = stripe.elements();
    const paymentRequest = stripe.paymentRequest({
      currency: 'usd',
      country: 'US',
      requestPayerName: true,
      requestPayerEmail: true,
      total: {
        label: 'Demo total',
        amount: 1000,
      }
    });
    const prButton = this.elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest
    })

    paymentRequest.canMakePayment().then((result: any) => {
      if (result) {
        this.isPaymentAvailable = true;
        prButton.mount('#payment-request-button');
      }
    });
  }
}