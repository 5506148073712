import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../common/notification.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AuthService } from '../common/auth.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login.modal.component.html',
  styleUrls: ['./login.modal.component.scss']
})
export class LoginModalComponent implements OnInit {

  loginForm: FormGroup;
  loginInProgress: boolean;
  login: () => void;
  signUp: () => void;
  forgotPassword: () => void;

  forgotPasswordFn: () => void;
  loginFn: () => void;
  signUpFn: () => void;
  returnUrl: string;
  registerUrl: string;
  shouldShowModal: boolean;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.loginInProgress = false;
    this.resetForm();
    console.log(this.forgotPasswordFn, this.loginFn);

    this.login = () => this.loginFn.apply(this);
    this.signUp = () => this.signUpFn.apply(this);
    this.forgotPassword = () => this.forgotPasswordFn.apply(this);
  }

  private resetForm() {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

}
