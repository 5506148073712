import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { OrderModel } from '../models/order.model';
import { BsModalRef } from 'ngx-bootstrap';
import { OrderService } from './order.service';
import { UserService } from '../user/user.service';
@Component({
  selector: 'app-order-bulk-payment',
  templateUrl: './order-bulk.payment.component.html'
})
export class OrderBulkPaymentComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentInformation') paymentInformation: any;

  // passeed from modal client
  orders: OrderModel[];
  // passeed from modal client
  eventId: string;
  // passed from modal client
  userId: string;
  // passed from modal client
  onClose: () => void;

  totalAmount: number;
  chargeInProgress: boolean;
  delayedChargeInProgress: boolean;
  selectedTipPercent: number;
  amount: number;
  tax: number;
  tips: number;
  useCredit: boolean;
  credit: number;

  constructor(
    public bsModalRef: BsModalRef,
    private orderService: OrderService,
    private userService: UserService
  ) { }

  async ngOnInit() {
    const user = await this.userService.getById(this.userId);
    this.credit = user ? user.credit : 0;
    this.totalAmount = 0;
    this.chargeInProgress = false;
    this.delayedChargeInProgress = false;
    this.selectedTipPercent = 0;
    this.recalculateTotalAmount();
  }

  ngAfterViewInit() {
    this.recalculateTotalAmount();
  }

  selectTip(value: number) {
    this.selectedTipPercent = value;
    const amount: number = this.amount;
    this.tips = Math.round((amount * (this.selectedTipPercent / 100)) * 100) / 100;
    this.recalculateTotalAmount();
  }

  removeTipPercent() {
    this.selectedTipPercent = 0;
    this.recalculateTotalAmount();
  }

  selectCredit() {
    this.useCredit = !this.useCredit;
    this.recalculateTotalAmount();
  }

  async recalculateTotalAmount() {
    const credit: number = this.useCredit ? this.credit : 0;
    const tips: number = this.tips ? this.tips : 0;
    let tax = 0;
    let amount = 0;
    this.orders.forEach(item => {
      tax += item.price.taxTotal;
      amount += item.price.value;
    });
    this.amount = amount;
    this.totalAmount = (amount + tax + tips) - credit;
  }

  close() {
    this.bsModalRef.hide();
    if (this.onClose) {
      this.onClose();
    }
  }

  async buy() {
    try {
      this.chargeInProgress = true;
      const source = await this.paymentInformation.getPaymentSource();
      const saveCard = !!this.paymentInformation.get('saveCard').value;
      const res = await this.orderService.payBulkOrder(this.orders, source, this.tips, saveCard, this.eventId, this.useCredit);
      if (!res) {
        this.chargeInProgress = false;
        return;
      }
      alert('Transfer success');
      this.paymentInformation.resetForm();
      this.totalAmount = 0;
      this.chargeInProgress = false;
      this.close();
    } catch (err) {
      console.error(err);
      this.chargeInProgress = false;
    }
  }
}
