import { Injectable } from '@angular/core';

@Injectable()
export class AutomapperService {

  constructor() {}

  map<TOut>(sourceName: string, toName: string, data: any): TOut {
    return automapper.map(sourceName, toName, data);
  }

  mapArray<TOut>(sourceName: string, toName: string, data: any[]): TOut[] {
    const res: TOut[] = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < data.length; i++) {
      res.push(automapper.map(sourceName, toName, data[i]));
    }
    return res;
  }

  register(registerCallback: (mapper: AutoMapperJs.AutoMapper) => void) {
    registerCallback(automapper);
  }
}
