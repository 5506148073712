import { Component, OnInit } from '@angular/core';
import { PagesService } from '../common/pages.service';
import { PageModel } from '../models/page.model';

@Component({
    selector: 'app-intro-component',
    templateUrl: './intro.component.html',
    styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

    page: PageModel;

    constructor(private pagesService: PagesService) { }

    async ngOnInit() {
        this.page = await this.pagesService.getByName('intro');
    }
}
