import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import { Injectable } from '@angular/core';

@Injectable()
export class VenueMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('VenueApi', 'VenueModel')
                .forMember('id', (opts) => opts.mapFrom('id'))
                .forMember('mainImage', (opts) => {
                    if (opts.sourceObject.gallery && opts.sourceObject.gallery.length) {
                        return opts.sourceObject.gallery[0];
                    } else if (opts.sourceObject.mainImage) {
                        return opts.sourceObject.mainImage;
                    } else {
                        return './../../assets/images/banner.jpg';
                    }
                })
                .forMember('coverImage', (opts) => opts.mapFrom('coverImage'))
                .forMember('public', (opts) => opts.mapFrom('public'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('phoneNumber', (opts) => opts.mapFrom('phoneNumber'))
                // .forMember('address', (opts) => opts.sourceObject.location ? opts.sourceObject.location.address : '')
                .forMember('gallery', (opts) => opts.mapFrom('gallery'))
                .forMember('location', (opts) => opts.mapFrom('location'))
                .forMember('siteUrl', (opts) => opts.mapFrom('siteUrl'))
                .forMember('workingHours', (opts) => {
                    if (opts.sourceObject.workingHours) {
                        return {
                            monday: this.automapper.map('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours.monday),
                            tuesday: this.automapper.map('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours.tuesday),
                            wednesday: this.automapper.map('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours.wednesday),
                            thursday: this.automapper.map('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours.thursday),
                            friday: this.automapper.map('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours.friday),
                            saturday: this.automapper.map('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours.saturday),
                            sunday: this.automapper.map('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours.sunday)
                        };
                    }
                })
                .forMember('categories', (opts) => opts.mapFrom('categories'))
                .forMember('features', (opts) => opts.mapFrom('features'))
                .forMember('neighborhood', (opts) => opts.mapFrom('neighborhood'))
                .forMember('attire', (opts) => opts.mapFrom('attire'))
                .forMember('ambience', (opts) => opts.mapFrom('ambience'))
                .forMember('priceRating', (opts) => opts.mapFrom('priceRating'))
                .forMember('noiseLevel', (opts) => opts.mapFrom('noiseLevel'))
                .forMember('tip', (opts) => opts.mapFrom('tip'))
                .forMember('seatingCapacity', (opts) => opts.mapFrom('seatingCapacity'))
                .forMember('timeLimit', (opts) => opts.mapFrom('timeLimit'))
                .forMember('description', (opts) => opts.mapFrom('description'));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('VenueCreateForm', 'VenueModel')
                .forMember('public', (opts) => !!opts.sourceObject.public)
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('location', (opts) => opts.mapFrom('location'))
                .forMember('siteUrl', (opts) => opts.mapFrom('siteUrl'))
                .forMember('phoneNumber', (opts) => opts.sourceObject.phone ? opts.sourceObject.phone.number : null)
                // tslint:disable-next-line:max-line-length
                .forMember('workingHours', (opts) => this.automapper.map('WorkingDaysArray', 'WorkingWeekModel', { days: opts.sourceObject.workingHours }))
                .forMember('categories', (opts) => opts.mapFrom('categories'))
                .forMember('features', (opts) => opts.mapFrom('features'))
                .forMember('neighborhood', (opts) => opts.mapFrom('neighborhood'))
                .forMember('attire', (opts) => opts.mapFrom('attire'))
                .forMember('ambience', (opts) => opts.mapFrom('ambience'))
                .forMember('priceRating', (opts) => opts.mapFrom('priceRating'))
                .forMember('noiseLevel', (opts) => opts.mapFrom('noiseLevel'))
                .forMember('tip', (opts) => opts.mapFrom('tip'))
                .forMember('seatingCapacity', (opts) => opts.mapFrom('seatingCapacity'))
                .forMember('timeLimit', (opts) => opts.mapFrom('timeLimit'))
                .forMember('description', (opts) => opts.mapFrom('description'))
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('VenueRegisterForm', 'VenueModel')
                .forMember('public', (opts) => !!opts.sourceObject.public)
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('location', (opts) => opts.mapFrom('location'))
                .forMember('siteUrl', (opts) => opts.mapFrom('website'))
                .forMember('taxId', (opts) => opts.mapFrom('taxId'))
                .forMember('companyName', (opts) => opts.mapFrom('companyName'))
                // tslint:disable-next-line:max-line-length
                .forMember('phoneNumber', (opts) => {
                    return opts.sourceObject.phone ? `${opts.sourceObject.phone.dialCode + opts.sourceObject.phone.number}` : null;
                })
                .ignoreAllNonExisting();
                // tslint:disable-next-line:max-line-length
                // .forMember('workingHours', (opts) => this.automapper.map('WorkingDaysArray', 'WorkingWeekModel', { days: opts.sourceObject.workingHours }))
                // .forMember('categories', (opts) => opts.mapFrom('categories'))
                // .forMember('features', (opts) => opts.mapFrom('features'))
                // .forMember('neighborhood', (opts) => opts.mapFrom('neighborhood'))
                // .forMember('attire', (opts) => opts.mapFrom('attire'))
                // .forMember('ambience', (opts) => opts.mapFrom('ambience'))
                // .forMember('priceRating', (opts) => opts.mapFrom('priceRating'))
                // .forMember('noiseLevel', (opts) => opts.mapFrom('noiseLevel'))
                // .forMember('tip', (opts) => opts.mapFrom('tip'))
                // .forMember('seatingCapacity', (opts) => opts.mapFrom('seatingCapacity'))
                // .forMember('timeLimit', (opts) => opts.mapFrom('timeLimit'))
                // .forMember('description', (opts) => opts.mapFrom('description'));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('VenueModel', 'VenueEditForm')
                .forMember('public', (opts) => !!opts.sourceObject.public)
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('siteUrl', (opts) => opts.mapFrom('siteUrl'))
                .forMember('categories', (opts) => opts.mapFrom('categories'))
                .forMember('neighborhood', (opts) => opts.mapFrom('neighborhood'))
                // tslint:disable-next-line:max-line-length
                .forMember('workingHours', (opts) => {
                    const data: any = this.automapper.map('WorkingWeekModel', 'WorkingDaysArray', opts.sourceObject.workingHours);
                    return data ? data.days : [];
                })
                .forMember('description', (opts) => opts.mapFrom('description'))
                .forMember('phone', (opts) => opts.mapFrom('phoneNumber'))
                .forMember('priceRating', (opts) => opts.mapFrom('priceRating'));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('VenueModel', 'VenueApi')
                .forMember('id', (opts) => opts.mapFrom('id'))
                .forMember('mainImage', (opts) => opts.mapFrom('mainImage'))
                .forMember('coverImage', (opts) => opts.mapFrom('coverImage'))
                .forMember('public', (opts) => opts.mapFrom('public'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('gallery', (opts) => opts.mapFrom('gallery'))
                .forMember('phoneNumber', (opts) => opts.mapFrom('phone'))
                .forMember('location', (opts) => opts.mapFrom('location'))
                .forMember('siteUrl', (opts) => opts.mapFrom('siteUrl'))
                .forMember('workingHours', (opts) => opts.mapFrom('workingHours'))
                .forMember('features', (opts) => opts.mapFrom('features'))
                .forMember('categories', (opts) => opts.mapFrom('categories'))
                .forMember('neighborhood', (opts) => opts.mapFrom('neighborhood'))
                .forMember('attire', (opts) => opts.mapFrom('attire'))
                .forMember('ambience', (opts) => opts.mapFrom('ambience'))
                .forMember('priceRating', (opts) => opts.mapFrom('priceRating'))
                .forMember('noiseLevel', (opts) => opts.mapFrom('noiseLevel'))
                .forMember('tip', (opts) => opts.mapFrom('tip'))
                .forMember('seatingCapacity', (opts) => opts.mapFrom('seatingCapacity'))
                .forMember('timeLimit', (opts) => opts.mapFrom('timeLimit'))
                .forMember('description', (opts) => opts.mapFrom('description'));
        });
    }
}
