import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { SearchService, SearchFilter, Filters } from '../search.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {

  @Input() neighborhoods: any;
  @Input() features: any;
  @Input() type: string;

  filters: Filters;
  selectedType: string;
  filterSubscription: Subscription;
  dateFilterOptions: any[]  = [
    {
      label: 'Today',
      value: 'today',
      data: {
        from: moment(),
        to: moment().endOf('day'),
      },
    },
    {
      label: 'Tomorrow',
      value: 'tomorrow',
      data: {
        from: moment().add(1, 'days').startOf('day'),
        to: moment().add(1, 'days').endOf('day'),
      }
    },
    {
      label: 'This Week',
      value: 'thisWeek',
      data: {
        from: moment(),
        to: moment().endOf('isoWeek')
      }
    },
    {
      label: 'This Weekend',
      value: 'thiwWeekend',
      data: {
        from: moment().endOf('isoWeek').subtract(2, 'days'),
        to: moment().endOf('isoWeek')
      }
    },
    {
      label: 'Next Week',
      value: 'nextWeek',
      data: {
        from: moment().startOf('isoWeek').add(7, 'days'),
        to: moment().endOf('isoWeek').add(7, 'days').endOf('isoWeek')
      }
    },
    {
      label: 'Next Weekend',
      value: 'nextWeekend',
      data: {
        from: moment().add(1, 'weeks'),
        to: moment().add(1, 'weeks').endOf('isoWeek')
      }
    },
  ];
  guestNumberFilterOptions: any[] = [
    { label: '1 - 4', value: { from: 1, to: 4 }},
    { label: '5 - 9', value: { from: 5, to: 9 }},
    { label: '10+', value: { from: 10, to: 1000 }},
  ];
  priceFilterOptions: any[] = [
    { value: null, label: 'Clear' },
    { value: 1, label: '$' },
    { value: 2, label: '$$' },
    { value: 3, label: '$$$' },
  ];
  speciesOptions: any = [
    { label: 'Food', value: 'food' },
    { label: 'Drink', value: 'drink' },
    { label: 'Bottle', value: 'bottle' }
  ];
  mappedNeighborhoods: any;
  mappedFeatures: any;

  constructor(
    private searchService: SearchService,
  ) { }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  async ngOnInit() {
    this.mappedNeighborhoods = [];
    this.mappedFeatures = [];

    // load filters by type
    this.filterSubscription = this.searchService.getFiltersObj()
      .subscribe((v) => {
        this.filters = v;
        this.loadFiltersByType(this.filters.type);
      });

    this.neighborhoods.forEach(n => this.mappedNeighborhoods.push({
      label: n.value,
      value: n.key
    }));
    this.mappedNeighborhoods.unshift({ value: null, label: 'Clear' });

    this.features.forEach(f => this.mappedFeatures.push({
      label: f.value,
      value: f.key
    }));
    this.mappedFeatures.unshift({ value: null, label: 'Clear' });
  }

  loadFiltersByType(type) {
    switch (type) {
      case 'tables':
        this.fillTableFilters();
        break;
      case 'venues':
        this.fillVenuesFilters();
        break;
      case 'happyHour':
        this.fillHappyHourFilters();
        break;
      default:
        this.fillAllFilters();
    }
  }

  fillTableFilters() {
    this.filters.filters = [
      {
        label: 'Date',
        type: 'dropdown',
        name: 'date',
        selected: [],
        options: this.dateFilterOptions
      },
      {
        label: 'Guests',
        type: 'dropdown',
        name: 'guests',
        selected: [],
        options: this.guestNumberFilterOptions
      },
      {
        label: 'Features',
        type: 'multiSelect',
        name: 'features',
        selected: [],
        options: this.mappedFeatures
      },
      {
        label: 'Neighborhood',
        type: 'multiSelect',
        name: 'neighborhood',
        selected: [],
        options: this.mappedNeighborhoods
      }
    ];
  }

  fillVenuesFilters() {

    const priceRating = this.filters.filters.find((x) => x.name === 'priceRating');
    if (!priceRating) {
      this.filters.filters.push({
        label: 'Price',
        type: 'dropdown',
        name: 'priceRating',
        selected: [],
        options: [
          { value: null, label: 'Clear' },
          { value: 1, label: '$' },
          { value: 2, label: '$$' },
          { value: 3, label: '$$$' },
        ]
      });
    }

    const features = this.filters.filters.find((x) => x.name === 'features');
    if (!features) {
      this.mappedFeatures.forEach(f => f.selected = false);
      this.filters.filters.push({
        label: 'Features',
        type: 'multiSelect',
        name: 'features',
        selected: [],
        options: this.mappedFeatures
      });
    }

    const neighborhood = this.filters.filters.find((x) => x.name === 'neighborhood');
    if (!neighborhood) {
      this.mappedNeighborhoods.forEach(n => n.selected = false);
      this.filters.filters.push({
        label: 'Neighborhood',
        type: 'multiSelect',
        name: 'neighborhood',
        selected: [],
        options: this.mappedNeighborhoods
      });
    }
  }

  fillHappyHourFilters() {
    this.filters.filters = [
      {
        label: 'Date',
        type: 'dropdown',
        name: 'date',
        selected: [],
        options: this.dateFilterOptions
      },
      {
        label: 'Features',
        type: 'multiSelect',
        name: 'features',
        selected: [],
        options: this.mappedFeatures
      },
      {
        label: 'Food/Drink/Bottle',
        type: 'multiSelect',
        name: 'species',
        selected: [],
        options: this.speciesOptions
      }
    ];
  }

  fillAllFilters() {
    this.filters.filters = [];
  }

  getSelectedOption(name: string) {
    let selectedOption = null;
    if (this.filters.filters) {
      const filter = this.filters.filters.find((x) => x.name === name);
      if (filter) {
        selectedOption = filter.options.find(o => !!o.selected && o.value != null);
        return selectedOption ? selectedOption.label : 'Choose';
      }
    }
    return 'Choose';
  }

  selectOption(name, option) {
    this.filters.filters.forEach(f => {
      if (f.name === name) {
        f.options.forEach(o => {
          o.label === option.label ? o.selected = true : o.selected = false;
        });
      }
    });
    this.searchService.updateFilters(this.filters);
  }
}
