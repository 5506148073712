import { Component, OnInit } from '@angular/core';
import { EventModel } from '../models/event.model';
import { EventService } from '../event/event.service';
import { VenueService } from './venue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueModel } from '../models/venue.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { VenueBookingEditComponent } from './venue.booking.edit.component';
import { Statuses } from '../data/statuses.data';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-venue-bookings',
  templateUrl: './venue.bookings.component.html'
})
export class VenueBookingsComponent implements OnInit {

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
    private eventService: EventService,
    private modalService: BsModalService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router) { }

  events: EventModel[] = [];
  venue: VenueModel;
  bsModalRef: BsModalRef;

  getActionsList = ((event: EventModel) => {
    const actions = [];
    actions.push({ label: 'Orders', action: this.goToOrders.bind(this) });
    return actions;
  }).bind(this);

  async ngOnInit() {
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.venue = await this.venueService.getBySlug(slug);
    this.events = await this.eventService.search({ venueId: this.venue.id });
  }

  getEventPrefix(event: EventModel) {
    if (moment(event.startDate).isBefore(moment({ hour: 18, minute: 0 }))) {
        return 'Lunch';
      } else {
        return 'Dinner';
      }
  }

  async approveEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Approve ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to approve ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.approveEvent(event);
      event.eventStatus = response.eventStatus;
    }
  }

  async rejectEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Reject ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to reject ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.rejectEvent(event);
      event.eventStatus = response.eventStatus;
    }
  }

  async goToOrders(event: EventModel) {
    this.router.navigateByUrl(`/bookings/${event.id}`);
  }

  openEventInfo(event: EventModel) {
    const initialState = {
      event,
      venue: this.venue
    };
    const bsModalRef = this.modalService.show(VenueBookingEditComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }
}
