import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-send-to-download-app",
  templateUrl: "./send-to-download-app.component.html",
  styleUrls: ["./send-to-download-app.component.scss"],
})
export class SendToDownloadAppComponent implements OnInit {
  titleLabel = "Example of Tables";
  labelMessage =
    "ONE LAST STEP ";
  labelMessage2 = 
    "TO GO!";
  l1Title = 
    "Seamless Dining: ";
  l1Text = 
    "Simple Hosting";
  l2Title = 
    "Smooth Splitting";
  l2Text = 
    "Easy Payment";
  l3Title = 
    "Various Events";
  l3Text = 
    "Real Connections";
  l4Title = 
    "Real Connections: ";
  l4Text = 
    "Build meaningful relationship.";
  l5Title = 
    "Various Events: ";
  l5Text = 
    "Choose from brunches to galas."; 
  subMessage =
    "Ready for a better way to";
  lastLabel = "dine and socialize?";

  footerMsg = "Get started by downloading the Cuculi app!";
  subFooterMsg = "Your next table is just a tap away!";

  iOS: boolean;

  urlApp: string;

  googleURLApp =
    "https://play.google.com/store/apps/details?id=com.cuculi.p.mobile";
  appleURLApp = "https://apps.apple.com/us/app/cuculi/id1490415047?mt=8";

  public getScreenWidth: any;
  public getScreenHeight: any;
  public isMobile: boolean;
  constructor() {}

  ngOnInit() {
    this.iOS = this.checkIfIOS();
    this.urlApp = this.iOS ? this.appleURLApp : this.googleURLApp;
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.isMobile = this.getScreenWidth < 768;
  }

  checkIfIOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  goToDownload() {
    window.location.href = this.urlApp;
  }
}
