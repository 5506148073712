import { Component, OnInit, Input } from '@angular/core';
import { UserModel } from 'src/app/models/user.model';
import * as moment from 'moment'
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import * as momentTz from 'moment-timezone';

@Component({
  selector: 'app-explore-tables',
  templateUrl: './explore.tables.component.html',
  styleUrls: ['./explore.tables.component.scss']
})
export class ExploreTablesComponent implements OnInit {

  @Input() neighborhoods: any;
  @Input() tables: any;
  @Input() pastTables: any;
  @Input() groupedData: any;

  user: UserModel;
  slideConfig: any;
  cuisines: any[];
  day: string;
  notFirst: boolean;
  getTheAppContent = {
    containerClass: 'download-container',
    text: 'Discover your favorite events, ',
    boldText: 'click to join now!',
    buttonText: 'JOIN'
  };
  moment = moment;
  comeFromSocialMedia
  constructor(private route: ActivatedRoute) { }

  async ngOnInit() {

    this.route.queryParamMap.pipe(take(1)).subscribe((paramsMap: any) => {
      this.comeFromSocialMedia = paramsMap.get("socialmedia");
    });

    this.day = this.getDayName(new Date());

    this.slideConfig = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
  }

  getDateTz(table: any): string {
    const timeZone = table.venue.timeZone ? table.venue.timeZone : 'America/New_York';
    const startZ = momentTz(table.startDate).tz(timeZone).format("dddd, MMMM Do, YYYY / h:mm a");
    return startZ;
  }

  getAddress(table: any): string {
    const neighborhood = this.neighborhoods.find(n => n.key === table.venue.neighborhood);
    return neighborhood ? neighborhood.value : table.venue.location.city;
  }

  getParticipantsNumbers(table: any): string {
    const oneOrMore = table.maxParticipants === 1 ? 'seat' : 'seats';
    return `${table.participants.length}/${table.maxParticipants} ${oneOrMore}`;
  }

  private getDayName(date: Date) {
    const day = date.getDay();
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    return days[day];
  }

  setLeft(i) {
    const left = 15 * (i + 1);
    return `${left}px`;
  }
}
