import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/common/auth.service';
import { INotificationConfigType, NotificationConfigTypes } from 'src/app/data/notification.config.types';
import { UserModel } from 'src/app/models/user.model';
import { UserService } from '../user.service';

@Component({
  selector: 'app-unsubscribe-form',
  templateUrl: './unsubscribe.form.component.html',
  styleUrls: ['./unsubscribe.form.component.scss'],
})
export class UnsubscribeFormComponent implements OnInit {
  currentUser: UserModel;
  reasonList = [
    {
      id: 0,
      name: 'reason',
      value: 'Too much emails',
      label: 'Too much emails',
    },
    {
      id: 1,
      name: 'reason',
      value: 'Not interested in Social Tables',
      label: 'Not interested in Social Tables',
    },
    {
      id: 2,
      name: 'reason',
      value: 'Temporarily',
      label: 'Temporarily',
    },
    {
      id: 3,
      name: 'reason',
      value: 'Other',
      label: 'Other',
    },
  ];

  unsubForm = new FormGroup({
    reason: new FormControl('', Validators.required),
  });

  constructor(private authService: AuthService, private userService: UserService) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getCurrentUser();
    console.log(this.currentUser.id);
  }

  get f() {
    return this.unsubForm.controls;
  }

  async submit() {
    const newConfig: INotificationConfigType = {
      type: NotificationConfigTypes.EVENT_ANNOUNCEMENT,
      email: false,
    };
    try {
      await this.userService.updateUserNotificationConfigs(
        this.currentUser.id,
        [newConfig],
        this.unsubForm.value.reason
      );
    } catch (error) {
      console.error(error);
    }
  }
}
