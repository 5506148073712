import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StripeCard } from 'stripe-angular';
import { BsModalRef } from 'ngx-bootstrap';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html'
})
export class PaymentInformationComponent implements OnInit {
  @ViewChild('stripeCard') stripeCard: StripeCard;

  existingCreditCards: any[];
  selectedCreditCard: any;
  newUserForm: boolean;
  newCreditCardForm: boolean;
  currentUser: UserModel;
  showCCOptions: boolean;

  options: any = {
    hidePostalCode: true,
    disableLink:true
  };

  paymentForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public bsModalRef: BsModalRef,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.resetForm();
    this.showCCOptions = false;
    this.newCreditCardForm = false;
    this.newUserForm = false;
    this.reloadUserData();
  }

  async reloadUserData() {
    const user = await this.userService.getById(this.currentUser.id);
    this.newUserForm = false;
    this.newCreditCardForm = false;
    if (user.payment) {
      if (!user.payment.sources || !user.payment.sources.length) {
        this.newUserForm = false;
        this.newCreditCardForm = true;
      } else {
        const savedCards = user.payment.sources.filter((x) => !x.hidden);
        if (savedCards && savedCards.length) {
          this.existingCreditCards = savedCards;
          this.selectedCreditCard = savedCards[0];
          this.newUserForm = false;
          this.newCreditCardForm = false;
        } else {
          this.newCreditCardForm = true;
          this.newUserForm = false;
        }
      }
    } else {
      this.newUserForm = true;
      this.newCreditCardForm = true;
    }
  }

  resetForm() {
    this.paymentForm = this.fb.group({
      email: [this.currentUser.email, [Validators.required]],
      firstName: [this.currentUser.firstName, [Validators.required]],
      lastName: [this.currentUser.lastName, [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      address: ['', [Validators.required]],
      phone: [this.currentUser.phone, [Validators.required]],
      credit: [0],
      useCredit: [false],
      selectedCardId: [''],
      saveCard: [false],
    });
    this.existingCreditCards = null;
    this.selectedCreditCard = null;
    this.newCreditCardForm = false;
    this.newUserForm = false;
    this.showCCOptions = false;
  }

  public async getPaymentSource() {
    let result;
    if (!this.selectedCreditCard) {
      result = await this.stripeCard.createToken({
        name: `${this.paymentForm.get('firstName').value} ${this.paymentForm.get('lastName').value}`,
        address_city: `${this.paymentForm.get('city').value}`,
        address_line1: `${this.paymentForm.get('address').value}`,
        address_state: `${this.paymentForm.get('state').value}`,
        address_zip: `${this.paymentForm.get('zipCode').value}`
      });
    } else {
      result = this.selectedCreditCard;
    }

    return result;
  }

  get(formControlName) {
    return this.paymentForm.get(formControlName).value;
  }

  set(formControlName, value) {
    this.paymentForm.get(formControlName).setValue(value);
  }

  openCCOptions() {
    this.showCCOptions = true;
    this.paymentForm.get('selectedCardId').setValue(this.selectedCreditCard.id);
  }

  selectCC() {
    const selectedCardId = this.paymentForm.get('selectedCardId').value;
    const card = this.existingCreditCards.find((x) => {
      return x.id === selectedCardId;
    });
    if (card) {
      this.selectedCreditCard = card;
      this.showCCOptions = false;
    } else {
      this.newCard();
    }
  }

  newCard() {
    this.existingCreditCards = null;
    this.selectedCreditCard = null;
    this.newCreditCardForm = true;
    this.newUserForm = true;
    this.showCCOptions = false;
  }
}
