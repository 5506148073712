import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NotificationService } from '../common/notification.service';
import { LoginModalComponent } from './login.modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginInProgress: boolean;
  shouldShowModal: boolean;

  @Input() returnUrl: string;
  @Input() registerUrl: string;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.loginInProgress = false;
    this.resetForm();
    this.shouldShowModal = false;

    if (this.router.url.indexOf('returnUrl') !== -1) {
      // tslint:disable-next-line:no-string-literal
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
      this.shouldShowModal = true;
      this.toggleLoginModal();
    } else {
      this.returnUrl = '/events';
    }

    const currentUser = this.authService.getCurrentUser();
    if (currentUser) {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  toggleLoginModal() {
    const initialState = {
      class: 'modal-dialog-border-bottom',
      loginFn: this.login,
      signUpFn: this.signUp,
      forgotPasswordFn: this.forgotPassword,
      returnUrl: this.returnUrl,
      registerUrl: this.registerUrl,
    };
    const bsModalRef = this.modalService.show(LoginModalComponent, { initialState });
    bsModalRef.setClass(initialState.class);
  }

  signUp() {
    this.registerUrl = this.registerUrl || '/register-user';
    if (this.returnUrl) {
      this.router.navigateByUrl(`${this.registerUrl}?returnUrl=${this.returnUrl}`);
    } else {
      this.router.navigateByUrl(`${this.registerUrl}`);
    }
    this.bsModalRef.hide();
  }

  async login() {
    if (!this.loginForm.valid) {
      return;
    }
    this.loginInProgress = true;
    try {
      const username = this.loginForm.get('username').value;
      const password = this.loginForm.get('password').value;
      await this.authService.login(username, password);
      this.router.navigateByUrl(this.returnUrl);
      this.loginInProgress = false;
      this.bsModalRef.hide();
    } catch (err) {
      this.loginInProgress = false;
      this.notificationService.clear();
      this.notificationService.error('User Sign In', 'Please check your credentials');
    }
  }

  async forgotPassword() {
    this.router.navigateByUrl('/reset-password');
    this.bsModalRef.hide();
  }

  private resetForm() {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }
}
