import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-address-search',
  templateUrl: './address.search.component.html',
  styleUrls: ['./address.search.component.scss']
})
export class AddressSearchComponent implements OnInit {

  @Output() keyword: EventEmitter<string> = new EventEmitter();

  searchKeyword: string;
  mobileView: boolean;

  constructor(
  ) {}

  ngOnInit() {
    this.mobileView = window.innerWidth < 768;
  }

  search() {
    this.keyword.emit(this.searchKeyword);
  }
}
