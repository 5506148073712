import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(
    private apiService: ApiService,
    private automapper: AutomapperService,
  ) {
    
  }

  async getAllVenuesNames() {
    const res = await this.apiService.get('/venue', []);
    const names = res.map(obj => obj.name);
    return names;
  }

  async getAllActivesVenuesNames() {
    const res = await this.apiService.get('/venue', []);
    const filteredRestaurants = res.filter(restaurant => restaurant.active === true && restaurant.ownerId != null && restaurant.isBookingEnabled === true);
    const names = filteredRestaurants.map(obj => obj.name);
    return names;
  }

  async buyVoucher(payload: {
    loggedUser: string;
    amount: number;
    venueName: string;
    validUntil: string;
    quantity: number;
    customer: {
      firstName: string,
      lastName: string,
      phone: string,
      email: string,
      cardToken: string,
      cardId: string,
    };
  }) {

    const data = {
      loggedUser: payload.loggedUser,
      amount: payload.amount,
      venueName: payload.venueName,
      validUntil: payload.validUntil,
      quantity: payload.quantity,
      customer: {
        firstName: payload.customer.firstName,
        lastName: payload.customer.lastName,
        phone: payload.customer.phone,
        email: payload.customer.email,
        cardToken: payload.customer.cardToken,
        cardId: payload.customer.cardId,
      },
    };

    try {
      const response = await this.apiService.post(
        `/voucher/buy`,
        data,
        []
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  }
}
