import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class PageMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('PageApi', 'PageModel')
                .forMember('createdAt', (opts) => moment(opts.sourceObject.createdAt).format(environment.dateFormat));
        });
    }
}
