import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { GenericFormComponent, GenericFormType, Lookup } from '../generic-form/generic.form.component';
import { BsModalRef } from 'ngx-bootstrap';
import { MenuService } from '../menu-item/menu.service';
import { VenueModel } from '../models/venue.model';
import { MenuModel } from '../models/menu.model';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { MenuItemModel } from '../models/menu-item.model';

@Component({
  selector: 'app-menu-category-copy',
  templateUrl: './menu-category.copy.component.html',
  styleUrls: ['../menu-item/menu.component.scss']
})
export class MenuCategoryCopyComponent implements OnInit, AfterViewInit {

  @ViewChild('menuCategoryForm') menuCategoryForm: GenericFormComponent;

  copyInProgress: boolean;
  venue: VenueModel;
  menu: MenuModel;
  updateRules: GenericFormType[];
  loadMenus: any;
  menuTypes: any;
  currentType: Lookup;
  menuTypeLabels: any[];

  constructor(
    private bsModalRef: BsModalRef,
    private menuService: MenuService,
  ) { }

  async ngOnInit() {
    this.copyInProgress = false;
    this.updateRules = [
      GenericFormBuilder.text('name', 'Copy From', [], true),
      GenericFormBuilder.dropdown('copyTo', 'Copy To', [], this.getMenuTypes())
    ];
  }

  ngAfterViewInit() {
    this.menuCategoryForm.fromModel<MenuModel>('MenuModel', 'MenuCategoryCopyForm', this.menu);
  }

  getMenuTypes() {
    this.menuTypeLabels = [];
    this.menuTypes.forEach((element, index) => {
      if (element.value !== this.menu.name) {
        this.menuTypeLabels.push({
            value: element.key,
            label: element.value,
        });
      }
    });
    return this.menuTypeLabels;
  }

  dataChanged(data) {
    this.currentType = data.type;
  }

  async copy() {
    this.copyInProgress = true;
    try {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.menu.categories.length; i++) {
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < this.menu.categories[i].items.length; j++) {
          delete this.menu.categories[i].items[j].id;
          delete this.menu.categories[i].items[j].createdAt;
          delete this.menu.categories[i].items[j].status;
          delete this.menu.categories[i].items[j].avgRating;
          // tslint:disable-next-line:max-line-length
          this.menu.categories[i].items[j].type = this.menuCategoryForm.myForm.value.copyTo ? this.menuCategoryForm.myForm.value.copyTo : this.menuTypeLabels[0].value;
          // tslint:disable-next-line:max-line-length
          this.menu.categories[i].items[j].category = this.menu.categories[i].items[j].category ? this.menu.categories[i].items[j].category : this.menu.categories[i].name;
          await this.menuService.create(this.menu.categories[i].items[j], this.venue.id);
        }
      }
      this.copyInProgress = false;
      this.loadMenus();
      this.bsModalRef.hide();
    } catch (err) {
      this.copyInProgress = false;
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
