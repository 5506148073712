import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { UserModel } from '../models/user.model';
import { UserService } from './user.service';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserRoles } from '../data/user.roles.data';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators, FormGroup, AbstractControl } from '@angular/forms';
import { StripeCard } from 'stripe-angular';
import { PaymentCreateComponent } from '../payment/payment.create.component';
import { NotificationService } from '../common/notification.service';

@Component({
  selector: 'app-user-create-from-invite',
  templateUrl: './user.create.from.invite.component.html',
})
export class UserCreateFromInviteComponent implements AfterViewInit {

  @ViewChild('genericForm') genericForm: GenericFormComponent;
  @ViewChild('paymentCreate') paymentCreate: PaymentCreateComponent;

  createRules: GenericFormType[] = [
    GenericFormBuilder.text('email', 'Email', [Validators.required], true, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('firstName', 'First Name', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('lastName', 'Last Name', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.password('password', 'Password', [Validators.required], null, 'col-md-6 inline-block'),
    GenericFormBuilder.password('repeatPassword', 'Retype Password', [Validators.required, (control: AbstractControl): any => {
      if (control.parent) {
        const form: FormGroup = control.parent as FormGroup;
        const password = form.get('password').value;
        const repeatPassword = control.value;
        return (password !== repeatPassword) ? { invalidRetypePassword: { value: control.value } } : null;
      }
    }], null, 'col-md-6 inline-block'),
    GenericFormBuilder.phone('phone', 'Phone', [], false, null, 'col-md-6 inline-block'),
  ];
  invitedUser: UserModel;
  createInProgress: boolean;
  loading = true;
  stripeValid = false;

  options: any = {
    hidePostalCode: true
  };

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private cr: ChangeDetectorRef,
  ) { }

  async ngAfterViewInit() {
    this.createInProgress = false;
    const id: string = this.route.snapshot.paramMap.get('id');
    this.invitedUser = await this.userService.getById(id);
    this.cr.detectChanges();
    this.loading = false;
    if (this.invitedUser) {
      this.genericForm.fromModel<UserModel>('UserModel', 'UserCreateForm', this.invitedUser);
    }
  }

  async create() {
    if (!this.paymentCreate.isValid() || !this.genericForm.isValid()) {
      return null;
    }
    this.createInProgress = true;
    const stripeSource = await this.paymentCreate.getPaymentSource();
    if (!stripeSource) {
      this.createInProgress = false;
      this.notificationService.warning('Invalid card', 'Something went wrong, card can not be verified please try again later');
      return null;
    }
    if (this.genericForm.isValid()) {
      this.createInProgress = true;
      try {
        this.invitedUser = this.genericForm.toModel<UserModel>('UserCreateForm', 'UserModel');
        this.invitedUser.role = UserRoles.REGULAR;
        this.invitedUser.payment = {
          source: stripeSource,
        };
        this.invitedUser.username = this.invitedUser.email;
        await this.userService.create(this.invitedUser);
        await this.authService.login(this.invitedUser.email, this.invitedUser.password);
        this.router.navigateByUrl('/invitations');
      } catch (err) {
        this.createInProgress = false;
      }
    }
  }
}
