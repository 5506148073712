import { Component, OnInit, Input } from '@angular/core';
import { EventModel } from '../models/event.model';
import { AuthService } from '../common/auth.service';
import { EventService } from './event.service';
import { UserModel } from '../models/user.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-event-participants',
  templateUrl: './event.participants.component.html'
})
export class EventParticipantsComponent implements OnInit {

  @Input() event: EventModel;
  currentUser: UserModel;
  emailForm: FormGroup;
  inviteInProgress: boolean;
  autocompleteText: string;
  autocompleteResults: string[];

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private eventService: EventService,
    private fb: FormBuilder,
    private userService: UserService) { }

  ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.inviteInProgress = false;
  }

  async inviteParticipant() {
    const email = this.autocompleteText; // this.emailForm.get('email');
    if (email) {
      this.inviteInProgress = true;
      try {
        this.event = await this.eventService.inviteParticipant(this.event, email);
        this.inviteInProgress = false;
      } catch (err) {
        this.inviteInProgress = false;
      }
    }
  }

  async removeInivitation(email: string) {
    if (this.event.invitedParticipants.indexOf(email) !== -1) {
      await this.eventService.removeInvitation(this.event, email);
    }
  }

  cancel() {
    this.bsModalRef.hide();
  }

  async searchUsers(event) {
    const users: UserModel[] = await this.userService.findByKeyword(event.query);
    this.autocompleteResults = users.map((x) => x.email);
  }
}
