import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { VenueCreateComponent } from './venue.create.component';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { VenueService } from './venue.service';
import { LocationInputComponent } from '../location/location.input.component';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VenueListComponent } from './venue.list.component';
import { CarouselModule, TimepickerModule, BsDatepickerModule, BsDropdownModule, BsModalService, BsModalRef } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { ImagePreloadDirective } from '../common/img.preload.directive';
import { VenueProfileComponent } from './venue.profile.component';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { TabsModule } from 'ngx-bootstrap';
import { VenueBookingComponent } from './venue.booking.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { VenueEditComponent } from './venue.edit.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { EventModule } from '../event/event.module';
import { VenueBookingsComponent } from './venue.bookings.component';
import { VenueGalleryComponent } from './venue.gallery.component';
import { ImageViewComponent } from './image.view.component';
import { VenueBookingEditComponent } from './venue.booking.edit.component';
import { VenueClaimComponent } from './venue.claim.component';
import { VenueMenuComponent } from './venue.menu.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '../layout/layout.module';
import { HappyHourModule } from '../happy-hour/happy-hour.module';
import { MenuCategoryEditComponent } from '../menu-item/menu-category.edit.component';
import { DropdownModule } from 'primeng/dropdown';
import { CreateTableComponentComponent } from './create-table-component/create-table-component.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MenuCategoryCopyComponent } from '../menu-category/menu-category.copy.component';
import { VenueRegisterComponent } from '../venue/venue.register.component';
import { NotificationPopupComponent } from '../common/notification.popup.component';
import { LoginModule } from '../login/login.module';
import { StorageService } from '../common/storage.service';
import { SearchModule } from '../search/search.module';
import { VenueAuxFormComponent } from '../venue-aux-form/venue-aux-form.component'
// import { GetTheAppComponent } from '../common/get-the-app/get-the-app.component';
@NgModule({
    declarations: [
        VenueCreateComponent,
        LocationInputComponent,
        VenueListComponent,
        ImagePreloadDirective,
        VenueProfileComponent,
        VenueBookingComponent,
        VenueEditComponent,
        VenueBookingsComponent,
        VenueGalleryComponent,
        ImageViewComponent,
        VenueBookingEditComponent,
        VenueClaimComponent,
        VenueMenuComponent,
        CreateTableComponentComponent,
        VenueRegisterComponent,
        NotificationPopupComponent,
        VenueAuxFormComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GenericFormModule,
        LaddaModule,
        AgmCoreModule,
        CarouselModule,
        RouterModule,
        FileUploadModule,
        TabsModule,
        TimepickerModule,
        NgxIntlTelInputModule,
        BsDatepickerModule,
        UiSwitchModule,
        EventModule,
        BsDropdownModule,
        DropdownModule,
        DragDropModule,
        LayoutModule,
        HappyHourModule,
        NgxSpinnerModule,
        LoginModule,
        SearchModule
    ],
    entryComponents: [
        VenueBookingComponent,
        ImageViewComponent,
        VenueBookingEditComponent,
        VenueClaimComponent,
        VenueRegisterComponent,
        MenuCategoryEditComponent,
        MenuCategoryCopyComponent,
        NotificationPopupComponent
    ],
    providers: [VenueService, BsModalService, BsModalRef, StorageService],
})
export class VenueModule { }
