import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import { LoginComponent } from '../../login/login.component';
import { AuthService } from 'src/app/common/auth.service';
import { UserModel } from 'src/app/models/user.model';
import { MobileService } from '../mobile.service';

@Component({
  selector: 'app-mobile-ordering-home',
  templateUrl: './home.component.html',
  styleUrls: ['../mobile-ordering.styles.scss']
})
export class MobileOrderingHomeComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private authService: AuthService,
    private mobileService: MobileService,
  ) { }

  playStoreUrl = 'https://play.google.com/store/apps/details?id=com.cuculi.p.mobile';
  appStoreUrl = 'https://apps.apple.com/us/app/cuculi/id1490415047?mt=8';
  table: string;
  venueSlug: string;
  loggedUser: UserModel;
  eventId: string;

  async ngOnInit() {
    this.table = this.route.snapshot.paramMap.get('table');
    this.venueSlug = this.route.snapshot.paramMap.get('venueSlug');
    this.loggedUser = this.authService.getCurrentUser();
    this.eventId = this.route.snapshot.paramMap.get('id');
    if (this.route.snapshot.fragment) {
      this.eventId = this.route.snapshot.fragment.slice(-24);
    }
    this.mobileService.eventId = this.eventId;
  }

  openMenuScreen(orderAsGuest) {
    if (orderAsGuest && !this.isCurrentUserGuest()) {
      this.authService.logout();
    }
    this.router.navigateByUrl(`/mobile-ordering/menu/${this.venueSlug}/${this.table}`);
  }

  redirectToAppStore() {
    const platform = this.getMobileOperatingSystem();

    if (platform && platform === 'Android') {
      window.open(this.playStoreUrl, '_self');
    }

    if (platform && platform === 'IOS') {
      window.open(this.appStoreUrl, '_self');
    }
  }

  isCurrentUserGuest() {
    return !this.loggedUser || this.loggedUser.role.toLowerCase() === 'guest';
  }

  getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'IOS';
    }
  }
}
