import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { EventService } from './event.service';
import { EventCreateComponent } from './event.create.component';
import { EventListComponent } from './event.list.component';
import { CarouselModule, TabsModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { EventParticipantsComponent } from './event.participants.component';
import { EventEditComponent } from './event.edit.component';
import { EventOrdersComponent } from './event.orders.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { LayoutModule } from '../layout/layout.module';
import { AddonsInstructionsTooltipComponent } from './addons-instructions-tooltip/addons.tooltip.component';
import { EventProfileComponent } from './event.profile.component';
import { RouterModule } from '@angular/router';
import { EventTicketsComponent } from './tickets/event.tickets.component';
import { PaymentModule } from '../payment/payment.module';
import { MomentModule } from 'ngx-moment';
import { RsvpComponent } from './rsvp/rsvp.component';
import { showMapComponent } from './show-map-component/show-map-component';

@NgModule({
  declarations: [
      EventCreateComponent,
      EventListComponent,
      EventParticipantsComponent,
      EventEditComponent,
      EventOrdersComponent,
      AddonsInstructionsTooltipComponent,
      EventProfileComponent,
      EventTicketsComponent,
      RsvpComponent,
      showMapComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    FileUploadModule,
    TabsModule,
    BsDropdownModule,
    AutoCompleteModule,
    FormsModule,
    LayoutModule,
    TooltipModule.forRoot(),
    CarouselModule,
    RouterModule,
    PaymentModule,
    MomentModule
  ],
  providers: [EventService],
  exports: [
    EventCreateComponent,
    EventListComponent,
    EventParticipantsComponent,
    EventEditComponent,
    EventOrdersComponent,
    EventProfileComponent,
    EventTicketsComponent
  ],
  bootstrap: [AddonsInstructionsTooltipComponent]
})
export class EventModule { }
