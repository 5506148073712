import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { VenueModel } from '../models/venue.model';
import { VenueService } from './venue.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FileUploadComponent } from '../file.upload/file.upload.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ImageViewComponent } from './image.view.component';

@Component({
  selector: 'app-venue-gallery',
  templateUrl: './venue.gallery.component.html',
  styleUrls: ['./venue.gallery.component.scss']
})
export class VenueGalleryComponent implements OnInit, AfterViewInit {
  @ViewChild('fileUpload') fileUpload: FileUploadComponent;
  updateInProgress: boolean;
  venue: VenueModel;
  bsModalRef: BsModalRef;
  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService
  ) { }

  async ngOnInit() {
    this.updateInProgress = false;
    await this.getAndSetVenue();
  }

  async getAndSetVenue() {
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.venue = await this.venueService.getBySlug(slug);
  }

  ngAfterViewInit(): void {}

  openImageModal(url) {
    const initialState = {
       url
    };
    this.bsModalRef = this.modalService.show(ImageViewComponent, {initialState});
    this.bsModalRef.setClass('modal-xl');
  }

  async removeFile(index) {
    this.updateInProgress = true;
    this.venue.gallery.splice(index, 1);
    await this.venueService.updateGallery(this.venue.id, this.venue.gallery);
    await this.getAndSetVenue();
    this.updateInProgress = false;
  }

  back() {
    this.router.navigateByUrl('/venues');
  }

  async upload() {
    if (true) {
      this.updateInProgress = true;
      try {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.fileUpload.filesData.length; i++) {
          await this.venueService.uploadPhoto(this.venue.id, this.fileUpload.filesData[i]);
          await this.getAndSetVenue();
          this.updateInProgress = false;
        }
        this.fileUpload.filesData.length = 0;
      } catch (err) {
        this.updateInProgress = false;
      }
    }
  }
}
