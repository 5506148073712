import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';

@Injectable()
export class CustomerService {

  constructor(
    private apiService: ApiService) {
  }

  getCustomer(email: string): Promise<any> {
    return this.apiService.get(`/customer/${email}`, [])
        .catch(() => {
            return null;
        });
  }
}
