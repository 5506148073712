import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class OrderMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('OrderApi', 'OrderModel')
                .forMember('createdAt', (opts) => moment(opts.sourceObject.createdAt).format(environment.dateFormat));
        });
    }
}
