import { NgModule } from '@angular/core';
import { OrderService } from './order.service';
import { OrderCreateComponent } from './order.create.component';
import { TabsModule } from 'ngx-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { SpinnerModule } from 'primeng/spinner';
import { SortPipe } from '../common/sort.pipe';
import { OrderPaymentComponent } from './order.payment.component';
import { Module as StripeModule } from 'stripe-angular';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { PaymentInformationComponent } from './payment-information.component';
import { OrderBulkPaymentComponent } from './order-bulk.payment.component';
import { ConfirmationDialogComponent } from '../common/confirmation.dialog.component';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
  declarations: [
    OrderCreateComponent,
    SortPipe,
    OrderPaymentComponent,
    PaymentInformationComponent,
    OrderBulkPaymentComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    TabsModule,
    SpinnerModule,
    StripeModule,
    NgxIntlTelInputModule,
    LayoutModule
  ],
  providers: [OrderService, ConfirmationDialogService],
  exports: [],
  entryComponents: [OrderCreateComponent, OrderPaymentComponent, ConfirmationDialogComponent, OrderBulkPaymentComponent]
})
export class OrderModule { }
