import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class EventMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('EventApi', 'EventModel')
                .forMember('createdAt', (opts) => moment(opts.sourceObject.createdAt).format(environment.dateFormat));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('EventModel', 'EventApi')
                .forMember('orders', (opts) => opts.ignore())
                .forMember('autoApproveOrders', (opts) => opts.ignore())
                .forMember('status', (opts) => opts.ignore())
                .forMember('createdAt', (opts) => opts.ignore());
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('EventModel', 'EventEditForm')
                .forMember('startDate', (opts) => new Date(opts.sourceObject.startDate))
                .forMember('startTime', (opts) => {
                    const start = moment(opts.sourceObject.startDate);
                    return `T${start.format('HH:mm:ss')}`;
                })
                .forMember('phone', (opts) => opts.mapFrom('phone'));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('EventEditForm', 'EventModel')
                .forMember('orders', (opts) => [])
                .forMember('phone', (opts) => opts.sourceObject.phone ? opts.sourceObject.phone.number : null)
                .forMember('startDate', (opts) => {
                    const date = moment(opts.sourceObject.startDate).format('YYYY-MM-DD');
                    const time = opts.sourceObject.startTime;
                    return moment(`${date}${time}`).toISOString();
                })
                .forMember('endDate', (opts) => {
                    const date = moment(opts.sourceObject.startDate).format('YYYY-MM-DD');
                    const time = opts.sourceObject.startTime;
                    return moment(`${date}${time}`).add(6, 'h').toISOString();
                })
                .forMember('orders', (opts) => [])
                .forMember('participants', (opts) => [])
                .forMember('invitedParticipants', (opts) => [])
                .forMember('autoApproveOrders', (opts) => false)
                .forMember('startTime', (opts) => opts.ignore());
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('EventCreateForm', 'EventModel')
                .forMember('orders', (opts) => [])
                .forMember('phone', (opts) => opts.sourceObject.phone ? opts.sourceObject.phone.number : null)
                .forMember('startDate', (opts) => {
                    const date = moment(opts.sourceObject.startDate).format('YYYY-MM-DD');
                    const time = opts.sourceObject.startTime;
                    const m = moment(`${date}${time}`).toISOString();
                    return m;
                })
                .forMember('endDate', (opts) => {
                    const date = moment(opts.sourceObject.startDate).format('YYYY-MM-DD');
                    const time = opts.sourceObject.startTime;
                    return moment(`${date}${time}`).add(6, 'h').toISOString();
                })
                .forMember('orders', (opts) => [])
                .forMember('participants', (opts) => [])
                .forMember('invitedParticipants', (opts) => [])
                .forMember('autoApproveOrders', (opts) => false)
                .forMember('startTime', (opts) => opts.ignore());
        });
    }
}
