import { GenericFormType, GenericFormControl } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { HourSlots } from '../data/hour.slots.data';

export class WorkingWeekModel {
    monday: WorkingDayModel;
    tuesday: WorkingDayModel;
    wednesday: WorkingDayModel;
    thursday: WorkingDayModel;
    firday: WorkingDayModel;
    saturday: WorkingDayModel;
    sunday: WorkingDayModel;
}

export class WorkingDayModel {
    day?: string;
    open: boolean;
    start: string;
    end: string;
    splitWorkingHours: boolean;
    secondStart?: string;
    secondEnd?: string;
}

export const WorkingDayContraints: GenericFormControl[] = [
    GenericFormBuilder.switch('open', 'Open'),
    GenericFormBuilder.dropdown('start', 'From', [], HourSlots.slice(0)),
    GenericFormBuilder.dropdown('end', 'To', [], HourSlots.slice(0)),
    GenericFormBuilder.switch('splitWorkingHours', 'Split working hours', (field) => !!field.open),
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown('secondStart', 'From', [], HourSlots.slice(0), false, null, (field) => !!field.splitWorkingHours && !!field.open),
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown('secondEnd', 'To', [], HourSlots.slice(0), false, null, (field) => !!field.splitWorkingHours && !!field.open),
];

export const WorkingWeekContraints: GenericFormType[] = [
    GenericFormBuilder.group('monday', 'Monday', WorkingDayContraints, null, 'inline'),
    GenericFormBuilder.group('tuesday', 'Tuesday', WorkingDayContraints, null, 'inline'),
    GenericFormBuilder.group('wednesday', 'Wednesday', WorkingDayContraints, null, 'inline'),
    GenericFormBuilder.group('thursday', 'Thursday', WorkingDayContraints, null, 'inline'),
    GenericFormBuilder.group('firday', 'Friday', WorkingDayContraints, null, 'inline'),
    GenericFormBuilder.group('saturday', 'Saturday', WorkingDayContraints, null, 'inline'),
    GenericFormBuilder.group('sunday', 'Sunday', WorkingDayContraints, null, 'inline')
];
