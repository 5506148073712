import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { GetTheAppPopupComponent } from '../home/get-the-app-popup.component';
import { RestaurantSignUpModalComponent } from './restaurant-signup-modal.component';
@Component({
  selector: 'app-for-restaurant-component',
  templateUrl: './for-restaurant.component.html',
  styleUrls: ['./for-restaurant.component.scss']
})
export class ForRestaurantComponent implements OnInit {
  graphicType = 'approve';
  graphicTypes = [
    'approve',
    'receive',
    'automatic',
  ];
  bsModalRef: BsModalRef;
  partners: string[];
  constructor(
    private modalService: BsModalService,
  ) { }

  async ngOnInit() {
    this.partners = [
      '/assets/images/restaurants/212_Steakhouse.png',
      '/assets/images/restaurants/AOCEast.png',
      '/assets/images/restaurants/Bombay_Bistro.png',
      '/assets/images/restaurants/CaffeBuonGusto.png',
      '/assets/images/restaurants/Canyon_Road.png',
      '/assets/images/restaurants/Cibo_e_Vino.png',
      '/assets/images/restaurants/Da_Capo.png',
      '/assets/images/restaurants/House_of_Brews.png',
      '/assets/images/restaurants/Jose_Luis.png',
      '/assets/images/restaurants/Jukai.png',
      '/assets/images/restaurants/Lena.png',
      '/assets/images/restaurants/Mad.png',
      '/assets/images/restaurants/Mama_Mia.png',
      '/assets/images/restaurants/PierreLoti.png',
      '/assets/images/restaurants/Rebel.png',
      '/assets/images/restaurants/SakeBarHagi46.png',
      '/assets/images/restaurants/SakeBarShigure.png',
      '/assets/images/restaurants/Sato.png',
      '/assets/images/restaurants/Sushi_Damo.png',
      '/assets/images/restaurants/TheMaze.png',
      '/assets/images/restaurants/TioPepe.png',
      '/assets/images/restaurants/Tohbay.png',
      '/assets/images/restaurants/Tramonti.png',
    ];
  }

  mouseOver = (type) => {
    this.graphicType = type;
  }

  openGetTheAppPopup() {
    const initialState = {
      appType: 'restaurant-app'
    };
    const bsModalRef = this.modalService.show(GetTheAppPopupComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  openSignupRestaurant() {
    const bsModalRef = this.modalService.show(RestaurantSignUpModalComponent, {
      ignoreBackdropClick: true
    });
    bsModalRef.setClass('modal-md restaurant-sign-up-modal');
  }

  featureNavigationHandler(action: string) {
    const currentPosition = this.graphicTypes.indexOf(this.graphicType);
    if (action === 'back') {
      if (currentPosition > 0) {
        this.graphicType = this.graphicTypes[currentPosition - 1];
      } else {
        this.graphicType = this.graphicTypes[this.graphicTypes.length - 1];
      }
    }
    if (action === 'forward') {
      if (currentPosition < this.graphicTypes.length - 1) {
        this.graphicType = this.graphicTypes[currentPosition + 1];
      } else {
        this.graphicType = this.graphicTypes[0];
      }
    }
  }
}
