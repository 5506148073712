import { NgModule } from '@angular/core';
import { TabsModule } from 'ngx-bootstrap';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { SpinnerModule } from 'primeng/spinner';
import { Module as StripeModule } from 'stripe-angular';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
import { ReservationListContainerComponent } from './reservation-list-container.component';
import { ReservationListComponent } from './reservation-list.component';
import { EventModule } from '../event/event.module';
import { LayoutModule } from '../layout/layout.module';

@NgModule({
  declarations: [
    ReservationListContainerComponent,
    ReservationListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    TabsModule,
    SpinnerModule,
    StripeModule,
    NgxIntlTelInputModule,
    EventModule,
    LayoutModule
  ],
  providers: [],
  exports: [],
  entryComponents: [ReservationListContainerComponent]
})
export class ReservationsModule { }
