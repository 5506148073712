import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '../search/search.component';
import { SlickCarouselModule  } from 'ngx-slick-carousel';
import { CuisineComponent } from './cuisine/cuisine.component';
import { AddressSearchComponent } from './address-search/address.search.component';
import { ExploreTablesComponent } from './explore-tables/explore.tables.component';
import { HappyHoursComponent } from './happy-hours/happy.hours.component';
import { VenuesComponent } from './venues/venues.component';
import { FiltersComponent } from './filters/filters.component';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { GetTheAppComponent } from '../common/get-the-app/get-the-app.component';

@NgModule({
    declarations: [
      SearchComponent,
      CuisineComponent,
      AddressSearchComponent,
      ExploreTablesComponent,
      HappyHoursComponent,
      VenuesComponent,
      FiltersComponent,
      GetTheAppComponent
    ],
    imports: [
      CommonModule,
      SlickCarouselModule,
      RouterModule,
      BsDropdownModule,
      MultiSelectModule,
      FormsModule
    ],
    exports: [GetTheAppComponent],
    providers: [],
    entryComponents: []
})
export class SearchModule { }
