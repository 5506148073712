import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { VenueService } from '../venue/venue.service';
import { VenueModel } from '../models/venue.model';

@Component({
  selector: 'app-restaurant-signup-component',
  templateUrl: './restaurant-signup-modal.component.html',
  styleUrls: ['./restaurant-signup-modal.component.scss']
})
export class RestaurantSignUpModalComponent implements OnInit {

  originalVenues: VenueModel[];
  venues: VenueModel[];
  nameSearch: string;
  dropdownVisible = false;

  constructor(
    public bsModalRef: BsModalRef,
    private venueService: VenueService,
    private router: Router,
  ) { }

  async ngOnInit() {
    this.originalVenues = await this.venueService.getAll();
    this.dropdownVisible = false;
  }

  selectVenue(venue: VenueModel) {
    this.router.navigateByUrl(`/venue-register/${venue.slug}/claim-restaurant`);
    this.bsModalRef.hide();
  }

  closeDropdown() {
    setTimeout(() => {
      this.dropdownVisible = false;
    }, 500);
  }

  addRestaurant = () => this.bsModalRef.hide();

  openDropdown = () => this.dropdownVisible = true;

  goToVenueRegister = () => {
    this.router.navigateByUrl(`/venue-register`);
    this.bsModalRef.hide();
  }

  search() {
    if (this.nameSearch.length === 0) {
      this.dropdownVisible = false;
      this.venues = this.originalVenues;
      return;
    }
    this.dropdownVisible = true;
    this.venues = this.originalVenues
      .filter((x) => !x.ownerId && x.name.toLowerCase().indexOf(this.nameSearch.toLowerCase()) !== -1);
  }

  cancel() {
    this.bsModalRef.hide();
  }
}
