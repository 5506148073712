import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GenericFormType, GenericFormComponent } from '../generic-form/generic.form.component';
import { VenueCreateConstraints, VenueModel } from '../models/venue.model';
import { VenueService } from './venue.service';
import { Router } from '@angular/router';
import { LocationInputComponent } from '../location/location.input.component';
import { FileUploadComponent } from '../file.upload/file.upload.component';
import { VenueAuxService } from '../venue-aux-form/venue.aux.service';

@Component({
  selector: 'app-venue-create',
  templateUrl: './venue.create.component.html'
})
export class VenueCreateComponent implements OnInit {

  @ViewChild('genericForm') genericForm: GenericFormComponent;
  @ViewChild('locationInput') locationInput: LocationInputComponent;
  @ViewChild('fileUpload') fileUpload: FileUploadComponent;

  createRules: GenericFormType[] = VenueCreateConstraints;
  createInProgress: boolean;
  latitude: number;
  longitude: number;

  isModalVisible = false;
  createModalElement: string;

  constructor(
    private venueService: VenueService,
    private router: Router,
    private venueAuxService: VenueAuxService,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.latitude = 40.730610;
    this.longitude = -73.935242;
    this.createInProgress = false;
    this.venueAuxService.modalState$.subscribe(isOpen => {
      this.isModalVisible = isOpen;
    });
    this.venueAuxService.setForm(this.genericForm)
  }

  async create() {
    if (this.genericForm.isValid()) {
      this.createInProgress = true;
      try {
        let venue: VenueModel = this.genericForm.toModel<VenueModel>('VenueCreateForm', 'VenueModel');
        venue.location = this.locationInput.currentLocation;
        venue = await this.venueService.create(venue);
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.fileUpload.filesData.length; i++) {
          await this.venueService.uploadPhoto(venue.id, this.fileUpload.filesData[i]);
        }
        this.router.navigateByUrl('/venues');
      } catch (err) {
        this.createInProgress = false;
      }
    }
  }
  setInputVisible(type: string) {
    if(type === "categories") {
      this.createModalElement = 'categorie'
    }else {
      this.createModalElement = 'feature'
    }
  }
  async hadleDataChange(data: VenueModel){
    if(
      (data.categories && data.categories.includes('other')) || 
      (data.features && data.features.includes('other')) )
    {
    this.openModal()
      for (const key in data) {
        if (data[key] && typeof data[key] !== 'string' && data[key].includes('other')) {
          this.setInputVisible(key)
        }
      }
    }
    else this.closeModal()
  }
  openModal(){
    const selectElements = this.elRef.nativeElement.querySelectorAll('.ui-multiselect-panel');
    selectElements.forEach(el => {
      el.style.display = 'none'
    })
    this.venueAuxService.openModal()
  }
  closeModal() {
    this.venueAuxService.closeModal()
  }
}
