import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ToasterService } from 'angular2-toaster';
import { NotificationService } from './notification.service';

@Injectable()
export class ApiService {

  private host: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toasterService: NotificationService) {
    this.host = environment.api.host;
  }

  get(url: string, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.host + url, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          console.log('API ERROR', error);
          this.toasterService.error('', error.error.message);
          reject(error.error);
        });
    });
  }

  newget(url: string, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    const user = this.authService.getCurrentUser();
    const httphead: HttpHeaders = new HttpHeaders()
      .set('Authorization', `Bearer ${user.token}`);
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.host + url, {
        headers: httphead
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          console.log('API ERROR', error);
          this.toasterService.error('', error.error.message);
          reject(error.error);
        });
    });
  }

  post(url: string, data: any, headers?: any[], skipAuth = false): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    if (!skipAuth) {
      httpHeaders = this.setAuthToken(httpHeaders);
    }
    return new Promise<any>((resolve, reject) => {
      this.http.post(this.host + url, data, {
        headers: httpHeaders,
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          console.log('API ERROR', error);
          this.toasterService.error('', error.error.message);
          reject(error.error);
        });
    });
  }

  put(url: string, data: any, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    return new Promise<any>((resolve, reject) => {
      this.http.put(this.host + url, data, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          console.log('API ERROR', error);
          this.toasterService.error('', error.error.message);
          reject(error.error);
        });
    });
  }

  delete(url: string, headers?: any[]): Promise<any> {
    let httpHeaders: HttpHeaders = new HttpHeaders();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < headers.length; i++) {
      httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
    }
    httpHeaders = this.setAuthToken(httpHeaders);
    return new Promise<any>((resolve, reject) => {
      this.http.delete(this.host + url, {
        headers: httpHeaders
      })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          console.log('API ERROR', error);
          this.toasterService.error('', error.error.message);
          reject(error.error);
        });
    });
  }

  private setAuthToken(httpHeaders: HttpHeaders): HttpHeaders {
    if (this.authService.isLoggedIn().getValue()) {
      return httpHeaders.set('Authorization', `Bearer ${this.authService.user.token}`);
    }
    return httpHeaders;
  }
}
