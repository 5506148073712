import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import { Injectable } from '@angular/core';
import { HourSlots } from '../data/hour.slots.data';

const getHourSlotValue = (label: string): string => {
  const findHour = HourSlots.find(ele => ele.label === label);
  if (findHour) {
    return findHour.value;
  }
  return null;
};

@Injectable()
export class WorkingWeekMapper implements IMapperRegistry {

  constructor(private automapper: AutomapperService) {
  }

  register() {
    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('WorkingDaysArray', 'WorkingWeekModel')
        .forMember('monday', (opts) => this.automapper.map('WorkingDayFormData', 'WorkingDayModel', opts.sourceObject.days[0]))
        .forMember('tuesday', (opts) => this.automapper.map('WorkingDayFormData', 'WorkingDayModel', opts.sourceObject.days[1]))
        .forMember('wednesday', (opts) => this.automapper.map('WorkingDayFormData', 'WorkingDayModel', opts.sourceObject.days[2]))
        .forMember('thursday', (opts) => this.automapper.map('WorkingDayFormData', 'WorkingDayModel', opts.sourceObject.days[3]))
        .forMember('friday', (opts) => this.automapper.map('WorkingDayFormData', 'WorkingDayModel', opts.sourceObject.days[4]))
        .forMember('saturday', (opts) => this.automapper.map('WorkingDayFormData', 'WorkingDayModel', opts.sourceObject.days[5]))
        .forMember('sunday', (opts) => this.automapper.map('WorkingDayFormData', 'WorkingDayModel', opts.sourceObject.days[6]))
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('TimeFrame', 'WorkingDayModel')
        .forMember('day', (opts) => opts.sourceObject ? opts.sourceObject.day : null)
        .forMember('open', (opts) => true)
        .forMember('splitWorkingHours', (opts) => false)
        .forMember('start', (opts) => {
          if (opts.sourceObject.workHours && opts.sourceObject.workHours.length) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.workHours[0].start);
            return findHour ? findHour.label : null;
          }
          if (!opts.sourceObject.workHours) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.start);
            return findHour ? findHour.label : null;
          }
        })
        .forMember('end', (opts) => {
          if (opts.sourceObject.workHours && opts.sourceObject.workHours.length) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.workHours[0].end);
            return findHour ? findHour.label : null;
          }
          if (!opts.sourceObject.workHours) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.end);
            return findHour ? findHour.label : null;
          }
        })
        .forMember('secondStart', (opts) => null)
        .forMember('secondEnd', (opts) => null)
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('WorkingDayApi', 'WorkingDayModel')
        .forMember('day', (opts) => opts.sourceObject.day)
        .forMember('open', (opts) => !!opts.sourceObject.open)
        .forMember('splitWorkingHours', (opts) => !!opts.sourceObject.splitWorkingHours)
        .forMember('start', (opts) => {
          if (opts.sourceObject.workHours && opts.sourceObject.workHours.length) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.workHours[0].start);
            return findHour ? findHour.label : null;
          }
          if (!opts.sourceObject.workHours) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.start);
            return findHour ? findHour.label : null;
          }
        })
        .forMember('end', (opts) => {
          if (opts.sourceObject.workHours && opts.sourceObject.workHours.length) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.workHours[0].end);
            return findHour ? findHour.label : null;
          }
          if (!opts.sourceObject.workHours) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.end);
            return findHour ? findHour.label : null;
          }
        })
        .forMember('secondStart', (opts) => {
          if (opts.sourceObject.workHours && opts.sourceObject.workHours.length === 2) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.workHours[1].start);
            return findHour ? findHour.label : null;
          }
        })
        .forMember('secondEnd', (opts) => {
          if (opts.sourceObject.workHours && opts.sourceObject.workHours.length === 2) {
            const findHour = HourSlots.find(ele => ele.value === opts.sourceObject.workHours[1].end);
            return findHour ? findHour.label : null;
          }
        })
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('WorkingDayFormData', 'WorkingDayModel')
        .forMember('open', (opts) => !!opts.sourceObject.open)
        .forMember('splitWorkingHours', (opts) => !!opts.sourceObject.splitWorkingHours)
        .forMember('workHours', (opts) => {
          const workHours = [
            {
              start: opts.sourceObject.start ? opts.sourceObject.start : 'T00:00:00',
              end: opts.sourceObject.end ? opts.sourceObject.end : 'T00:00:00'
            }
          ];
          if (opts.sourceObject.splitWorkingHours) {
            workHours.push({
              start: opts.sourceObject.secondStart ? opts.sourceObject.secondStart : 'T00:00:00',
              end: opts.sourceObject.secondEnd ? opts.sourceObject.secondEnd : 'T00:00:00'
            });
          }
          return workHours;
        })
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('WorkingDayModel', 'WorkingDayFormData')
        .forMember('open', (opts) => !!opts.sourceObject.open)
        .forMember('splitWorkingHours', (opts) => !!opts.sourceObject.splitWorkingHours)
        .forMember('start', (opts) => getHourSlotValue(opts.sourceObject.start))
        .forMember('end', (opts) => getHourSlotValue(opts.sourceObject.end))
        .forMember('secondStart', (opts) => getHourSlotValue(opts.sourceObject.secondStart))
        .forMember('secondEnd', (opts) => getHourSlotValue(opts.sourceObject.secondEnd))
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('WorkingDayModel', 'WorkingDayApi')
        .forMember('day', (opts) => opts.sourceObject ? opts.sourceObject.day : null)
        .forMember('open', (opts) => !!opts.sourceObject.open)
        .forMember('splitWorkingHours', (opts) => !!opts.sourceObject.splitWorkingHours)
        .forMember('workHours', (opts) => {
          const hours = [];
          hours.push({
            start: getHourSlotValue(opts.sourceObject.start),
            end: getHourSlotValue(opts.sourceObject.end)
          });
          if (opts.sourceObject.splitWorkingHours) {
            hours.push({
              start: getHourSlotValue(opts.sourceObject.secondStart),
              end: getHourSlotValue(opts.sourceObject.secondEnd)
            });
          }
          return hours;
        })
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('WorkingWeekModel', 'WorkingDaysArray')
        .forMember('days', (opts) => {
          return [
            this.automapper.map('WorkingDayModel', 'WorkingDayFormData', opts.sourceObject.monday),
            this.automapper.map('WorkingDayModel', 'WorkingDayFormData', opts.sourceObject.tuesday),
            this.automapper.map('WorkingDayModel', 'WorkingDayFormData', opts.sourceObject.wednesday),
            this.automapper.map('WorkingDayModel', 'WorkingDayFormData', opts.sourceObject.thursday),
            this.automapper.map('WorkingDayModel', 'WorkingDayFormData', opts.sourceObject.friday),
            this.automapper.map('WorkingDayModel', 'WorkingDayFormData', opts.sourceObject.saturday),
            this.automapper.map('WorkingDayModel', 'WorkingDayFormData', opts.sourceObject.sunday)
          ];
        })
        .ignoreAllNonExisting();
    });
  }
}
