import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GenericFormComponent } from '../generic-form/generic.form.component';

@Injectable({
  providedIn: 'root'
})
export class VenueAuxService {
  private modalState = new BehaviorSubject<boolean>(false);
  private formSubject = new BehaviorSubject<GenericFormComponent>(null);
  modalState$ = this.modalState.asObservable();
  form$ = this.formSubject.asObservable();

  openModal() {
    this.modalState.next(true);
  }
  setForm(form: GenericFormComponent) {
    this.formSubject.next(form);
  }

  closeModal(control?: string) {
    this.modalState.next(false);
    if(control){
      this.updateFormValue(control)
    }
  }
  updateFormValue(controlName: string, value?: string, data?: any) {
    const form = this.formSubject.value;
    if (form) {
      const index = form.myForm.value[controlName].indexOf('other');

      if (index !== -1) {
        form.myForm.value[controlName].splice(index, 1);
      }
      if(value){
        form.myForm.value[controlName].push(value);
        const field = form.fields.find((f)=> f.name === controlName) as any
        field.lookups.push(data)
      }
    }
    this.formSubject.next(form)
  }
}