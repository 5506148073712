import { Component, OnInit } from '@angular/core';
import { EventModel } from '../models/event.model';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';
import * as moment from 'moment';
import { EventService } from '../event/event.service';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';

@Component({
  selector: 'app-user-invitations',
  templateUrl: './user.invitations.component.html'
})
export class UserInvitationsComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router) { }

  events: EventModel[] = [];
  currentUser: UserModel;

  getActionsList = ((event: EventModel) => {
    const actions = [];
    actions.push({ label: 'Accept', action: this.acceptInvitation.bind(this) });
    return actions;
  }).bind(this);

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.events = await this.eventService.search({ invitedParticipants: this.currentUser.email });
  }

  async acceptInvitation(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Accept ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to accept ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      await this.eventService.acceptInvitation(event, this.currentUser.email);
      this.router.navigateByUrl(`/bookings/${event.id}/orders/add`);
    }
  }

  getEventPrefix(event: EventModel) {
    if (moment(event.startDate).isBefore(moment({ hours: 18, minute: 0 }))) {
        return 'Lunch';
    } else {
        return 'Dinner';
    }
  }
}
