import { GenericFormControl } from '../generic-form/generic.form.component';

export class LocationModel {
    latitude: number;
    longitude: number;
    address?: string;
    // neighborhood?: string;
    postalCode?: string;
    city?: string;
    country?: string;
    countryCode?: string;
}

export const LocationConstraints: GenericFormControl[] = [];
