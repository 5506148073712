import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MailService } from '../common/mail.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  active: boolean;
  notActive: boolean;
  verifying: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mailService: MailService
  ) { }

  async ngOnInit() {
    const email: string = this.route.snapshot.paramMap.get("email");
    const code: string = this.route.snapshot.paramMap.get("code");
    if (!email || !code) {
      this.router.navigate([""])
    }
    try {
      const response = await this.mailService.verifyEmail({ email, verificationCode: code });
      this.verifying = false;
      if (response && response.verified) {
        this.active = true;
      } else {
        this.notActive = true;
      }
    } catch (err) {
      this.notActive = true;
      this.verifying = false;
    }
    setTimeout(() => this.router.navigate([""]), 3000);
  }

}
