import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItemCreateComponent } from './menu-item.create.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { GenericFormModule } from '../generic-form/generic.form.module';
import { LaddaModule } from 'angular2-ladda';
import { MenuService } from './menu.service';
import { FileUploadModule } from '../file.upload/file.upload.module';
import { MenuItemEditComponent } from './menu-item.edit.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { MenuCategoryEditComponent } from './menu-category.edit.component';
import { MenuCategoryCopyComponent } from '../menu-category/menu-category.copy.component';

@NgModule({
  declarations: [
    MenuItemCreateComponent,
    MenuItemEditComponent,
    MenuCategoryEditComponent,
    MenuCategoryCopyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericFormModule,
    LaddaModule,
    FileUploadModule,
    MultiSelectModule,
    DropdownModule
  ],
  providers: [MenuService]
})
export class MenuItemModule { }
