import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GenericFormType, GenericFormComponent, Lookup } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { EventModel } from '../models/event.model';
import { VenueService } from '../venue/venue.service';
import { UserModel } from '../models/user.model';
import * as moment from 'moment';
import { TabsetComponent, BsModalService } from 'ngx-bootstrap';
import { VenueModel } from '../models/venue.model';
import { VenueBookingEditComponent } from '../venue/venue.booking.edit.component';

@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list-container.component.html',
})
export class ReservationListContainerComponent implements OnInit {

  @ViewChild('reservationsFilterForm') reservationsFilterForm: GenericFormComponent;
  @ViewChild('staticTabs') staticTabs: TabsetComponent;

  filterVenueRules: GenericFormType[];
  user: UserModel;
  venues: VenueModel[];
  originalEvents: EventModel[];
  filteredEvents: any;

  reservationTabs = [
    { key: 'past', label: 'Past' },
    { key: 'present', label: 'Present' },
    { key: 'future', label: 'Future' }
  ];

  venueLookups: any = [
    {label: 'All venues', value: 'all venues'},
  ];

 getActionsList = ((event: EventModel) => {
  const actions = [];
  actions.push({ label: 'Orders', action: this.goToOrders.bind(this) });
  return actions;
}).bind(this);

  constructor(
    private venueService: VenueService,
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  async ngOnInit() {
    this.user = await this.authService.getCurrentUser();
    this.venues = await this.venueService.getAllVenuesByUserId(this.user.id);
    this.setVenueLookups(this.venues);
    this.filterVenueRules = [
      GenericFormBuilder.dropdown('reservations', null, [], this.venueLookups)
    ];
    this.originalEvents = await this.venueService.getAllEventsByVenueIds(this.user.venueIds);
    this.filteredEvents = this.filterEvents(this.copy(this.originalEvents));
  }

  setVenueLookups(venues: VenueModel[]) {
    const lookups = [... this.venueLookups];
    venues.forEach((item) => lookups.push({label: item.name, value: item.id}));
    this.venueLookups = lookups;
  }

  getEventPrefix(event: EventModel) {
    if (moment(event.startDate).isBefore(moment({ hour: 18, minute: 0 }))) {
      return 'Lunch';
    } else {
      return 'Dinner';
    }
  }

  async goToOrders(event: EventModel) {
    this.router.navigateByUrl(`/bookings/${event.id}`);
  }

  getTabItems(tab: string) {
    if (tab === 'present') {
      return this.filteredEvents.present;
    }
    if (tab === 'past') {
      return this.filteredEvents.past;
    }
    if (tab === 'future') {
      return this.filteredEvents.future;
    }
  }

  filterEvents(events: EventModel[]) {
    const filteredEvents = {
      past: [],
      present: [],
      future: [],
    };
    events.forEach((item) => {
      if (moment(item.startDate).isAfter(moment())) {
        return filteredEvents.future.push(item);
      }
      if (moment().isAfter(moment(item.endDate))) {
        return filteredEvents.past.push(item);
      } else {
        return filteredEvents.present.push(item);
      }
    });

    return filteredEvents;
  }

  openEventInfo(event: EventModel) {
    let venue = this.venues.find(item => item.id === event.venueId);
    const initialState = {
      event,
      venue: venue
    };
    const bsModalRef = this.modalService.show(VenueBookingEditComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  onVenueSelect() {
    const selectedVenue = this.reservationsFilterForm.myForm.value.reservations;
    const events: EventModel[] = this.copy(this.originalEvents);
    if (!selectedVenue || selectedVenue === 'all venues') {
      this.filteredEvents = this.filterEvents(events);
    } else {
      const selectedVenueEvents = events.filter((item) => item.venueId === selectedVenue);
      this.filteredEvents = this.filterEvents(selectedVenueEvents);
    }
  }

  copy(object) {
    return JSON.parse(JSON.stringify(object));
  }
}
