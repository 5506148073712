import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from '../../menu-item/menu.service';
import { MenuItemAddonnModel, MenuItemModel, MenuItemOptionModel } from '../../models/menu-item.model';
import { UserModel } from '../../models/user.model';
import { AuthService } from '../../common/auth.service';
import { VenueModel } from '../../models/venue.model';
import { VenueService } from '../../venue/venue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserRoles } from '../../data/user.roles.data';
import { BasketService } from '../basket/basket.service';
import { NotificationService } from 'src/app/common/notification.service';
import { MobileService } from '../mobile.service';

@Component({
  selector: 'app-mobile-ordering-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MobileOrderingMenuItemComponent implements OnInit {

  venue: VenueModel;
  eventId: string;
  menuId: string;
  table: string;
  menuModel: MenuItemModel;
  menuModelString: string;
  selectedOption: MenuItemOptionModel;
  currentUser: UserModel;
  shownDetails: string;
  basket: any[] = [];
  selectedPriority: string;
  price: number;
  availablePriorities = [{
    key: '1st',
    label: 'Immediate'
  }, {
    key: '2nd',
    label: 'After appetizer'
  }];

  constructor(
    private route: ActivatedRoute,
    private venueService: VenueService,
    private menuService: MenuService,
    private authService: AuthService,
    private basketService: BasketService,
    private notificationService: NotificationService,
    private mobileService: MobileService,
    private router: Router) { }

  async ngOnInit() {
    const slug = this.route.snapshot.paramMap.get('slug');
    this.menuId = this.route.snapshot.paramMap.get('menuId');
    this.table = this.route.snapshot.paramMap.get('table');
    this.eventId = this.mobileService.eventId;
    this.currentUser = this.authService.getCurrentUser();
    this.venue = await this.venueService.getBySlug(slug);
    await this.loadMenu();
  }

  async loadMenu() {
    this.menuModel = await this.menuService.getById(this.venue.id, this.menuId);
    this.menuModel.options.forEach((option) => {
      let addons = [];
      this.menuModel.addonsCategories.forEach(ac => ac.addons.forEach(addon => addons.push({ ...addon })))
      option.quantity = 1;
      option.optionAddons = addons;
    });
    this.selectedOption = this.menuModel.options[0];
    this.selectedPriority = this.availablePriorities[0].key;
    this.menuModelString = JSON.stringify(this.menuModel);
  }

  isAdmin() {
    return this.currentUser && this.currentUser.role === UserRoles.ADMIN;
  }

  isOwner() {
    return (this.currentUser && this.currentUser.id === this.venue.ownerId);
  }

  getMenuItemPrice() {
    this.price = this.selectedOption.price.value * this.selectedOption.quantity;
    if (this.menuModel.addonsCategories && this.menuModel.addonsCategories.length) {
      const addonsWithPrice = []
      this.menuModel.addonsCategories.forEach(ac => ac.addons.forEach((addon) => {
        if (addon.includeAddon && !!addon.addonTotal) {
          addonsWithPrice.push(addon);
        }
      }));
      if (addonsWithPrice && addonsWithPrice.length) {
        addonsWithPrice.forEach(a => this.price += a.addonTotal * a.quantity * this.selectedOption.quantity);
      }
    }
    return this.price.toFixed(2);
  }

  isItemAdded(item: MenuItemModel, option: MenuItemOptionModel) {
    const existingItem = this.basket.find((x) => x.menuItemId === item.id && x.optionId === option.id);
    return !!existingItem;
  }

  openDetails(menu: MenuItemModel) {
    if (this.shownDetails === menu.name) {
      this.shownDetails = null;
    } else {
      this.shownDetails = menu.name;
    }
  }

  selectoption(option: MenuItemOptionModel) {
    this.selectedOption = option;
    this.selectedOption.quantity = 1;
  }

  getAddonsQuantity(min: number, max: number) {
    let text = '';
    if (min && min > 0) {
      text += `min ${min}`;
    }
    if (min && min > 0 && max && max > 0) {
      text += ' / ';
    }
    if (max && max > 0) {
      text += `max ${max}`;
    }
    text += ' addons';
    return `${text}`;
  }

  addToBasket() {
    if (this.validateAddonsTotal(this.menuModel)) {
      this.basketService.addToBasket(this.menuModel, this.selectedOption, this.selectedPriority);
      this.router.navigateByUrl(`mobile-ordering/menu/${this.venue.slug}/${this.table}`);
    }
  }

  cancel() {
    this.router.navigateByUrl(`mobile-ordering/menu/${this.venue.slug}/${this.table}`);
  }

  validateAddonsTotal = (item: MenuItemModel) => {
    let addonTotal = 0;
    if (!item.addonsCategories || !item.addonsCategories.length) {
      return true;
    }
    item.addonsCategories.forEach(ac => ac.addons.forEach((addon: MenuItemAddonnModel) => {
      if (!!addon.includeAddon) {
        addonTotal += addon.quantity;
      }
    }));
    if (!!item.maxAddonQuantity && addonTotal > item.maxAddonQuantity) {
      this.notificationService.error(`You added ${addonTotal} addons. Maximum allowed is ${item.maxAddonQuantity}`);
      return false;
    }
    if (!!item.minAddonQuantity && addonTotal < item.minAddonQuantity) {
      this.notificationService.error(`You added ${addonTotal} addons. Minimum required is ${item.minAddonQuantity}`);
      return false;
    }
    return true;
  }

  updateQuantity(qIncrement: number) {
    if (this.selectedOption.quantity !== 0 || qIncrement !== -1) {
      this.selectedOption.quantity += qIncrement;
    }
  }

  updatePriority(key: string) {
    this.selectedPriority = key;
  }

  selectAddon(addon: any) {
    addon.includeAddon = !addon.includeAddon;
    addon.quantity = addon.includeAddon ? 1 : 0;
  }

  updateAddonQuantity(qIncrement: number, addon: any) {
    if (qIncrement === -1 && addon.quantity === 1) {
      return;
    }
    if (addon.quantity !== 0 || qIncrement !== -1) {
      addon.quantity += qIncrement;
    }
  }
}
