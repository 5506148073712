import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GenericFormType, GenericFormComponent, GenericFormControl } from './generic.form.component';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-input-row',
  templateUrl: './generic.input.row.component.html'
})
export class GenericInputRowComponent {
  // tslint:disable-next-line:no-input-rename
  @Input('field') field;
  // tslint:disable-next-line:no-input-rename
  @Input('formGroup') formGroup;

  @Input('myForm') myForm;

  @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();

  toRaw(myForm?: GenericFormType[]): any {
    const form = myForm ? myForm : this.formGroup;
    const raw = {};
    Object.keys(form.controls).forEach(key => {
      const value = form.get(key).value;
      raw[key] = value;
    });
    return raw;
  }

  async search(event: any, field: string) {
    const autocompleteField = this.field as GenericFormControl;
    if (autocompleteField && autocompleteField.search) {
      autocompleteField.filteredLookups = await autocompleteField.search(event.query);
    }
  }

  valueChanged() {
    const autocompleteField = this.field as GenericFormControl;
    const rawValue = this.toRaw();
    if (autocompleteField && autocompleteField.onChange) {
      autocompleteField.onChange(rawValue.address.value);
    }
    this.dataChange.emit(rawValue);
  }
}
