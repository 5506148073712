import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { MenuItemOptionModel, PriceModel } from '../models/menu-item.model';
import { EventModel } from '../models/event.model';

interface Order {
    id: string;
    userId: string;
    venueId: string;
    eventId: string;
    orderStatus?: string;
    usedCredit?: boolean;
    usedDiscount?: boolean;
    items?: MenuItemOptionModel[];
    price?: PriceModel;
    userFullName?: string;
    charge: any;
}

@Injectable({
  providedIn: 'root'
})
export class MobileService {

  public eventId:string;
  public tableNumber: string;
  public tableNumberMandatory: boolean;

  constructor(
    private apiService: ApiService,
  ) { }

  async getOrderByID(id: string): Promise<Order> {
    const res = await this.apiService.get(`/order/${id}`, []);
    return res;
  }

  async getTableByID(id: string): Promise<EventModel> {
    // return this.apiService.get(`/event/${id}`, []);
    return this.apiService.get(`/${this.getApiPrefix()}/${id}`, [])
      .then((res) => {
        return res;
      });
  }

  async getEventTableNumberMandatory(id: string){
    const res = await this.apiService.get(`/event/${id}/tableNumberMandatory`, []);
    return res;
  }

  async getUserTableNumber(eventId: string, userId: string){
    const res = await this.apiService.newget(`/event/${eventId}/table/${userId}`, []); //event/{eventId}/table/{userId}
    return res.tableNumber;
  }

  protected getApiPrefix(): string {
    return 'event';
  }
  
}

