import { Component, Input, OnInit } from '@angular/core';
import { OrderModel } from 'src/app/models/order.model';

@Component({
  selector: 'app-addons-instructions-tooltip',
  templateUrl: './addons.tooltip.component.html'
})
export class AddonsInstructionsTooltipComponent implements OnInit {
  @Input() order: OrderModel;
  @Input() orderTitle: string;
  addonsHTML: string;
  instructionsHTMLHTML: string;
  showTooltip: boolean;
  constructor() {}

  ngOnInit() {
    if ((this.order.items[0].optionAddons && this.order.items[0].optionAddons.length) || this.order.items[0].specialInstructions) {
      this.showTooltip = false;
    } else {
      this.showTooltip = true;
    }

    if (this.order.items[0].optionAddons && this.order.items[0].optionAddons.length) {
      this.addonsHTML = '<span class="btn-block btn-danger well-sm">';
      this.order.items[0].optionAddons.forEach((addon: any, index: number) => {
        if (index !== this.order.items[0].optionAddons.length - 1) {
          this.addonsHTML = this.addonsHTML + addon.name + ', ';
        } else {
          this.addonsHTML = this.addonsHTML + addon.name + '</span>';
        }
      });
    }

    this.instructionsHTMLHTML = `<span class="btn-block btn-danger well-sm">${this.order.items[0].specialInstructions}</span>`;
  }
}
