import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { EventService } from './event.service';
import { OrderService } from '../order/order.service';
import { OrderModel } from '../models/order.model';
import { ActivatedRoute, Router } from '@angular/router';
import { EventModel } from '../models/event.model';
import { AuthService } from '../common/auth.service';
import { VenueService } from '../venue/venue.service';
import { VenueModel } from '../models/venue.model';
import { UserModel } from '../models/user.model';
import { BsModalService } from 'ngx-bootstrap';
import { OrderCreateComponent } from '../order/order.create.component';
import { OrderPaymentComponent } from '../order/order.payment.component';
import { UserRoles } from '../data/user.roles.data';
import { Statuses } from '../data/statuses.data';
import { OrderStatuses } from '../data/order.statuses.data';
import { GenericFormType, GenericFormComponent, Lookup } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { OrderBulkPaymentComponent } from '../order/order-bulk.payment.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';
import { EventParticipantsComponent } from './event.participants.component';
import { VenueBookingEditComponent } from '../venue/venue.booking.edit.component';
import * as moment from 'moment';

@Component({
  selector: 'app-event-orders',
  templateUrl: './event.orders.component.html',
  styleUrls: ['./event.orders.component.scss']
})
export class EventOrdersComponent implements OnInit, OnDestroy {

  @ViewChild('OrdersFilterForm') ordersFilterForm: GenericFormComponent;

  events: EventModel[];
  event: EventModel;
  orders: OrderModel[];
  venue: VenueModel;
  currentUser: UserModel;
  shownDetails: string;
  statuses = Statuses;
  participants = [];
  filterParticipantsRules: GenericFormType[];
  timer;

  private originalOrders: OrderModel[];
  private eventActions = {};

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private authService: AuthService,
    private venueService: VenueService,
    private modalService: BsModalService,
    private router: Router,
    private confirmationDialogService: ConfirmationDialogService,
  ) { }

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    const id: string = this.route.snapshot.paramMap.get('id');
    this.event = await this.eventService.getById(id);
    this.venue = await this.venueService.getById(this.event.venueId);
    this.loadEventActions(this.event);
    await this.loadOrders();
  }

  onParticipantSelect() {
    const selectedParticipants = this.ordersFilterForm.myForm.value.participants;
    if (selectedParticipants && selectedParticipants === '*') {
      return this.orders = this.originalOrders;
    }
    if (selectedParticipants && selectedParticipants.length) {
      this.orders = this.originalOrders.filter((x) => {
        return selectedParticipants
          .indexOf(x.userId) !== -1;
      });
    } else {
      return this.orders = this.originalOrders;
    }
  }

  isAdmin() {
    return this.currentUser && this.currentUser.role === UserRoles.ADMIN;
  }

  isOwner() {
    return (this.currentUser && this.currentUser.id === this.venue.ownerId);
  }

  singlePaymentEnabled() {
    return false;
  }

  goToAddOrder() {
    this.router.navigateByUrl(`/bookings/${this.event.id}/orders/add`);
  }

  openOrderModal() {
    const initialState = {
      venue: this.venue,
      event: this.event
    };
    const bsModalRef = this.modalService.show(OrderCreateComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  openDetails(order: OrderModel) {
    if (order.id === this.shownDetails) {
      this.shownDetails = null;
    } else {
      this.shownDetails = order.id;
    }
  }

  openPaymentForm(order: OrderModel) {
    const initialState = {
      order,
      onClose: this.loadOrders.bind(this)
    };
    const bsModalRef = this.modalService.show(OrderPaymentComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  openBulkPaymentForm() {
    const initialState = {
      orders: this.orders.filter((x => x.orderStatus === OrderStatuses.COMPLETED)),
      eventId: this.event.id,
      onClose: this.loadOrders.bind(this),
      userId: this.currentUser.id
    };
    const bsModalRef = this.modalService.show(OrderBulkPaymentComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  getPaymentTotal() {
    if (!this.orders) {
      return 0;
    }
    let total = 0;
    this.orders.forEach((o) => {
      if (o.orderStatus === OrderStatuses.COMPLETED) {
        total += o.price.total;
      }
    });
    return total;
  }

  getOrderTitle(order: OrderModel) {
    return order.items.map((x) => `${x.quantity}x ${x.name}`).join(', ');
  }

  isOrderAvailableForPayment(order: OrderModel) {
    return order.orderStatus === OrderStatuses.COMPLETED &&
      !this.isOwner() &&
      this.event.eventStatus === Statuses.ACTIVE;
  }

  isPaymentEnabled() {
    return !this.isOwner() &&
      this.event.eventStatus === Statuses.ACTIVE;
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  getPaidAmount() {
    let totalAmount = 0;
    if (!this.originalOrders) {
      return 0;
    }
    const charges = {};
    this.originalOrders.forEach((o) => {
      if (o.orderStatus === OrderStatuses.PAID) {
        if (o.charge && !charges[o.charge.id]) {
          charges[o.charge.id] = o.charge;
        }
      }
    });
    for (const i in charges) {
      if (charges.hasOwnProperty(i)) {
        totalAmount += ((charges[i].amount + charges[i].taxAmount) / 100);
      }
    }
    return totalAmount;
  }

  getPaidTips() {
    let totalTips = 0;
    if (!this.originalOrders) {
      return 0;
    }
    const charges = {};
    this.originalOrders.forEach((o) => {
      if (o.orderStatus === OrderStatuses.PAID) {
        if (o.charge && !charges[o.charge.id]) {
          charges[o.charge.id] = o.charge;
        }
      }
    });
    for (const i in charges) {
      if (charges.hasOwnProperty(i)) {
        totalTips += (charges[i].tipsAmount / 100);
      }
    }
    return totalTips;
  }

  getUnpaidAmount() {
    let totalUnpaid = 0;
    if (!this.originalOrders) {
      return 0;
    }
    this.originalOrders.forEach((o) => {
      if (o.orderStatus === OrderStatuses.COMPLETED) {
        totalUnpaid += o.price.total;
      }
    });
    return totalUnpaid;
  }

  getTotalValue() {
    let totalUnpaidValue = 0;
    if (!this.originalOrders) {
      return 0;
    }
    this.originalOrders.forEach((o) => {
      if (o.orderStatus === OrderStatuses.COMPLETED) {
        totalUnpaidValue += o.price.value;
      }
    });
    return totalUnpaidValue;
  }

  getCuculiFee() {
    const totalValue = this.getTotalValue() * 100;
    let taxTotal;
    let paymentFee;
    let cuculiFee = 0;
    if (totalValue) {
      taxTotal = totalValue * 0.08875;
      paymentFee = ((totalValue + taxTotal) * 0.029 + 30) / 100;
      cuculiFee = (this.getTotalValue() * 0.15) - paymentFee;
    }
    return cuculiFee;
  }

  getRestaurantFee() {
    let totalRestaurantFee = 0;
    if (!this.originalOrders) {
      return 0;
    }
    this.originalOrders.forEach((o) => {
      if (o.orderStatus === OrderStatuses.COMPLETED) {
        totalRestaurantFee += o.price.total;
      }
    });
    return totalRestaurantFee - this.getCuculiFee();
  }

  getTotalTips() {
    let totalUnpaidTips = 0;
    if (!this.originalOrders) {
      return 0;
    }
    this.originalOrders.forEach((o) => {
      if (o.orderStatus === OrderStatuses.COMPLETED) {
        totalUnpaidTips += o.price.tipsTotal;
      }
    });
    return totalUnpaidTips;
  }

  async cancelOrder(order: OrderModel) {
    if (this.canOrderBeCancelled(order)) {
      const res = await this.confirmationDialogService.popup(
        `Cancel Order`,
        `Are you sure you want to cancel this order?`
      );
      if (res) {
        await this.orderService.delete(order.id);
        this.loadOrders();
      }
    }
  }

  async rejectOrder(order: OrderModel) {
    if (this.canOrderBeRejected(order)) {
      const res = await this.confirmationDialogService.popup(
        `Reject Order`,
        `Are you sure you want to reject this order?`
      );
      if (res) {
        await this.orderService.rejectOrder(order);
        this.loadOrders();
      }
    }
  }

  async completeOrder(order: OrderModel) {
    if (this.canOrderBeCompleted(order)) {
      const res = await this.confirmationDialogService.popup(
        `Complete Order`,
        `Are you sure you want to complete this order?`
      );
      if (res) {
        await this.orderService.completeOrder(order);
        this.loadOrders();
      }
    }
  }

  canOrderBeCancelled(order: OrderModel) {
    // tslint:disable-next-line:max-line-length
    return (this.currentUser.id === order.userId && order.orderStatus !== OrderStatuses.COMPLETED && order.orderStatus !== OrderStatuses.PAID);
  }

  canOrderBeRejected(order: OrderModel) {
    return (this.isOwner() && order.orderStatus !== OrderStatuses.PAID);
  }

  isOrderRejected(order: OrderModel) {
    return (order.orderStatus !== OrderStatuses.REJECTED);
  }

  canOrderBeCompleted(order: OrderModel) {
    return (order.orderStatus === OrderStatuses.APPROVED && this.isOwner());
  }

  private async loadOrders() {
    if (this.isOwner()) {
      this.loaOrdersForVenueOwner();
      return;
    }
    if (this.event.type === 'group') {
      this.loadOrdersForGroupEvent();
    } else {
      this.loadOrdersForIndividualEvent();
    }
  }

  private async loadOrdersForIndividualEvent() {
    this.orders = await this.orderService.search({ eventId: this.event.id });
    this.originalOrders = [...this.orders];
    this.participants = await this.getAllParticipants();
    this.participants.forEach(v => {
      if (v.value === this.currentUser.id) {
        v.selected = true;
      }
    });
    this.filterParticipantsRules = [
      GenericFormBuilder.dropdown('participants', null, [], this.participants)
    ];
    setTimeout(() => {
      this.onParticipantSelect();
    });
  }

  private async loadOrdersForGroupEvent() {
    this.originalOrders = await this.orderService.search({ eventId: this.event.id });
    if (this.isAdmin() || this.isOwner()) {
      this.orders = this.originalOrders;
    } else {
      this.orders = this.originalOrders.filter((x) => {
        return x.userId === this.currentUser.id;
      });
    }
  }

  private async loaOrdersForVenueOwner() {
    this.originalOrders = await this.orderService.search({ eventId: this.event.id });
    this.orders = this.originalOrders;
  }

  private getAllParticipants(): Lookup[] {
    const participants = [{
      value: '*',
      label: 'All orders',
    }];
    this.orders.forEach(order => {
      const existingParticipant = participants.find((x) => x.value === order.userId);
      if (!existingParticipant) {
        const userLabel = this.currentUser.id === order.userId ? 'Me' : order.userFullName;
        participants.push({
          value: order.userId,
          label: `Ordered by ${userLabel}`
        });
      }
    });
    return participants;
  }

  async deleteEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Delete ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to delete ${this.event} at ${event.venueName}?`
    );
    if (res) {
      await this.eventService.delete(event.id);
      await this.getAllEventsForUser();
      this.router.navigateByUrl(`/bookings`);
    }
  }

  async leaveEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Leave ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to leave ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      await this.eventService.leaveEvent(event, this.currentUser.email);
      await this.getAllEventsForUser();
      this.router.navigateByUrl('/invitations');
    }
  }

  getEventPrefix(event: EventModel) {
    if (moment(event.startDate).isBefore(moment({ hour: 18, minute: 0 }))) {
      return 'Lunch';
    } else {
      return 'Dinner';
    }
  }

  async getAllEventsForUser() {
    this.events = await this.eventService.search({ participants: this.currentUser.email });
  }

  openInviteModal(event: EventModel) {
    const initialState = {
      event
    };
    const bsModalRef = this.modalService.show(EventParticipantsComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  goToCreateOrder(event: EventModel) {
    this.router.navigateByUrl(`/bookings/${event.id}/orders/add`);
  }

  goToOrders(event: EventModel) {
    this.router.navigateByUrl(`/bookings/${event.id}`);
  }

  openEventInfo(event: EventModel) {
    const initialState = {
      event
    };
    const bsModalRef = this.modalService.show(VenueBookingEditComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  loadEventActions(event: EventModel) {
    const actions = [];
    // Actions for Owner (Admin can see the same)
    if (this.isOwner()) {
      if (event.eventStatus === Statuses.PENDING) {
        actions.push({ label: 'Approve', action: this.approveEvent.bind(this) });
        actions.push({ label: 'Reject', action: this.rejectEvent.bind(this) });
      }
      if (event.eventStatus === Statuses.APPROVED) {
        actions.push({ label: 'Reject', action: this.rejectEvent.bind(this) });
        actions.push({ label: 'Activate', action: this.activateEvent.bind(this) });
        actions.push({ label: 'No Show', action: this.noshowEvent.bind(this) });
      }
      if (event.eventStatus === Statuses.ACTIVE) {
        actions.push({ label: 'Complete', action: this.completeEvent.bind(this) });
        actions.push({ label: 'Deactivate', action: this.dactivateEvent.bind(this) });
        actions.push({ label: 'Create Order', action: this.goToCreateOrder.bind(this) });
      }
      if (event.eventStatus === Statuses.COMPLETED) {
        actions.push({ label: 'Reopen', action: this.reopenEvent.bind(this) });
      }
    } else if (this.venue.ownerId !== this.currentUser.id) {
      // Actions for Participant
      if (event.ownerId !== this.currentUser.id) {
        actions.push({ label: 'Leave', action: this.leaveEvent.bind(this) });
      }
      if (event.eventStatus === Statuses.APPROVED || event.eventStatus === Statuses.PENDING || event.eventStatus === Statuses.ACTIVE) {
        actions.push({ label: 'Create Order', action: this.goToCreateOrder.bind(this) });
      }
      actions.push({ label: 'Invite', action: this.openInviteModal.bind(this) });
      if (event.ownerId === this.currentUser.id) {
        actions.push({ label: 'Cancel', action: this.deleteEvent.bind(this) });
      }
      actions.push({ label: 'Details', action: this.openEventInfo.bind(this) });
    }
    this.eventActions[event.id] = actions;
    return this.eventActions[event.id];
  }

  async approveEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Approve ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to approve ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.approveEvent(event);
      event.eventStatus = response.eventStatus;
      this.loadEventActions(event);
    }
  }

  async dactivateEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Deactivate ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to deactivate ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.approveEvent(event);
      event.eventStatus = response.eventStatus;
      this.loadEventActions(event);
    }
  }

  async rejectEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Reject ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to reject ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.rejectEvent(event);
      event.eventStatus = response.eventStatus;
      this.loadEventActions(event);
    }
  }

  async activateEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Activate ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to activate ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.activateEvent(event);
      event.eventStatus = response.eventStatus;
      this.loadEventActions(event);
    }
  }

  async reopenEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Reopen ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to reopen ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.activateEvent(event);
      event.eventStatus = response.eventStatus;
      this.loadEventActions(event);
    }
  }

  async completeEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Complete ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to complete ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      const response: EventModel = await this.eventService.completeEvent(event);
      event.eventStatus = response.eventStatus;
      this.loadEventActions(event);
    }
  }

  async noshowEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Mark ${this.getEventPrefix(event)} at ${event.venueName} as "No Show"`,
      `Are you sure you want to mark ${this.getEventPrefix(event)} at ${event.venueName} as "No Show"?`
    );
    if (res) {
      const response: EventModel = await this.eventService.noShowEvent(event);
      event.eventStatus = response.eventStatus;
      this.loadEventActions(event);
    }
  }
}
