import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import { Injectable } from '@angular/core';

@Injectable()
export class HappyHourMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('HappyHourApi', 'HappyHourModel');
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('HappyHourForm', 'HappyHourModel')
                .forMember('name', (opts) => opts.sourceObject.name)
                .forMember('species', (opts) => opts.sourceObject.species)
                // tslint:disable-next-line:max-line-length
                .forMember('startDate', (opts) => {
                    const startDate = {
                        day: null,
                        dayUntil: null,
                        start: null,
                        end: null
                    };
                    const dayForm = opts.sourceObject.day && opts.sourceObject.day.length ? opts.sourceObject.day[0] : null;
                    if (dayForm) {
                        startDate.day = dayForm.startDay;
                        startDate.dayUntil = dayForm.endDay;
                    }
                    const timeForm = opts.sourceObject.time && opts.sourceObject.time.length ? opts.sourceObject.time[0] : null;
                    if (timeForm) {
                        startDate.start = timeForm.start;
                        startDate.end = timeForm.end;
                    }
                    return startDate;
                })
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('HappyHourModel', 'HappyHourApi');
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('HappyHourModel', 'HappyHourForm')
                .forMember('day', (opts) => {
                    const day = {
                        startDay: null,
                        endDay: null
                    };
                    const startDate = opts.sourceObject.startDate ? opts.sourceObject.startDate : null;
                    if (startDate) {
                        day.startDay = startDate.day;
                        day.endDay = startDate.dayUntil;
                    }
                    return [day];
                })
                .forMember('time', (opts) => {
                    const time = {
                        start: null,
                        end: null
                    };
                    const startTime = opts.sourceObject.startDate ? opts.sourceObject.startDate : null;
                    if (startTime) {
                        time.start = startTime.start;
                        time.end = startTime.end;
                    }
                    return [time];
                });
        });
    }
}
