import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import { Injectable } from '@angular/core';

@Injectable()
export class MenuItemMapper implements IMapperRegistry {
  constructor(private automapper: AutomapperService) { }

  register() {
    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemApi', 'MenuItemModel')
        .forMember('images', (opts) => {
          if (!opts.sourceObject.images || !opts.sourceObject.images.length) {
            return ['assets/images/meal-default-img.jpg'];
          }
          return opts.sourceObject.images;
        })
        .forMember('workingHours', (opts) => {
          if (!opts.sourceObject.workingHours) {
            return null;
          }
          return this.automapper.mapArray('WorkingDayApi', 'WorkingDayModel', opts.sourceObject.workingHours);
        })
        .forMember('includeAddons', (opts) => {
          if (opts.sourceObject.addonsCategories && opts.sourceObject.addonsCategories.length) {
            return true;
          }
          return false;
        })
        .forMember('alcohol', (opts) => {
          if (opts.sourceObject.features && opts.sourceObject.features.length && (opts.sourceObject.features.indexOf('alcohol') !== -1)) {
            return true;
          }
          return false;
        })
        .forMember('addonsCategories', (opts) => {
          if (opts.sourceObject.addonsCategories && opts.sourceObject.addonsCategories.length) {
            return this.automapper.mapArray('MenuItemAddonsCategoryArray', 'MenuItemAddonsCategoryModel', opts.sourceObject.addonsCategories);
          }
          return [];
        })
    });

    this.automapper.register((mapperCgf) => {
      mapperCgf.createMap('MenuItemModel', 'MenuItemModel')
    })

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemCreateForm', 'MenuItemModel')
        .forMember('type', (opts) => opts.mapFrom('type'))
        .forMember('category', (opts) => {
          if (opts.sourceObject.category && opts.sourceObject.category.value) {
            return opts.sourceObject.category.value;
          }
          return opts.sourceObject.category;
        })
        .forMember('name', (opts) => opts.mapFrom('name'))
        .forMember('description', (opts) => {
          if (!!opts.sourceObject.description) {
            return opts.sourceObject.description;
          }
          return null;
        })
        // tslint:disable-next-line:max-line-length
        .forMember('workingHours', (opts) => {
          if (opts.sourceObject.type === 'happyHour') {
            return this.automapper.mapArray('TimeFrame', 'WorkingDayModel', opts.sourceObject.workingHours);
          }
        })
        .forMember('optionsLabel', (opts) => opts.mapFrom('optionsLabel'))
        .forMember('options', (opts) => this.automapper.mapArray('MenuOptionsArray', 'MenuItemOptionModel', opts.sourceObject.options))
        .forMember('species', (opts) => opts.mapFrom('species'))
        .forMember('marketPrice', (opts) => opts.mapFrom('marketPrice'))
        .forMember('includeAddons', (opts) => opts.sourceObject.includeAddons)
        .forMember('addonsCategories', (opts) => {
          if (opts.sourceObject.addonsCategories && opts.sourceObject.addonsCategories.length && opts.sourceObject.includeAddons) {
            return this.automapper.mapArray('MenuItemAddonsCategoryCreateForm', 'MenuItemAddonsCategoryModel', opts.sourceObject.addonsCategories);
          }
          return null;
        })
        .forMember('minAddonQuantity', (opts) => opts.mapFrom('minAddonQuantity'))
        .forMember('maxAddonQuantity', (opts) => opts.mapFrom('maxAddonQuantity'))
        .forMember('alcohol', (opts) => opts.mapFrom('alcohol'))
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemModel', 'MenuItemApi')
        .forMember('workingHours', (opts) => {
          if (opts.sourceObject.type === 'happyHour') {
            if (!opts.sourceObject.workingHours) {
              opts.sourceObject.workingHours = [];
            }
            return this.automapper.mapArray('WorkingDayModel', 'WorkingDayApi', opts.sourceObject.workingHours);
          } else {
            return null;
          }
        })
        .forMember('total', (opts) => opts.ignore())
        .forMember('addonsCategories', (opts) => {
          if (opts.sourceObject.addonsCategories && opts.sourceObject.addonsCategories.length) {
            return this.automapper.mapArray('MenuItemAddonsCategoryModel', 'MenuItemAddonsCategoryArray', opts.sourceObject.addonsCategories);
          }
          return null;
        })
        .forMember('features', (opts) => {
          return (opts.sourceObject.alcohol) ? ['alcohol'] : [];
        })
        .forMember('alcohol', (opts) => opts.ignore())
        .forMember('includeAddons', (opts) => opts.ignore());
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemModel', 'MenuItemEditForm')
        .forMember('workingHours', (opts) => {
          // tslint:disable-next-line:max-line-length
          return opts.sourceObject.workingHours ? opts.sourceObject.workingHours : [];
        })
        .forMember('options', (opts) => {
          const menuItemOptions: any = this.automapper.map('MenuItemOptionModel', 'MenuOptionsArray', opts.sourceObject.options);
          return menuItemOptions ? menuItemOptions : [];
        });
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuOptionsArray', 'MenuItemOptionModel')
        .forMember('name', (opts) => opts.mapFrom('name'))
        .forMember('type', (opts) => opts.sourceObject.type ? opts.mapFrom('type') : null)
        .forMember('price', (opts) => {
          return {
            taxIncluded: false,
            tipsIncluded: false,
            taxTotal: null,
            tipsTotal: null,
            value: opts.sourceObject.total ? opts.sourceObject.total : opts.sourceObject.value,
            total: opts.sourceObject.total ? opts.sourceObject.total : opts.sourceObject.value
          };
        })
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemOptionModel', 'MenuOptionsArray')
        .forMember('name', (opts) => opts.mapFrom('name'))
        .forMember('type', (opts) => opts.sourceObject.type ? opts.sourceObject.type : null)
        .forMember('total', (opts) => opts.mapFrom('price.value'));
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemAddonsCategoryArray', 'MenuItemAddonsCategoryModel')
        .forMember('addonsLabel', (opts) => opts.sourceObject.addonsLabel)
        .forMember('addons', (opts) => {
          if (opts.sourceObject.addons && opts.sourceObject.addons.length) {
            return this.automapper.mapArray('MenuItemAddonArray', 'MenuItemAddonModel', opts.sourceObject.addons);
          }
          return [];
        })
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemAddonsCategoryCreateForm', 'MenuItemAddonsCategoryModel')
        .forMember('addonsLabel', (opts) => opts.mapFrom('addonsLabel'))
        .forMember('addons', (opts) => {
          if (opts.sourceObject.addons && opts.sourceObject.addons.length) {
            return this.automapper.mapArray('MenuItemAddonModel', 'MenuItemAddonArray', opts.sourceObject.addons);
          }
          return null;
        })
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemAddonsCategoryModel', 'MenuItemAddonsCategoryArray')
        .forMember('addonsLabel', (opts) => opts.mapFrom('addonsLabel'))
        .forMember('addons', (opts) => {
          if (opts.sourceObject.addons && opts.sourceObject.addons.length) {
            return opts.sourceObject.addons;
          }
          return null;
        })
    })

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemAddonArray', 'MenuItemAddonModel')
        .forMember('addonName', (opts) => opts.sourceObject.name)
        .forMember('addonTotal', (opts) => opts.sourceObject.price ? opts.sourceObject.price : null)
        .forMember('id', (opts) => opts.sourceObject.id)
        .ignoreAllNonExisting();
    });

    this.automapper.register((mapperCfg) => {
      mapperCfg
        .createMap('MenuItemAddonModel', 'MenuItemAddonArray')
        .forMember('name', (opts) => opts.sourceObject.addonName)
        .forMember('price', (opts) => opts.sourceObject.addonTotal ? opts.sourceObject.addonTotal : 0)
        .forMember('id', (opts) => opts.sourceObject.id ? opts.sourceObject.id : null)
        .ignoreAllNonExisting();
    });
  }
}
