export const environment = {
  production: process.env["PRODUCTION"],
  api: {
    host: process.env["HOST"]
  },
  dateFormat: process.env["DATE_FORMAT"],
  datTimeFormat: process.env["DATTIMEFORMAT"],
  stripeKey: process.env["STRIPEKEY"],
  intercomId: process.env["INTERCOMID"],
  homePage: process.env["HOMEPAGE"]
};
