import { Component, OnInit, Input } from '@angular/core';
import { EventModel } from '../models/event.model';
import { OrderService } from './order.service';
import { MenuService } from '../menu-item/menu.service';
import { MenuItemModel, MenuItemOptionModel } from '../models/menu-item.model';
import { UserModel } from '../models/user.model';
import { AuthService } from '../common/auth.service';
import { VenueModel } from '../models/venue.model';
import { EventService } from '../event/event.service';
import { VenueService } from '../venue/venue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AutomapperService } from '../common/automapper.service';
import { BsModalService } from 'ngx-bootstrap';
import { OrderModel } from '../models/order.model';
import { OrderPaymentComponent } from './order.payment.component';
import { UserRoles } from '../data/user.roles.data';
import { Statuses } from '../data/statuses.data';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';
import { MenuModel, MenuDataCategory } from '../models/menu.model';
import { EnumService } from '../common/enum.service';

@Component({
  selector: 'app-order-create',
  templateUrl: './order.create.component.html',
  styleUrls: ['./order.create.component.scss'],
})
export class OrderCreateComponent implements OnInit {

  // provided by modal client
  event: EventModel;
  // provided by modal client
  venue: VenueModel;
  menuModels: MenuModel[];
  currentUser: UserModel;
  shownDetails: string;
  basket: any[] = [];
  orderInProgress: boolean;
  orderAndPayInProgress: boolean;
  menuTabs = [];
  statuses = Statuses;
  basketOpened: boolean;
  mobileView: boolean;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private venueService: VenueService,
    private orderService: OrderService,
    private menuService: MenuService,
    private authService: AuthService,
    private router: Router,
    private autoMapper: AutomapperService,
    private confirmationDialogService: ConfirmationDialogService,
    private enumService: EnumService,
    private modalService: BsModalService) { }

  async ngOnInit() {
    this.orderInProgress = false;
    this.orderAndPayInProgress = false;
    const id = this.route.snapshot.paramMap.get('id');
    this.event = await this.eventService.getById(id);
    this.venue = await this.venueService.getById(this.event.venueId);
    await this.loadMenus();
    this.currentUser = this.authService.getCurrentUser();
    this.loadMenuTabs();
    this.basketOpened = !this.isMobileView();
  }

  async loadMenus() {
    const allMenues = await this.menuService.getAllMenues(this.venue.id, true);
    this.menuModels = allMenues.filter(m => !m.disabled);
    this.menuModels.forEach((mm: MenuModel) => {
      mm.categories.forEach((c: MenuDataCategory) => {
        c.items.forEach((item: MenuItemModel) => {
          item.options.forEach((option) => {
            let addons = [];
            item.addonsCategories.forEach(ac => ac.addons.forEach(addon => addons.push({ ...addon })))
            option.quantity = 1;
            option.optionAddons = addons;
          });
        });
      });
    });
  }

  async addToOrder(item: MenuItemModel, option: MenuItemOptionModel) {
    this.basket.push({
      menuItemId: item.id,
      optionId: option.id,
      name: `${item.name} - ${option.name}`,
      price: option.price.value,
      quantity: option.quantity,
      addons: option.optionAddons && option.optionAddons.length ? option.optionAddons.filter((addon) => addon.includeAddon) : [],
      specialInstructions: option.specialInstructions,
    });
  }

  async createOrder(pay: boolean) {

    const res = await this.confirmationDialogService.popup('Confirm Order', 'Are you sure you want to confirm this order?');
    if (res) {
      this.createConfirmedOrder(pay);
    }
  }

  async createConfirmedOrder(pay: boolean) {
    this.orderInProgress = !pay;
    this.orderAndPayInProgress = pay;
    console.log('BASKET', this.basket);
    try {
      let order: OrderModel = null;
      if (this.currentUser.id === this.venue.ownerId) {
        // tslint:disable-next-line:max-line-length
        order = await this.orderService.createByVenue(this.event.id, this.venue.id, this.autoMapper.mapArray('BasketModel', 'BasketApi', this.basket));
      } else {
        // tslint:disable-next-line:max-line-length
        order = await this.orderService.createByParticipant(this.event.id, this.autoMapper.mapArray('BasketModel', 'BasketApi', this.basket));
      }
      this.orderInProgress = false;
      this.orderAndPayInProgress = false;
      if (pay) {
        this.openPaymentForm(order);
      } else {
        this.backToOrdersPage();
      }
    } catch {
      this.orderInProgress = false;
      this.orderAndPayInProgress = false;
    }
  }

  async loadMenuTabs() {
    const menuTypes = await this.enumService.getByType('menu-types');
    menuTypes.forEach(element => {
      const items = this.getMenuModelItems(element.key);
      if (items && items.length && this.doesMenuContainItems(items[0])) {
        this.menuTabs.push({ key: element.key, label: element.value, items });
      }
    });
  }

  isAdmin() {
    return this.currentUser && this.currentUser.role === UserRoles.ADMIN;
  }

  isOwner() {
    return (this.currentUser && this.currentUser.id === this.venue.ownerId);
  }

  getMenuModelItems(menu: string) {
    return this.menuModels.filter((x) => x.type === menu);
  }

  getLowestPrice(menu: MenuItemModel) {
    const prices = [];
    if (menu.options) {
      menu.options.forEach((option) => {
        prices.push(option.price.value);
      });
    }
    return Math.min(...prices);
  }

  removeFromOrder(item: any, index: number) {
    this.basket.splice(index, 1);
    this.isItemAdded(item, item.optionId);
  }

  isItemAdded(item: MenuItemModel, option: MenuItemOptionModel) {
    const existingItem = this.basket.find((x) => x.menuItemId === item.id && x.optionId === option.id);
    return !!existingItem;
  }

  openDetails(menu: MenuItemModel) {
    if (this.shownDetails === menu.name) {
      this.shownDetails = null;
    } else {
      this.shownDetails = menu.name;
    }
  }

  backToOrdersPage() {
    this.router.navigateByUrl(`/bookings/${this.event.id}`);
  }

  getBasketTotal() {
    let res = 0;
    this.basket.forEach((x) => {
      res += (x.price * x.quantity);
      x.addons.forEach((addon) => {
        res += addon.addonTotal;
      });
    });
    return res;
  }

  openPaymentForm(order: OrderModel) {
    const initialState = {
      order,
      onClose: this.backToOrdersPage.bind(this)
    };
    const bsModalRef = this.modalService.show(OrderPaymentComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  toggleBasket() {
    if (this.isMobileView()) {
      this.basketOpened = !this.basketOpened;
    }
  }

  shouldShowCategory(category) {
    return (category.items && category.items.length);
  }

  private isMobileView() {
    this.mobileView = (window.innerWidth < 992);
    return this.mobileView;
  }

  private doesMenuContainItems(menu: MenuModel) {
    if (menu.categories && menu.categories.length) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < menu.categories.length; i++) {
        if (menu.categories[i].items && menu.categories[i].items.length) {
          return true;
        }
      }
    }
    return false;
  }
}
