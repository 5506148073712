import { GenericFormType } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';

export class WaiterModel {
    id: string;
    firstName: string;
    lastName: string;
    venueId: string;
}

export const WaiterCreateConstraints: GenericFormType[] = [
    GenericFormBuilder.text('firstName', 'First Name', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('lastName', 'Last Name', [], false, null, 'col-md-6 inline-block')
];
