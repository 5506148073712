import { Component, OnInit, AfterViewInit, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HourSlots } from '../../data/hour.slots.data';
import { EventModel } from '../../models/event.model';
import { AuthService } from '../../common/auth.service';
import { NotificationService } from '../../common/notification.service';
import { AutomapperService } from '../../common/automapper.service';
import { EventService } from '../event.service';
import { extendMoment } from 'moment-range';
import { VenueModel } from '../../models/venue.model';
import { EventTypes } from '../../data/event.types.data';
import * as Moment from 'moment';
import { VenueService } from '../../venue/venue.service';
const moment = extendMoment(Moment);

@Component({
  selector: 'show-map-component',
  templateUrl: './show-map-component.html',
  styleUrls: ['./show-map-component.scss']
})
export class showMapComponent implements OnInit, AfterViewInit {

  @Output() created: EventEmitter<EventModel> = new EventEmitter();
  @ViewChild('gmap') gmapElement: any;

  createTableForm = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    time: new FormControl(null, [Validators.required])
  });

  map: google.maps.Map;
  venue: VenueModel;
  event: any;
  createTableDays: any;
  createTableTimeSlots: any;
  seatNumber = 1;
  currentUser: any;
  createInProgress = false;
  marker: any;

  constructor(
    private automapper: AutomapperService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private venueService: VenueService,
    private cr: ChangeDetectorRef,

  ) { }

  ngOnInit() {}

  async ngAfterViewInit() {
    const mapProp = {
      center: new google.maps.LatLng(44.787197, 20.457273),
      zoom: 13,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      streetViewControl: false,
      disableDoubleClickZoom: true,
      zoomControl: true,
      scrollwheel: true
    };
    const id: string = this.route.snapshot.paramMap.get("id");
    this.cr.detectChanges();
    this.event = await this.eventService.getPublicById(id);
    this.venue = this.event.venue;
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);
    this.prepareMapLocation();
  }

  private prepareMapLocation() {
    const icon = {
       path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
       fillColor: '#242847',
       fillOpacity: .9,
       anchor: new google.maps.Point(0, 0),
       strokeWeight: 0,
       scale: 0.55
    };
    this.marker = new google.maps.Marker({
       position: {
          lat: this.venue.location.latitude,
          lng: this.venue.location.longitude
       },
       map: this.map,
       draggable: false,
       icon
    });
    this.map.setCenter(this.marker.getPosition());
 }

}
