import { Component, OnInit } from '@angular/core';
import { PagesService } from '../common/pages.service';
import { PageModel } from '../models/page.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { GetTheAppPopupComponent } from './get-the-app-popup.component';
import { HomeBannerComponent } from './home.banner.component';

@Component({
  selector: 'app-home-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  page: PageModel;
  bsModalRef: BsModalRef;
  graphicType = 'book';
  showRestOfReviews = false;
  graphicTypes = [
    'book',
    'order',
    'pay',
  ];
  constructor(
    private pagesService: PagesService,
    private modalService: BsModalService,
  ) { }

  async ngOnInit() {
    this.page = await this.pagesService.getByName('home');
    // Disable home banner popup
    // setTimeout(() => {
    //   this.openBannerPopup();
    // }, 500);
  }

  mouseOver = (type) => {
    this.graphicType = type;
  }

  showAllReviews = () => {
    this.showRestOfReviews = true;
  }

  isMobile() {
    return window.innerWidth < 768;
  }

  openGetTheAppPopup() {
    const initialState = {
      appType: 'user-app'
    };
    const bsModalRef = this.modalService.show(GetTheAppPopupComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  openBannerPopup() {
    const initialState = {
    };
    const bsModalRef = this.modalService.show(HomeBannerComponent, { initialState });
    // bsModalRef.setClass('modal-lg');
  }

  featureNavigationHandler(action: string) {
    const currentPosition = this.graphicTypes.indexOf(this.graphicType);
    if (action === 'back') {
      if (currentPosition > 0) {
        this.graphicType = this.graphicTypes[currentPosition - 1];
      } else {
        this.graphicType = this.graphicTypes[this.graphicTypes.length - 1];
      }
    }
    if (action === 'forward') {
      if (currentPosition < this.graphicTypes.length - 1) {
        this.graphicType = this.graphicTypes[currentPosition + 1];
      } else {
        this.graphicType = this.graphicTypes[0];
      }
    }
  }
}
