export const FeatureTypes = {
  'features-general': {
    name: 'General',
    icon: 'fas fa-clipboard-list'
  },
  'features-food': {
    name: 'Food Options',
    icon: 'fas fa-hamburger'
  },
  'features-good-for': {
    name: 'Good For',
    icon: 'fas fa-users'
  },
  'features-parking': {
    name: 'Parking',
    icon: 'fas fa-parking'
  }
};
