import { NgModule } from '@angular/core';
import { UserMapper } from './user.mapper';
import { VenueMapper } from './venue.mapper';
import { PageMapper } from './page.mapper';
import { LocationMapper } from './location.mapper';
import { WorkingWeekMapper } from './working.week.mapper';
import { MenuItemMapper } from './menu-item.mapper';
import { MenuMapper } from './menu.mapper';
import { EnumMapper } from './enum.mapper';
import { EventMapper } from './event.mapper';
import { OrderMapper } from './order.mapper';
import { BasketMapper } from './basket.mapper';
import { WaiterMapper } from './waiter.mapper';
import { HappyHourMapper } from './happy-hour.mapper';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        UserMapper,
        VenueMapper,
        PageMapper,
        MenuItemMapper,
        MenuMapper,
        LocationMapper,
        WorkingWeekMapper,
        EnumMapper,
        EventMapper,
        OrderMapper,
        BasketMapper,
        WaiterMapper,
        HappyHourMapper
    ],
})
export class MappersModule {

    constructor(
        private userMapper: UserMapper,
        private venueMapper: VenueMapper,
        private pageMapper: PageMapper,
        private lcationMapper: LocationMapper,
        private workingWeekMapper: WorkingWeekMapper,
        private menuItemMapper: MenuItemMapper,
        private menuMapper: MenuMapper,
        private enumMapper: EnumMapper,
        private eventMapper: EventMapper,
        private orderMapper: OrderMapper,
        private basketMapper: BasketMapper,
        private waiterMapper: WaiterMapper,
        private happyHourMapper: HappyHourMapper) {
        this.userMapper.register();
        this.venueMapper.register();
        this.pageMapper.register();
        this.lcationMapper.register();
        this.workingWeekMapper.register();
        this.menuItemMapper.register();
        this.menuMapper.register();
        this.enumMapper.register();
        this.eventMapper.register();
        this.orderMapper.register();
        this.basketMapper.register();
        this.waiterMapper.register();
        this.happyHourMapper.register();
    }
}
