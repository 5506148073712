import { Component, OnInit, ViewChild } from '@angular/core';
import { GenericFormComponent, GenericFormType } from '../generic-form/generic.form.component';
import { UserCreateConstraints, UserModel } from '../models/user.model';
import { UserService } from './user.service';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserRoles } from '../data/user.roles.data';
import { PaymentCreateComponent } from '../payment/payment.create.component';
import { NotificationService } from '../common/notification.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-user-create',
  templateUrl: './user.create.component.html',
})
export class UserCreateComponent implements OnInit {

  @ViewChild('genericForm') genericForm: GenericFormComponent;
  @ViewChild('paymentCreate') paymentCreate: PaymentCreateComponent;

  createRules: GenericFormType[] = UserCreateConstraints;
  stripeValid = false;
  createInProgress: boolean;
  isCreditCardFormOpen: boolean;
  returnUrl: string;
  hidePaymentInfo: boolean;
  hideHeader: boolean;

  options: any = {
    hidePostalCode: true
  };

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private location: Location
  ) { }

  ngOnInit() {
    this.createInProgress = false;
    this.stripeValid = true;
    this.isCreditCardFormOpen = false;
    this.returnUrl =  this.route.snapshot.queryParams.returnUrl || '/search';
    this.hidePaymentInfo = !!this.route.snapshot.data.hidePayment;
    this.hideHeader = !!this.route.snapshot.data.hideHeader;
  }

  toggleCreditCard() {
    this.isCreditCardFormOpen = !this.isCreditCardFormOpen;
  }

  goBack() {
    this.location.back();
  }

  async create() {
    const accountFormValid = this.genericForm.isValid();
    if (!accountFormValid) {
      return null;
    }
    this.createInProgress = true;
    const user: UserModel = this.genericForm.toModel<UserModel>('UserCreateForm', 'UserModel');
    if (this.isCreditCardFormOpen) {
      const paymentFormValid = this.paymentCreate.isValid();
      if (paymentFormValid) {
        const stripeSource = await this.paymentCreate.getPaymentSource(`${user.firstName} ${user.lastName}`);
        if (!stripeSource) {
          this.createInProgress = false;
          this.notificationService.warning('Invalid card', 'Something went wrong, card can not be verified please try again later');
          return null;
        }
        user.payment = {
          source: stripeSource,
        };
      }
    }
    try {
      user.role = UserRoles.REGULAR;
      user.username = user.email;
      await this.userService.create(user);
      await this.authService.login(user.email, user.password);
      this.router.navigateByUrl(this.returnUrl);
    } catch (err) {
      this.createInProgress = false;
    }
  }
}
