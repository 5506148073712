import { Component, OnInit } from '@angular/core';
import { EventModel } from '../models/event.model';
import { AuthService } from '../common/auth.service';
import { UserModel } from '../models/user.model';
import { EventService } from '../event/event.service';
import { BsModalService } from 'ngx-bootstrap';
import { EventParticipantsComponent } from '../event/event.participants.component';
import { VenueBookingEditComponent } from '../venue/venue.booking.edit.component';
import { Router } from '@angular/router';
import { Statuses } from '../data/statuses.data';
import { ConfirmationDialogService } from '../common/confirmation.dialog.service';
import * as moment from 'moment';

@Component({
  selector: 'app-user-bookings',
  templateUrl: './user.bookings.component.html'
})
export class UserBookingsComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private modalService: BsModalService,
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router) { }

  events: EventModel[];
  currentUser: UserModel;
  private actionLists = {};

  getActionsList = ((event: EventModel) => {
    if (this.actionLists[event.id]) {
      return this.actionLists[event.id];
    }
    const actions = [];
    if (event.ownerId !== this.currentUser.id) {
      actions.push({ label: 'Leave', action: this.leaveEvent.bind(this) });
    }
    if (event.eventStatus === Statuses.APPROVED || event.eventStatus === Statuses.PENDING || event.eventStatus === Statuses.ACTIVE) {
      actions.push({ label: 'Create Order', action: this.goToCreateOrder.bind(this) });
      actions.push({ label: 'Orders', action: this.goToOrders.bind(this) });
    }
    actions.push({ label: 'Invite', action: this.openInviteModal.bind(this) });
    if (event.ownerId === this.currentUser.id) {
      actions.push({ label: 'Details', action: this.openEventInfo.bind(this) });
      actions.push({ label: 'Cancel', action: this.deleteEvent.bind(this) });
    }
    this.actionLists[event.id] = actions;
    return actions;
  }).bind(this);

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.getAllEventsForUser();
  }

  async getAllEventsForUser() {
    this.events = await this.eventService.search({ participants: this.currentUser.email });
  }

  async deleteEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Delete ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to delete ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      await this.eventService.delete(event.id);
      await this.getAllEventsForUser();
    }
  }

  async leaveEvent(event: EventModel) {
    const res = await this.confirmationDialogService.popup(
      `Leave ${this.getEventPrefix(event)} at ${event.venueName}`,
      `Are you sure you want to leave ${this.getEventPrefix(event)} at ${event.venueName}?`
    );
    if (res) {
      await this.eventService.leaveEvent(event, this.currentUser.email);
      await this.getAllEventsForUser();
    }
  }

  getEventPrefix(event: EventModel) {
    if (moment(event.startDate).isBefore(moment({ hour: 18, minute: 0 }))) {
      return 'Lunch';
    } else {
      return 'Dinner';
    }
  }

  openInviteModal(event: EventModel) {
    const initialState = {
      event
    };
    const bsModalRef = this.modalService.show(EventParticipantsComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }

  goToCreateOrder(event: EventModel) {
    this.router.navigateByUrl(`/bookings/${event.id}/orders/add`);
  }

  goToOrders(event: EventModel) {
    this.router.navigateByUrl(`/bookings/${event.id}`);
  }

  openEventInfo(event: EventModel) {
    const initialState = {
      event
    };
    const bsModalRef = this.modalService.show(VenueBookingEditComponent, { initialState });
    bsModalRef.setClass('modal-lg');
  }
}
