import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { OrderModel } from '../models/order.model';
import { BsModalRef } from 'ngx-bootstrap';
import { OrderService } from './order.service';

@Component({
  selector: 'app-order-payment',
  templateUrl: './order.payment.component.html'
})
export class OrderPaymentComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentInformation') paymentInformation: any;
  // passeed from modal client
  order: OrderModel;
  // passed from modal client
  onClose: () => void;

  totalAmount: number;
  chargeInProgress: boolean;
  delayedChargeInProgress: boolean;
  selectedTipPercent: number;
  amount: number;
  tax: number;
  tips: number;

  constructor(
    public bsModalRef: BsModalRef,
    private orderService: OrderService,
  ) { }

  ngOnInit() {
    this.totalAmount = 0;
    this.chargeInProgress = false;
    this.delayedChargeInProgress = false;
    this.selectedTipPercent = 0;

    this.amount = this.order.price.value;
    this.tax = this.order.price.taxTotal;
    this.tips = this.order.price.tipsTotal;
  }

  ngAfterViewInit() {
    this.recalculateTotalAmount();
  }

  selectTip(value: number) {
    this.selectedTipPercent = value;
    const amount: number = this.amount;
    this.tips = amount * (this.selectedTipPercent / 100);
    this.recalculateTotalAmount();
  }

  removeTipPercent(event) {
    this.selectedTipPercent = 0;
    this.tips = Number(event.currentTarget.value);
    this.recalculateTotalAmount();
  }

  selectCredit() {
    const useCredit: boolean = this.paymentInformation.get('useCredit');
    this.paymentInformation.set(useCredit, !useCredit);
    this.recalculateTotalAmount();
  }

  async recalculateTotalAmount() {
    const useCredit: boolean = this.paymentInformation.get('useCredit');
    const credit: number = useCredit ? this.paymentInformation.get('credit') : 0;
    const tips: number = this.tips;
    const tax: number = this.tax;
    const amount: number = this.amount;
    this.totalAmount = (amount + tax + tips) - credit;
  }

  close() {
    this.bsModalRef.hide();
    if (this.onClose) {
      this.onClose();
    }
  }

  async buy() {
    this.chargeInProgress = true;
    const source = this.paymentInformation.getPaymentSource();
    const res = await this.orderService.payOrder(this.order, source, this.tips, !!this.paymentInformation.get('saveCard'));
    if (!res) {
      this.chargeInProgress = false;
    }
    alert('Transfer success');
    this.paymentInformation.resetForm();
    this.totalAmount = 0;
    this.chargeInProgress = false;
    this.close();
  }
}
