import { Component, OnInit } from '@angular/core';
import { UserService } from '../user/user.service';
import { AuthService } from '../common/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

import { NotificationService } from '../common/notification.service';
import { VenueService } from '../venue/venue.service';

@Component({
  selector: 'app-reservation-list',
  templateUrl: './reservation-list.component.html',
})
export class ReservationListComponent implements OnInit {

  constructor(
    private venueService: VenueService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService
  ) { }

  async ngOnInit() {
  }

}
