import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { ApiService } from './api.service';
import { ToasterService } from 'angular2-toaster';

@Injectable()
export class NotificationService {

  constructor(
    private toasterService: ToasterService) { }


  public info(title: string, message?: string) {
    this.toasterService.pop('info', title, message);
  }

  public success(title: string, message?: string) {
    this.toasterService.pop('success', title, message);
  }

  public error(title: string, message?: string) {
    this.toasterService.pop('error', title, message);
  }

  public warning(title: string, message?: string) {
    this.toasterService.pop('warning', title, message);
  }

  public clear() {
    this.toasterService.clear();
  }
}
