import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class UserMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('UserApi', 'User')
                .forMember('id', (opts) => opts.mapFrom('id'))
                .forMember('username', (opts) => opts.mapFrom('username'))
                .forMember('password', (opts) => opts.mapFrom('password'))
                .forMember('email', (opts) => opts.mapFrom('email'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('role', (opts) => opts.mapFrom('role'))
                .forMember('status', (opts) => opts.mapFrom('status'))
                .forMember('token', (opts) => opts.mapFrom('token'))
                .forMember('credit', (opts) => opts.mapFrom('credit'))
                .forMember('venueIds', (opts) => opts.mapFrom('venueIds'))
                .forMember('createdAt', (opts) => moment(opts.sourceObject.createdAt).format(environment.dateFormat))
                .forMember('referredBy', (opts) => opts.mapFrom("referredBy"));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('User', 'UserApi')
                .forMember('id', (opts) => opts.mapFrom('id'))
                .forMember('username', (opts) => opts.mapFrom('username'))
                .forMember('password', (opts) => opts.mapFrom('password'))
                .forMember('email', (opts) => opts.mapFrom('email'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('venueIds', (opts) => opts.mapFrom('venueIds'))
                .forMember('role', (opts) => opts.mapFrom('role'))
                .forMember('status', (opts) => opts.ignore())
                .forMember('token', (opts) => opts.ignore())
                .forMember('createdAt', (opts) => opts.ignore())
                .forMember('credit', (opts) => opts.ignore())
                .forMember('referredBy', (opts) => opts.mapFrom("referredBy"));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('UserStorage', 'User')
                .forMember('id', (opts) => opts.mapFrom('id'))
                .forMember('username', (opts) => opts.mapFrom('username'))
                .forMember('password', (opts) => opts.mapFrom('password'))
                .forMember('email', (opts) => opts.mapFrom('email'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('role', (opts) => opts.mapFrom('role'))
                .forMember('status', (opts) => opts.mapFrom('status'))
                .forMember('token', (opts) => opts.mapFrom('token'))
                .forMember('venueIds', (opts) => opts.mapFrom('venueIds'))
                .forMember('createdAt', (opts) => opts.mapFrom('createdAt'))
                .forMember('referredBy', (opts) => opts.mapFrom("referredBy"));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('UserCreateForm', 'UserModel')
                .forMember('repeatPassword', (opts) => opts.ignore())
                .forMember('username', (opts) => opts.mapFrom('username'))
                .forMember('password', (opts) => opts.mapFrom('password'))
                .forMember('email', (opts) => opts.mapFrom('email'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('phone', (opts) => opts.sourceObject.phone ? opts.sourceObject.phone.number : null)
                .forMember('promoCode', (opts) => opts.sourceObject.promoCode ? opts.mapFrom("promoCode") : null);
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('UserModel', 'UserCreateForm')
                .forMember('username', (opts) => opts.mapFrom('username'))
                .forMember('email', (opts) => opts.mapFrom('email'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('phone', (opts) => opts.mapFrom('phone'))
                .forMember('promoCode', (opts) => opts.mapFrom("promoCode"));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('UserModel', 'UserUpdateForm')
                .forMember('username', (opts) => opts.mapFrom('username'))
                .forMember('email', (opts) => opts.mapFrom('email'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('phone', (opts) => opts.mapFrom('phone'))
                .forMember('referredBy', (opts) => opts.mapFrom("referredBy"));
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('UserUpdateForm', 'UserModel')
                .forMember('username', (opts) => opts.mapFrom('username'))
                .forMember('email', (opts) => opts.mapFrom('email'))
                .forMember('firstName', (opts) => opts.mapFrom('firstName'))
                .forMember('lastName', (opts) => opts.mapFrom('lastName'))
                .forMember('phone', (opts) => opts.sourceObject.phone ? opts.sourceObject.phone.number : null)
                .forMember('referredBy', (opts) => opts.mapFrom("referredBy"))
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('UserModel', 'NewCreditCard')
                .forMember('name', (opts) => {
                    const fullName = `${opts.sourceObject.firstName} ${opts.sourceObject.lastName}`;
                    return `${fullName}`;
                })
                .ignoreAllNonExisting();
        });
    }
}
