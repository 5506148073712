import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class LocationMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('GoogleResult', 'LocationModel')
                .forMember('latitude', (opts) => opts.sourceObject.geometry.location.lat())
                .forMember('longitude', (opts) => opts.sourceObject.geometry.location.lng())
                .forMember('address', (opts) => opts.sourceObject.formatted_address)
                .forMember('city', (opts) => {
                    const city = opts.sourceObject.address_components.find((x) => x.types.indexOf('locality') !== -1);
                    if (city) {
                        return city.long_name;
                    }
                })
                .forMember('postalCode', (opts) => {
                    const postalCode = opts.sourceObject.address_components.find((x) => x.types.indexOf('postal_code') !== -1);
                    if (postalCode) {
                        return postalCode.long_name;
                    }
                })
                .forMember('country', (opts) => {
                    const country = opts.sourceObject.address_components.find((x) => x.types.indexOf('country') !== -1);
                    if (country) {
                        return country.long_name;
                    }
                })
                .forMember('county', (opts) => {
                    const county = opts.sourceObject.address_components.find((x) => x.types.indexOf('administrative_area_level_2') !== -1);
                    if (county) {
                        return county.long_name;
                    }
                })
                .forMember('state', (opts) => {
                    const state = opts.sourceObject.address_components.find((x) => x.types.indexOf('administrative_area_level_1') !== -1);
                    if (state) {
                        return state.long_name;
                    }
                })
                .forMember('countryCode', (opts) => {
                    const country = opts.sourceObject.address_components.find((x) => x.types.indexOf('country') !== -1);
                    if (country) {
                        return country.short_name;
                    }
                })
                .ignoreAllNonExisting();
        });
    }
}
