import { Injectable } from "@angular/core";
import { BaseService } from "../common/base.service";
import { ApiService } from "../common/api.service";
import { AutomapperService } from "../common/automapper.service";
import { SubscriptionModel } from "../models/subscription.model";
import { StripeSource } from "stripe-angular/StripeTypes";
import { AuthService } from "../common/auth.service";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService extends BaseService<SubscriptionModel> {
  constructor(
    apiService: ApiService,
    automapper: AutomapperService,
    private authService: AuthService
  ) {
    super(apiService, automapper);
  }

  protected getModelName(): string {
    return "SubscriptionModel";
  }
  protected getApiName(): string {
    return "SubscriptionApi";
  }
  protected getApiPrefix(): string {
    return "subscription";
  }

  async createSubscription(payload: {
    username: string;
    password: string;
    selectedPrice: string;
    bankData: {
      bankAccountName: string;
      bankAccountNumber: string;
      routingNumber: string;
    };
    source: StripeSource;
    selectedCreditCardBrand: string;
    timerPerWeek: number;
  }) {
    console.log("====================================", payload);

    let user = this.authService.getCurrentUser();
    if (user === null) {
      user = await this.authService.login(payload.username, payload.password);
    }

    const data = {
      userId: user.id,
      cardBrand: payload.selectedCreditCardBrand,
      price: payload.selectedPrice.split(" - ").map(Number),
      bankData: payload.bankData,
      creditCard: payload.source,
      timerPerWeek: payload.timerPerWeek,
    };

    try {
      const userSubscription = await this.apiService.post(
        `/subscription-credits`,
        data,
        []
      );
      return userSubscription;
    } catch (error) {
      console.log(error);
    }
  }
}
