import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation.dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {
    confirmationInProgress: boolean;
    title: string;
    message: string;
    result: Subject<boolean>;

    constructor(
        private bsModalRef: BsModalRef,
    ) { }
    async ngOnInit() {
        this.result = new Subject();
        this.confirmationInProgress = false;
    }

    async confirmation() {
        this.confirmationInProgress = true;
        this.result.next(true);
        this.confirmationInProgress = false;
        this.bsModalRef.hide();
    }

    async cancel() {
        this.confirmationInProgress = true;
        this.result.next(false);
        this.confirmationInProgress = false;
        this.bsModalRef.hide();
    }
}
