import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PagesService } from '../common/pages.service';
import { PageModel } from '../models/page.model';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent implements OnInit {

  page: PageModel;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pagesService: PagesService) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      this.init();
    });
  }

  private async init() {
    const pageName: string = this.route.snapshot.paramMap.get('page');
    this.page = await this.pagesService.getByName(pageName);
    if (!this.page) {
      this.router.navigateByUrl('/');
    }
  }
}
