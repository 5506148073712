import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { AuthService } from "../common/auth.service";
import { UserModel } from "../models/user.model";
import { SubscriptionService } from "./subscription.service";
import { NotificationService } from "../common/notification.service";
import {
  GenericFormComponent,
  GenericFormType,
} from "../generic-form/generic.form.component";
import { GenericFormBuilder } from "../generic-form/generic.form.builder";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StripeCard, StripeSource } from "stripe-angular";
import { Countries } from "../data/countries.data";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
})
export class SubscriptionComponent implements OnInit {
  @ViewChild("stripeCard") stripeCard: StripeCard;
  @ViewChild("addressForm") addressForm: GenericFormComponent;

  subscriptionText: string = "Cuculi points subscription is in official beta testing. It is available to limited amount of users and it enables purchase of Cuculi points risk free, where we guarantee to fulfill every refund request for unspent points (minus the CC transaction fee).";
  subscriptionText2: string = "Thank you for helping us test and develop this product and please message us at customersupport@cuculi.com if you have any questions or concerns.";

  loggedInUser: UserModel;
  paymentOptions: any[];
  selectedCard: any;
  options: any = {
    hidePostalCode: true,
    disableLink: true,
  };
  // we could have them as parameters
  availablePrices = [
    "300 - 500",
    "501 - 700",
    "701 - 1000",
  ];
  timesPerWeek = [
    1, 2, 3
  ];
  totalCharged = null;
  selectedTimes: number = 1;
  // we could have them as parameters
  availableCards = [
    "Amex Gold Business",
    "Amex Gold Personal",
    "Mastercard Bilt",
    "Chase Sapphire Reserve",
    "Chase Sapphire Preferred",
    "Citi Premier",
    "Citi Prestige"
  ];
  selectedAvailableCards: string;
  selectedPrice: string;
  formData = {
    userFirstName: "",
    userLastName: "",
    cardNumber: "",
    expirationMM: "",
    expirationYY: "",
    cvv: "",
  };
  bankFormData = {
    bankName: "",
    accountNumber: "",
    routingNumber: "",
  };

  userFormData = {
    username: "",
    password: "",
  };

  addressFormRules: GenericFormType[] = [
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.dropdown(
      "state",
      "Country",
      [Validators.required],
      [
        { value: null, label: "Please select country" },
        ...Countries.map((x) => ({ value: x.code, label: x.name })),
      ],
      false,
      null
    ),
    GenericFormBuilder.text(
      "postalCode",
      "Zip Code",
      [Validators.required],
      false,
      null
    ),
  ];
  newCard: boolean = true;

  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private notificationService: NotificationService,
    private toasterService: NotificationService,
  ) {}

  ngOnInit() {
    this.loggedInUser = this.authService.getCurrentUser();
    if (this.loggedInUser) {
      this.paymentOptions =
        this.loggedInUser.payment && this.loggedInUser.payment.sources
          ? this.loggedInUser.payment.sources.filter((x) => !x.hidden)
          : [];
      console.log("====================================");
      console.log(
        "PRUBEA",
        this.loggedInUser.firstName,
        this.loggedInUser.lastName
      );
      console.log("====================================");
      this.formData.userFirstName = this.loggedInUser.firstName;
      this.formData.userLastName = this.loggedInUser.lastName;
    }
  }

  selectCard(card: any) {
    if (!card) {
      this.newCard = true;
    } else {
      this.newCard = false;
    }
    this.selectedCard = card;
  }

  onTimesSelected(selectedTime: number) {
    let parts = this.selectedPrice.split('-');
    let numberToMultiply = parseInt(parts[1].trim());
    this.totalCharged = numberToMultiply * this.selectedTimes;
  }

  async login() {
    const username = this.userFormData.username;
    const password = this.userFormData.password;
    if (!this.userFormData.username || !this.userFormData.password) {
      this.notificationService.error(
        "Please check username or password empty field"
      );
      return;
    }
    try {
      const res = await this.authService.login(this.userFormData.username, this.userFormData.password);
      if (res) {
        this.notificationService.success(
          "successful login!"
        );
      }
      this.loggedInUser = res;
      window.location.reload();
    } catch (error) {
      this.notificationService.error(
        "failed to login! Please check the username and password"
      );
    }
    
  }

  async confirm() {
    try {
      let source = null;

      if (!this.selectedPrice) {
        this.notificationService.error(
          "Please select an amount weekly subscription"
        );
        return;
      }

      if (
        !this.bankFormData.accountNumber ||
        !this.bankFormData.bankName ||
        !this.bankFormData.routingNumber
      ) {
        this.notificationService.error(
          "Please check empty fields on bank account details"
        );
        return;
      }

      if (!this.loggedInUser && (!this.userFormData.password || !this.userFormData.username)) {
        this.notificationService.error(
          "Please check empty fields on user account details"
        );
        return;
      }

      if (!this.selectedAvailableCards) {
        this.notificationService.error(
          "Please check card brand empty field"
        );
        return;
      }

      if (!this.selectedCard) {
        const address = this.addressForm.toRaw();
        source = await this.stripeCard.createToken({
          name: `${this.formData.userFirstName} ${this.formData.userLastName}`,
          address_state: `${address.state}`,
          address_zip: `${address.postalCode}`,
        });
      } else {
        source = this.selectedCard;
      }

      if (!source) {
        this.notificationService.error("Please check your credit card data");
        return;
      }
      if (this.bankFormData.routingNumber.length != 9) {
        this.notificationService.error("ABA/Routing Number must be 9 digits");
        return;
      }

      const payload = {
        username: this.userFormData.username,
        password: this.userFormData.password,
        selectedPrice: this.selectedPrice,
        bankData: {
          bankAccountName: this.bankFormData.bankName,
          bankAccountNumber: this.bankFormData.accountNumber,
          routingNumber: this.bankFormData.routingNumber,
        },
        source,
        selectedCreditCardBrand: this.selectedAvailableCards,
        timerPerWeek: this.selectedTimes,
      };
      const res = await this.subscriptionService.createSubscription(payload);
      if (res) {
        this.toasterService.success('subscription created successfully');
      } else {
        this.toasterService.error('error creating subscription');
      }
    } catch (error) {
      console.log(error, "erro in the confirm subscription");
    }
  }
}
