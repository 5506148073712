import { Component, OnInit, AfterViewInit, ViewChild, Input } from "@angular/core";
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
    selector: 'app-image-view',
    templateUrl: './image.view.component.html',
})
export class ImageViewComponent implements OnInit, AfterViewInit {
    
  url: string;

  constructor(
    private bsModalRef: BsModalRef,
    private router: Router
  ) { }
  
  async ngOnInit() {}
    
  ngAfterViewInit(): void {
  }

  cancel() {
    this.bsModalRef.hide();
  }
    
}