import { GenericFormType } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { Validators } from '@angular/forms';
import { HourSlots } from '../data/hour.slots.data';

export class EventModel {
    id: string;
    ownerId: string;
    venueId: string;
    name: string;
    type: string;
    email?: string;
    phone?: string;
    notes?: string;
    maxParticipants?: number;
    minParticipants?: number;
    startDate: string;
    endDate: string;
    participants: string[];
    invitedParticipants?: string[];
    eventStatus: string;
    orders: string[];
    autoApproveOrders?: boolean;
    venueName?: string;
    table?: string;
}

export const EventCreateConstraints: GenericFormType[] = [
    GenericFormBuilder.text('name', 'Name', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('email', 'Email', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.phone('phone', 'Phone', [], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.number('minParticipants', 'Min Participants', [], false, null, 'col-md-6 inline-block', '1'),
    GenericFormBuilder.number('maxParticipants', 'Max Participants', [], false, null, 'col-md-6 inline-block', '1'),
    GenericFormBuilder.datepicker('startDate', 'Date', [Validators.required], false, null, 'col-md-6 inline-block', new Date()),
    GenericFormBuilder.dropdown('startTime', 'Time', [Validators.required], HourSlots.slice(0), false, null, null, 'col-md-6 inline-block'),
    GenericFormBuilder.textarea('notes', 'Notes', [], false, null, 'col-md-12 inline-block')
];

export const EventEditConstraints: GenericFormType[] = [
    GenericFormBuilder.text('name', 'Name', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.text('email', 'Email', [Validators.required], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.phone('phone', 'Phone', [], false, null, 'col-md-6 inline-block'),
    GenericFormBuilder.number('minParticipants', 'Min Participants', [], false, null, 'col-md-6 inline-block', '1'),
    GenericFormBuilder.number('maxParticipants', 'Max Participants', [], false, null, 'col-md-6 inline-block', '1'),
    GenericFormBuilder.datepicker('startDate', 'Date', [Validators.required], true, null, 'col-md-6 inline-block', new Date()),
    GenericFormBuilder.dropdown('startTime', 'Time', [Validators.required], HourSlots.slice(0), true, null, null, 'col-md-6 inline-block'),
    GenericFormBuilder.textarea('notes', 'Notes', [], false, null, 'col-md-12 inline-block')
];
