import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-get-the-app-component',
  templateUrl: './get-the-app.component.html',
  styleUrls: ['./get-the-app.component.scss'],
})
export class GetTheAppComponent implements OnInit {

  @Input() content: any;

  bsModalRef: BsModalRef;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  openGetTheAppPopup() {
    window.location.href = "https://www.cuculi.com/join-cuculi";
    // this.route.queryParamMap.pipe(take(1)).subscribe((paramsMap: any) => {
    //   const comeFromSocialMedia = paramsMap.get("socialmedia");
    //   this.router.navigate(["/subscribe-social-media"], {
    //     queryParams: {
    //       socialmedia: comeFromSocialMedia,
    //     },
    //   });
    // });
  }

  isMobile() {
    const userAgent = navigator.userAgent;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
      return true;
    }
    return false;
  }

}
