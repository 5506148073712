import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-layout-page-header',
  templateUrl: './layout.page.header.component.html'
})
export class LayoutPageHeaderComponent {

  @Input() image: string;
  @Input() title: string;
  @Input() icon: string;

  constructor() {
  }
}
