import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LaddaModule } from 'angular2-ladda';
import { GenericFormComponent } from './generic.form.component';
import { GenericInputRowComponent } from './generic.input.row.component';
import { BsDatepickerModule, TimepickerModule, TooltipModule } from 'ngx-bootstrap';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { SpinnerModule } from 'primeng/spinner';
import { MultiSelectModule } from 'primeng/multiselect';
import { JSONEditorModule } from 'ngx-jsoneditor';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    LaddaModule,
    BsDatepickerModule,
    AutoCompleteModule,
    DropdownModule,
    InputTextModule,
    CheckboxModule,
    SpinnerModule,
    JSONEditorModule,
    UiSwitchModule,
    NgxIntlTelInputModule,
    BsDatepickerModule,
    MultiSelectModule,
    TimepickerModule,
    TooltipModule.forRoot()
  ],
  declarations: [GenericInputRowComponent, GenericFormComponent],
  exports: [GenericInputRowComponent, GenericFormComponent]
})
export class GenericFormModule { }
