import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { ConfirmationDialogComponent } from './confirmation.dialog.component';

@Injectable()
export class ConfirmationDialogService {

    constructor(
        private confirmationDialogService: BsModalService
    ) { }

    public async popup(title: string, message: string): Promise<boolean> {
        const initialState = {
            title,
            message
        };
        const bsModalRef = this.confirmationDialogService.show(ConfirmationDialogComponent, { initialState });
        bsModalRef.setClass('modal-md');
        return new Promise<boolean>((resolve) => bsModalRef.content.result.subscribe((result) => resolve(result) ));
    }
}
