import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';
import { VenueModel } from '../models/venue.model';
import { BaseService } from '../common/base.service';
import { EventModel } from '../models/event.model';
import { WaiterModel } from '../models/waiter.model';
import { HappyHourModel } from '../models/happy-hour.model';

@Injectable()
export class VenueService extends BaseService<VenueModel> {

  constructor(
    apiService: ApiService,
    automapper: AutomapperService) {
    super(apiService, automapper);
  }

  protected getModelName(): string {
    return 'VenueModel';
  }
  protected getApiName(): string {
    return 'VenueApi';
  }
  protected getApiPrefix(): string {
    return 'venue';
  }

  async uploadPhoto(venueId: string, payload: any): Promise<any> {
    const url = `/venue/${venueId}/gallery`;
    let response;
    try {
      response = await this.apiService.post(url, payload, []);
    } catch (error) {
      throw error;
    }
    return response;
  }

  async create(payload): Promise<any> {
    const url = `/venue`;
    let response;
    try {
      response = await this.apiService.post(url, payload, [], true);
    } catch (error) {
      throw error;
    }
    return response;
  }

  async updateGallery(venueId: string, payload: any): Promise<any> {
    const url = `/venue/${venueId}/gallery`;
    let response;
    try {
      response = await this.apiService.put(url, payload, []);
    } catch (error) {
      throw error;
    }
    return response;
  }

  async getBySlug(slug: string): Promise<VenueModel> {
    const res = await this.search({ slug });
    if (res && res.length) {
      return res[0];
    }
    return null;
  }

  getAllUserVenuesByVenueIds(venueIds: string[]): Promise<VenueModel[]> {
    const query = venueIds.map(id => `ids=${id}`).join('&');
    return this.apiService.get(`/${this.getApiPrefix()}?${query}`, []);
  }

  getAllEventsByVenueIds(payload: string[]): Promise<EventModel[]> {
    return this.apiService.post(`/${this.getApiPrefix()}s/events`, payload, []);
  }

  getAllVenuesByUserId(id: string): Promise<VenueModel[]> {
    return this.apiService.get(`/user/${id}/venues`, []);
  }

  // tslint:disable-next-line:max-line-length
  async claimVenue(
    venue: VenueModel,
    accountHolderName: string,
    accountNumber: string,
    taxId: string,
    ssn: string,
    phone: string,
    accountRoutingNumber?: string,
    birthDay?: string,
    companyName?: string,
    website?: string,
    referredBy?: string,
    ): Promise<VenueModel> {

    const payload = {
      id: venue.id,
      accountHolderName,
      accountNumber,
      accountRoutingNumber,
      taxId,
      ssn,
      birthDay,
      companyName,
      website,
      phone,
      referredBy,
    };
    return this.apiService.post(`/${this.getApiPrefix()}/${venue.id}/claim`, payload, [])
      .then((res) => {
        return this.automapper.map<VenueModel>(this.getApiName(), this.getModelName(), res);
      });
  }

  getVenueHappyHours(venueId): Promise<HappyHourModel[]> {
    return this.apiService.get(`/venue/${venueId}/happy-hour`, [])
      .then((data: any[]) => {
        return this.automapper.mapArray<HappyHourModel>('HappyHourApi', 'HappyHourModel', data);
      });
  }

  getVenueTipsRule(venueId, numberOfGuests): Promise<any> {
    return this.apiService.get(`/venues/${venueId}/tips-rule?guests=${numberOfGuests}`, []);
  }

  async uploadIdentityDocument(payload: any): Promise<{ path: string }> {
    const url = `/file`;
    let response;
    try {
      response = await this.apiService.post(url, payload, []);
    } catch (error) {
      throw error;
    }
    console.log(response);
    return { path: response.path };
  }

  async getVenuesGoogleInfo(venueId): Promise<any> {
    return await this.apiService.get(`/venue/${venueId}/google-info`, []);
  }
}

export interface ITipsRule {
  venueId: string;
  required: boolean;
  noTipsAllowed: boolean;
  percentages: number[];
  minGuests?: number;
  maxGuests?: number;
}
