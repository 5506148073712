export const neighborhood = [
  "neighborhood-queens",
  "neighborhood-brooklyn",
  "neighborhood-manhattan",
  "neighborhood-new-jersey",
];

export const neighborhoodLongIsland = [
  "neighborhood-long-island",
];
