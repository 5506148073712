import { Component, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { GenericFormType, GenericFormControl, GenericFormComponent } from '../generic-form/generic.form.component';
import { VenueModel, VenueUpdateConstraints } from '../models/venue.model';
import { VenueService } from './venue.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LocationInputComponent } from '../location/location.input.component';
import { UserModel } from '../models/user.model';
import { AuthService } from '../common/auth.service';
import { UserRoles } from '../data/user.roles.data';
import { FileUploadComponent } from '../file.upload/file.upload.component';

@Component({
  selector: 'app-venue-edit',
  templateUrl: './venue.edit.component.html'
})
export class VenueEditComponent implements AfterViewInit {


  @ViewChild('genericForm') genericForm: GenericFormComponent;
  @ViewChild('locationInput') locationInput: LocationInputComponent;
  @ViewChild('fileUpload') fileUpload: FileUploadComponent;

  updateRules: GenericFormType[] = VenueUpdateConstraints;
  updateInProgress: boolean;
  location: any;
  latitude: number;
  longitude: number;
  venue: VenueModel;
  currentUser: UserModel;
  shouldUpdateGallery: boolean;

  constructor(
    private venueService: VenueService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cr: ChangeDetectorRef,
  ) { }

  async ngAfterViewInit() {
    this.updateInProgress = false;
    this.shouldUpdateGallery = false;
    this.currentUser = this.authService.getCurrentUser();
    const slug: string = this.route.snapshot.paramMap.get('slug');
    this.venue = await this.venueService.getBySlug(slug);
    this.latitude = this.venue.location.latitude;
    this.longitude = this.venue.location.longitude;
    this.location = this.venue.location;
    this.cr.detectChanges();
    this.genericForm.fromModel<VenueModel>('VenueModel', 'VenueEditForm', this.venue);
  }

 isAdminOrOwner() {
   return this.currentUser && (this.currentUser.role === UserRoles.ADMIN || this.currentUser.id === this.venue.ownerId)
 }

 removeImage(index: number) {
  this.venue.gallery.splice(index, 1);
  this.shouldUpdateGallery = true;
  return;
 }

  async update() {
    if (this.genericForm.isValid()) {
      this.updateInProgress = true;
      try {
        let venueData: VenueModel = this.genericForm.toModel<VenueModel>('VenueCreateForm', 'VenueModel', this.venue);
        venueData.location = this.locationInput.currentLocation;
        if (!venueData.gallery || !venueData.gallery.length) {
          venueData.mainImage = null;
        }
        venueData = await this.venueService.update(this.venue.id, venueData);
        if (this.shouldUpdateGallery) {
          await this.venueService.updateGallery(this.venue.id, this.venue.gallery);
        }
        if (this.fileUpload.fileUploaded && this.fileUpload.filesData && this.fileUpload.filesData.length) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.fileUpload.filesData.length; i++) {
            await this.venueService.uploadPhoto(this.venue.id, this.fileUpload.filesData[i]);
          }
        }
        this.router.navigateByUrl(`/venues/${this.venue.slug}`);
      } catch (err) {
        this.updateInProgress = false;
      }
    }
  }
}
