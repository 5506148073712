import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EnumService } from 'src/app/common/enum.service';
import { NotificationPopupComponent } from 'src/app/common/notification.popup.component';
import { NotificationService } from 'src/app/common/notification.service';
import { UserRoles } from 'src/app/data/user.roles.data';
import { GenericFormBuilder } from 'src/app/generic-form/generic.form.builder';
import { GenericFormComponent, GenericFormType } from 'src/app/generic-form/generic.form.component';
import { MenuModel } from 'src/app/models/menu.model';
import { UserCreateConstraints, UserModel } from 'src/app/models/user.model';
import { PaymentCreateComponent } from 'src/app/payment/payment.create.component';
import { UserService } from 'src/app/user/user.service';
import { AuthService } from '../../common/auth.service';
import { EventService } from '../event.service';
import { BsModalService } from 'ngx-bootstrap';
import { Platforms } from './ticket.enum';
import { PaymentService } from 'src/app/payment/payment.service';
import { neighborhood } from 'src/configs/neighborhood';

@Component({
  selector: "app-event-tickets",
  templateUrl: "./event.tickets.component.html",
  styleUrls: ["./event.tickets.component.scss"],
})
export class EventTicketsComponent implements OnInit, AfterViewInit {
  @ViewChild("accountRegisterForm") accountRegisterForm: GenericFormComponent;
  createRules: GenericFormType[] = UserCreateConstraints;

  @ViewChild("loginForm") loginForm: GenericFormComponent;
  loginFormRules: GenericFormType[] = [
    GenericFormBuilder.text(
      "email",
      "Email",
      [Validators.required],
      null,
      null,
      "col-md-12 inline-block claim-restaurant"
    ),
    GenericFormBuilder.password(
      "password",
      "Password",
      [Validators.required],
      null,
      "col-md-12 inline-block claim-restaurant"
    ),
  ];

  @ViewChild("contactForm") contactForm: GenericFormComponent;
  contactFormRules: GenericFormType[] = [
    GenericFormBuilder.text(
      "firstName",
      "First Name",
      [Validators.required],
      null,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
    GenericFormBuilder.text(
      "lastName",
      "Last Name",
      [Validators.required],
      null,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
    GenericFormBuilder.text(
      "email",
      "Email",
      [Validators.required],
      null,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
    // GenericFormBuilder.phone(
    //   "phone",
    //   "Phone",
    //   [Validators.required],
    //   false,
    //   null,
    //   "col-md-6 inline-block claim-restaurant"
    // ),
  ];

  @ViewChild("shippingForm") shippingForm: GenericFormComponent;
  shippingFormRules: GenericFormType[] = [
    GenericFormBuilder.text(
      "state",
      "State",
      [Validators.required],
      null,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
    GenericFormBuilder.text(
      "zipCode",
      "ZIP code",
      [Validators.required],
      null,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
    GenericFormBuilder.text(
      "address",
      "Address",
      [Validators.required],
      null,
      null,
      "col-md-12 inline-block claim-restaurant"
    ),
    GenericFormBuilder.text(
      "apartment",
      "Apartment",
      [Validators.required],
      null,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
    GenericFormBuilder.phone(
      "phone",
      "Phone",
      [Validators.required],
      null,
      null,
      "col-md-6 inline-block claim-restaurant"
    ),
  ];

  @ViewChild("ticketQuantityForm") ticketQuantityForm: GenericFormComponent;
  ticketQuantityRules: GenericFormType[] = [
    // tslint:disable-next-line:max-line-length
    GenericFormBuilder.number(
      "quantity",
      "How many tickets would you like to purchase",
      [Validators.required],
      null,
      null,
      "col-md-12 inline-block claim-restaurant",
      "1"
    ),
  ];

  @ViewChild("paymentCreate") paymentCreate: PaymentCreateComponent;

  event: any;
  ticket: any;
  currentUser: UserModel;
  venueNeighborhood: string;
  contactInformationCompleted: boolean;
  itemsSelectionCompleted: boolean;
  shippingInformationCompleted: boolean;
  paymentInformationCompleted: boolean;
  activeForm:
    | "QUANTITY"
    | "REGISTER"
    | "LOGIN"
    | "GUEST"
    | "DELIVERY_MENU"
    | "SHIPPING"
    | "PAYMENT";
  purchaseInProgress: boolean;
  shippingData: any[];
  cards: any[];
  selectedCard: string;
  isMobileScreen: boolean;
  hideTicketInformation: boolean;
  deliveryMenu: MenuModel;
  interestedInVirtualEvent: boolean;
  registerInProgress: boolean;
  useExistingPaymentMethod: boolean;
  ticketQuantitySelected: boolean;
  ticketQuantity: number;
  tickets: Array<{ index: number; itemIds: string[]; items: any[] }>;
  currentTicketMenu: number;
  ticketExpenses: { serviceComission: number, tax: number, serviceFee: number };

  constructor(
    private eventService: EventService,
    private paymentService: PaymentService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private enumService: EnumService,
    private toasterService: NotificationService,
    private modalService: BsModalService,
    private userService: UserService,
    private router: Router,
  ) {}

  async ngOnInit() {
    // const ticketExpenses = this.paymentService.getTicketExpensesConfig();
    this.ticketExpenses = {
      serviceComission: 0.03,
      tax: 0.08875,
      serviceFee: 0.3
    }
    const id: string = this.route.snapshot.paramMap.get('id');
    if (this.authService.isLoggedIn().value) {
      this.ticketExpenses =
        (await this.paymentService.getTicketExpensesConfig()) as {
          serviceComission: number;
          tax: number;
          serviceFee: number;
        };
      this.currentUser = this.authService.getCurrentUser();
      this.event = await this.eventService.getPublicById(id);
    } else {
      this.event = await this.eventService.getPublicById(id);
      this.ticketExpenses = {
        serviceComission: this.event.tickets.serviceComission || 0,
        tax: this.event.tickets.tax || 0,
        serviceFee: this.event.tickets.serviceFee || 0,
      };
    }
    if (this.currentUser) {
      this.checkIfUserAlreadyOwnsTicket();
      await this.getAllCards();
    }
    await this.loadDeliveryMenu();
    this.ticketQuantity = 1;
    this.currentTicketMenu = 1;
    this.loadTickets();
    this.checkIfJoinIsPossible();
    const tickets = await this.eventService.getEventTickets(this.event.eventId);
    if (!tickets || !tickets.length) {
      this.toasterService.error(
        "Missing ticket information, please try again later..."
      );
    } else {
      this.ticket = tickets[0];
    }
    this.getNeighborhood();
    this.contactInformationCompleted = false;
    this.shippingInformationCompleted = false;
    this.paymentInformationCompleted = false;
    this.setActiveForm();
    this.isMobileScreen = window.innerWidth < 769;
    this.hideTicketInformation = this.isMobileScreen;
    this.interestedInVirtualEvent = false;
    this.useExistingPaymentMethod = true;
  }

  private async loadDeliveryMenu() {
    this.deliveryMenu = await this.eventService.getDeliveryMenu(
      this.event.eventId
    );
    this.deliveryMenu.categories = this.deliveryMenu.categories.filter(
      (x) => !!x.items && !!x.items.length
    );
  }

  toggleTicketInformation() {
    this.hideTicketInformation = !this.hideTicketInformation;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.currentUser && this.contactForm) {
        this.contactForm.myForm
          .get("firstName")
          .setValue(this.currentUser.firstName);
        this.contactForm.myForm
          .get("lastName")
          .setValue(this.currentUser.lastName);
        this.contactForm.myForm.get("email").setValue(this.currentUser.email);
        this.contactForm.myForm.get("phone").setValue(this.currentUser.phone);
      }
      if (this.ticketQuantityForm) {
        this.ticketQuantityForm.myForm
          .get("quantity")
          .setValue(this.ticketQuantity || 1);
      }
    }, 1000);
  }

  toggleLoginRegister() {
    this.activeForm = this.activeForm === "REGISTER" ? "LOGIN" : "REGISTER";
  }

  openLogin() {
    this.activeForm = "LOGIN";
  }

  openRegister() {
    this.activeForm = "REGISTER";
  }

  isMobile() {
    const userAgent = navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        userAgent
      )
    ) {
      return true;
    }
    return false;
  }

  selectDeliveryItem(item: any, ticketIndex: number) {
    const ticket = this.tickets.find((x) => x.index === ticketIndex);
    if (!ticket) {
      return;
    }
    const index = ticket.itemIds.indexOf(item.id);
    if (index === -1) {
      ticket.itemIds.push(item.id);
    } else {
      ticket.itemIds.splice(index, 1);
    }
  }

  async resetAndLogout() {
    await this.authService.logout();
    this.currentUser = null;
    this.contactInformationCompleted = false;
    this.shippingInformationCompleted = false;
    this.paymentInformationCompleted = false;
    this.cards = [];
    this.setActiveForm();
  }

  async login() {
    if (!this.loginForm.isValid()) {
      return;
    }
    const data: { email: string; password: string } = this.loginForm.toObject();
    try {
      this.currentUser = await this.authService.login(
        data.email,
        data.password
      );
      this.contactInformationCompleted = true;
      this.checkIfUserAlreadyOwnsTicket();
      await this.getAllCards();
      this.setActiveForm();
    } catch (err) {
      console.error(err);
      this.toasterService.error('Login Failed', 'Please check your credentials and try again');
    }
  }

  scrollToTop = () => $("html, body").animate({ scrollTop: 0 }, "fast");

  async register() {
    this.registerInProgress = true;
    if (!this.accountRegisterForm.isValid()) {
      return;
    }
    const user: UserModel = this.accountRegisterForm.toModel<UserModel>(
      "UserCreateForm",
      "UserModel"
    );
    user.role = UserRoles.REGULAR;
    user.username = user.email;
    try {
      await this.userService.create(user);
      this.currentUser = await this.authService.login(
        user.email,
        user.password
      );
      this.contactInformationCompleted = true;
      this.cards = [];
      this.setActiveForm();
    } catch (error) {
      console.log(error, "this is error on register");
    } finally {
      this.registerInProgress = false;
    }
  }

  async continueAsGuest() {
    if (!this.contactForm.isValid()) {
      return;
    }
    const data = this.contactForm.toRaw();
    const existingParticipantEmail = this.event.participants.find(
      (x) => x.email === data.email
    );
    if (!!existingParticipantEmail) {
      // tslint:disable-next-line:max-line-length
      this.toasterService.warning(
        "Existing email",
        "User with this email is already a member of this Event, please change email or log in with current email"
      );
      return;
    }
    const user = await this.userService.createPotentialUser(
      data.firstName,
      data.lastName,
      data.email,
    ) as any;
    this.currentUser = this.authService.setCurrentUser(user);
    this.contactInformationCompleted = true;
    this.checkIfUserAlreadyOwnsTicket();
    await this.getAllCards();
    this.setActiveForm();
  }

  private checkIfUserAlreadyOwnsTicket() {
    if (!this.currentUser) {
      return false;
    }
    const existingParticipant = this.event.participants.find(
      (x) => x.id === this.currentUser.id
    );
    if (!!existingParticipant) {
      this.toasterService.warning(
        "Unable To Join Event",
        "You are already member of this Event, you can not purchase ticket again."
      );
      this.router.navigateByUrl(`/events/${this.event.eventId}`);
      return true;
    }
    return false;
  }

  toggleNotificationPopup() {
    const initialState = {
      title: "Thank you for your purchase!",
      text: this.event.shippingMandatory
        ? "The meal will be delivered within 2 hrs prior to the event. Please check your email for the zoom link."
        : this.event.isVirtual
        ? "Please check your email for the zoom link."
        : "Please check your email.",
      textTwo: this.event.shippingMandatory
        ? "Enjoy the CUCULI package and see you at the event❤️❤️"
        : "Enjoy the CUCULI app, and see you at the event❤️❤️",
      confirmText: "Ok",
      buttonCallback: () => {
        this.purchaseInProgress = false;
        this.router.navigateByUrl(`/events/${this.event.eventId}`);
      },
    };
    const bsModalRef = this.modalService.show(NotificationPopupComponent, {
      initialState,
    });
    bsModalRef.setClass("notification-popup-modal");
  }

  completeShipping() {
    if (!this.shippingForm.isValid()) {
      return;
    }
    this.shippingInformationCompleted = true;
    this.shippingData = []; // this.shippingForm.toRaw();

    for (const ticket of this.tickets) {
      const newData = this.shippingForm.toRaw();
      newData.phone = newData.phone.number;
      newData.interestedInVirtualEvent = !!this.interestedInVirtualEvent;
      newData.items = ticket.items;
      this.shippingData.push(newData);
    }
    this.setActiveForm();
  }

  completeDeliveryMenu(ticketIndex: number) {
    const ticket = this.tickets.find((x) => x.index === ticketIndex);
    if (!ticket) {
      return;
    }
    if (!ticket.itemIds || !ticket.itemIds.length) {
      this.toasterService.warning(
        "No Items Selected",
        "Please select at least one item from the menu."
      );
      return;
    }
    ticket.items = [];
    for (const category of this.deliveryMenu.categories) {
      for (const item of category.items) {
        if (ticket.itemIds.indexOf(item.id) !== -1) {
          ticket.items.push({
            id: item.id,
            name: item.name,
            category: item.category,
            type: item.type,
            species: item.species,
          });
        }
      }
    }
    if (this.currentTicketMenu < this.tickets.length) {
      this.currentTicketMenu++;
    } else {
      this.itemsSelectionCompleted = !this.tickets.find(
        (x) => !x.itemIds || !x.itemIds.length
      );
      this.setActiveForm();
    }
  }

  backToDeliveryMenu() {
    this.itemsSelectionCompleted = false;
    this.activeForm = "DELIVERY_MENU";
  }

  backToQuantity() {
    this.ticketQuantitySelected = false;
    this.activeForm = "QUANTITY";
  }

  backToPreviousItems() {
    this.currentTicketMenu--;
  }

  private isDeliveryItemsSelected(): boolean {
    if (!this.deliveryMenu.categories || !this.deliveryMenu.categories.length) {
      return true;
    }
    const ticketWithoutItemsSelected = !!this.tickets.find(
      (x) => !x.itemIds || !x.itemIds.length
    );
    return !ticketWithoutItemsSelected && this.itemsSelectionCompleted;
  }

  switchPaymentMethods() {
    this.useExistingPaymentMethod = !this.useExistingPaymentMethod;
    this.selectedCard = null;
  }

  renderSwitchPaymentMethodsButtonLabel() {
    return this.useExistingPaymentMethod
      ? "Use new Credit Card"
      : "Use exsiting Credit Card";
  }

  selectCard(card: any) {
    if (this.selectedCard !== card.sourceId) {
      this.selectedCard = card.sourceId;
    } else {
      this.selectedCard = null;
    }
  }

  async quantitySelected() {
    const data = this.ticketQuantityForm.toObject();
    if (!data.quantity || parseInt(data.quantity) < 1) {
      this.toasterService.warning("Minimum ticket quantity is 1");
      return;
    }
    this.ticketQuantity = Number(data.quantity);

    this.event = await this.eventService.getPublicById(this.event.eventId);
    if (!this.checkIfJoinIsPossible()) {
      return;
    }

    this.ticketQuantitySelected = true;

    this.loadTickets();

    this.currentTicketMenu = 1;
    this.setActiveForm();
  }

  private loadTickets() {
    this.tickets = [];
    for (let i = 0; i < this.ticketQuantity; i++) {
      const ticket = { index: i + 1, itemIds: [], items: [] };
      this.tickets.push(ticket);
    }
    for (const category of this.deliveryMenu.categories) {
      if (category.items.length === 1) {
        this.tickets.forEach((x) => {
          x.itemIds.push(category.items[0].id);
        });
      }
    }
  }

  async purchaseTicketAndJoin() {
    if (!this.selectedCard && !this.paymentCreate.isValid()) {
      return;
    }
    if (!this.currentUser) {
      this.toasterService.warning(
        "Invalid data",
        "User data is missing, please reload the page and start from the start."
      );
      return;
    }
    this.purchaseInProgress = true;
    this.event = await this.eventService.getPublicById(this.event.eventId);
    this.checkIfJoinIsPossible();
    if (!this.selectedCard) {
      const stripeSource = await this.paymentCreate.getPaymentSource();
      if (!stripeSource) {
        this.purchaseInProgress = false;
        return null;
      }
      await this.userService.addNewCreditCard(
        this.currentUser.id,
        stripeSource
      );
      this.selectedCard = stripeSource.id; // stripeSource.card.id;
    }
    try {
      if (this.currentUser.role === UserRoles.GUEST || this.currentUser.role === UserRoles.POTENTIAL) {
        await this.eventService.purchaseTicket(
          this.event.eventId,
          this.ticket.id,
          this.selectedCard,
          this.shippingData,
          this.ticketQuantity,
          Platforms.WEB,
          true,
        );
      } else {
        await this.eventService.purchaseTicket(
          this.event.eventId,
          this.ticket.id,
          this.selectedCard,
          this.shippingData,
          this.ticketQuantity,
          Platforms.WEB
        );
      }
      if (this.currentUser.role === UserRoles.GUEST || this.currentUser.role === UserRoles.POTENTIAL) {
        await this.authService.logout();
      }
      this.toggleNotificationPopup();
      this.purchaseInProgress = false;
      this.router.navigateByUrl(`/events/${this.event.eventId}`);
    } catch (err) {
      this.toasterService.warning(
        "Ticket Purchase Failed",
        "Something went wrong, please try again later"
      );
      this.purchaseInProgress = false;
    }
  }

  private async getNeighborhood() {
    // tslint:disable-next-line:max-line-length
    const neighborhoods = await this.enumService.getByTypes(neighborhood);
    if (!neighborhoods || !this.event.venue.neighborhood) {
      return (this.venueNeighborhood = "New York");
    }
    const element = neighborhoods.find(
      (n) => n.key === this.event.venue.neighborhood
    );
    this.venueNeighborhood =
      element && element.value ? element.value : this.event.venue.neighborhood;
  }

  private setActiveForm() {
    if (!this.ticketQuantitySelected) {
      this.activeForm = "QUANTITY";
      this.scrollToTop();
      return;
    }

    if (!this.contactInformationCompleted) {
      if (
        this.event.registrationMandatory &&
        (!this.currentUser || this.currentUser.role === UserRoles.GUEST || this.currentUser.role === UserRoles.POTENTIAL)
      ) {
        this.activeForm = "GUEST";
        this.scrollToTop();
        return;
      }
      this.contactInformationCompleted = true;
    }

    if (!this.shippingInformationCompleted && this.event.shippingMandatory) {
      if (!this.isDeliveryItemsSelected()) {
        this.activeForm = "DELIVERY_MENU";
        this.scrollToTop();
        return;
      }
      this.activeForm = "SHIPPING";
      this.scrollToTop();
      return;
    }

    if (!this.paymentInformationCompleted) {
      this.activeForm = "PAYMENT";
      this.scrollToTop();
      return;
    }
  }

  private async getAllCards() {
    this.cards = [];
    if (this.currentUser) {
      this.currentUser = await this.userService.getById(this.currentUser.id);
      if (this.currentUser.payment && this.currentUser.payment.sources) {
        this.currentUser.payment.sources.forEach((source) => {
          const cc = { ...source.card };
          cc.sourceId = source.id;
          this.cards.push(cc);
        });
      }
    }
  }

  private checkIfJoinIsPossible() {
    if (!this.event.tickets) {
      this.toasterService.warning(
        "Invalid Data",
        "Unable to purchase a ticket and join the Event, missing ticket information"
      );
      this.router.navigateByUrl(`/events/${this.event.eventId}`);
      return false;
    }

    if (this.event.tickets.isSoldOut) {
      this.toasterService.warning(
        "Tickets Sold Out",
        "Unable to purchase a ticket and join the Event, all tickets has been sold out."
      );
      this.router.navigateByUrl(`/events/${this.event.eventId}`);
      return false;
    }

    if (this.event.tickets.available < this.ticketQuantity) {
      // tslint:disable-next-line:max-line-length
      this.toasterService.warning(
        "Tickets Quantity",
        `Unable to purchase ${this.ticketQuantity} ticket(s), there are ${this.event.tickets.available} ticket(s) available for this event.`
      );
      return false;
    }

    if (this.event.participants.length >= this.event.maxParticipants) {
      this.toasterService.warning(
        "Event Full",
        "Unable to purchase a ticket and join the Event, all participants already joined."
      );
      this.router.navigateByUrl(`/events/${this.event.eventId}`);
      return false;
    }

    return true;
  }
}
