import { Component, OnInit } from '@angular/core';
import { ApiService } from '../common/api.service';
import { NotificationService } from '../common/notification.service';

@Component({
  selector: 'app-layout-footer',
  templateUrl: './layout.footer.component.html'
})
export class LayoutFooterComponent implements OnInit {
  name: string;
  email: string;
  message: string;
  constructor(
    private apiService: ApiService,
    private notificationService: NotificationService,
  ) {

  }

  ngOnInit() {
  }

  async sendContactForm() {
    const contactData = {
      from: this.email,
      name: this.name,
      message: this.message,
    };
    try {
      const mailSent = await this.apiService.post('/mail/contact-form', contactData, []);
      if (mailSent) {
        this.notificationService.success('Success!', 'Your message was successfully sent.');
      }
    } catch (error) {
      console.log(error);
    }
  }
}
