import { IMapperRegistry } from './mapper.registry';
import { AutomapperService } from '../common/automapper.service';
import { Injectable } from '@angular/core';

@Injectable()
export class EnumMapper implements IMapperRegistry {

    constructor(private automapper: AutomapperService) {
    }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('Enum', 'Lookup')
                .forMember('label', (opts) => opts.mapFrom('value'))
                .forMember('value', (opts) => opts.mapFrom('key'));
        });
    }
}
