import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PageModel } from '../models/page.model';

@Injectable()
export class PagesService {

  constructor(
    private apiService: ApiService) { }

  getAll(): Promise<PageModel[]> {
    return this.apiService.get('/page', [])
      .then((pages: any[]) => {
        return pages;
      });
  }

  getByName(name: string): Promise<PageModel> {
    return this.apiService.get('/page', [])
      .then((pages: any[]) => {
        return pages.find((p) => {
          return p.name.toLowerCase() === name.toLowerCase();
        });
      });
  }
}
