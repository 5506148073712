import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-happy-hours',
  templateUrl: './happy.hours.component.html',
  styleUrls: ['./happy.hours.component.scss']
})
export class HappyHoursComponent implements OnInit {

  @Input() happyHours: any;
  @Input() neighborhoods: any;

  slideConfig: any;

  async ngOnInit() {

    this.slideConfig = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
  }

  getAddress(happyHour: any): string {
    const neighborhood = this.neighborhoods.find(n => n.key === happyHour.neighborhood);
    return neighborhood ? neighborhood.value : 'New York';
  }

  handleDistance = (distance: any): string => {
    if (distance) {
      return `${(distance / 1609).toFixed(1)} mi`;
    }
    return '';
  }
}
