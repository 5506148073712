import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-venues',
    templateUrl: './venues.component.html',
    styleUrls: ['./venues.component.scss']
})
export class VenuesComponent implements OnInit {

  @Input() venues: any;
  @Input() neighborhoods: any;
  @Input() loadInProgress: BehaviorSubject<boolean>;
  showResult: boolean;

  slideConfig: any;

  async ngOnInit() {

    this.loadInProgress.subscribe((x) => setTimeout(() => { this.showResult = !x }, 3000));

    this.slideConfig = {
      infinite: false,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 450,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
  }

  getImage(venue: any) {
    return venue.mainImage ? venue.mainImage : './../../assets/images/banner.jpg';
  }

  getAddress(venue: any): string {
    const neighborhood = this.neighborhoods.find(n => n.key === venue.neighborhood);
    return neighborhood ? neighborhood.value : 'New York';
  }

  handleDistance = (distance: any): string => {
    if (distance) {
      return `- ${(distance / 1609).toFixed(1)} mi`;
    }
    return '';
  }
}
