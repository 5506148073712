export interface INotificationConfigType {
  type: NotificationConfigTypes;
  sms?: boolean;
  email?: boolean;
  push?: boolean;
}

export enum NotificationConfigTypes {
  EVENT_ANNOUNCEMENT = 'event-announcement',
  EVENT_TABLE = "event-table",
  BOOKING = "booking",
  COMMUNICATION = "communication",
  GENERAL = "general",
}
