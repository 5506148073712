import { Component, OnInit, Input } from '@angular/core';
import { EventModel } from '../models/event.model';
import * as moment from 'moment';
import { Statuses } from '../data/statuses.data';

@Component({
  selector: 'app-event-list',
  templateUrl: './event.list.component.html'
})
export class EventListComponent implements OnInit {

  @Input() events: EventModel[];
  @Input() actions: (e: EventModel) => any[];
  @Input() forVenue: boolean;
  statuses = Statuses;

  private eventActions = {};

  constructor() { }

  async ngOnInit() {
  }

  getEventPrefix(event: EventModel) {
    if (moment(event.startDate).isBefore(moment({ hour: 18, minute: 0 }))) {
      return 'Lunch';
    } else {
      return 'Dinner';
    }
  }

  getEventActions(event: EventModel) {
    if (this.eventActions[event.id]) {
      return this.eventActions[event.id];
    }
    const actions = this.actions(event);
    this.eventActions[event.id] = actions;
    return this.eventActions[event.id];
  }
}
