import { Injectable } from '@angular/core';
import { ApiService } from '../common/api.service';
import { AutomapperService } from '../common/automapper.service';
import { WaiterModel } from '../models/waiter.model';

@Injectable()
export class WaiterService {

    constructor(
        private apiService: ApiService,
        private automapper: AutomapperService
    ) { }

    async create(venueId: string, data: any): Promise<WaiterModel> {
        const payload = this.automapper.map(this.getModelName(), this.getApiName(), data);
        return this.apiService.post(`/venue/${venueId}/waiter`, payload, [])
            .then((res) => {
                return this.automapper.map<WaiterModel>(this.getApiName(), this.getModelName(), res);
            });
    }

    async getAll(venueId: string): Promise<WaiterModel[]> {
        return this.apiService.get(`/venue/${venueId}/waiter`, [])
            .then((res) => {
                return this.automapper.mapArray<WaiterModel>(this.getApiName(), this.getModelName(), res);
            });
    }

    async delete(id: string): Promise<boolean> {
        return this.apiService.delete(`/waiter/${id}`, [])
            .then((res) => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    protected getModelName(): string {
        return 'WaiterModel';
    }

    protected getApiName(): string {
        return 'WaiterApi';
    }
}
