import { Component, OnInit } from '@angular/core';
import { UserModel } from '../models/user.model';
import { AuthService } from '../common/auth.service';
import { SearchService, Filters } from './search.service';
import { EnumService } from '../common/enum.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { neighborhood } from 'src/configs/neighborhood';
declare const navigator: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  availableTypes: any[] = [
    { name: 'tables', label: 'Public Tables' },
    { name: 'venues', label: 'Bars and Retsaurants' },
    { name: 'happyHour', label: 'Happy Hours' }
  ];
  selectedType: string;

  user: UserModel;
  offer: any;
  events: any;
  pastEvents: any;
  groupedData: any;
  venues: any;
  happyHour: any;
  filters: Filters;
  neighborhoods: any;
  features: any;
  cuisine: any;
  filtersVisible: boolean;
  mobileView: boolean;
  searchInProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private searchJob;

  constructor(
    private authService: AuthService,
    private activeRoute: ActivatedRoute,
    private searchService: SearchService,
    private enumService: EnumService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  async ngOnInit() {
    // subscribe on url param change
    this.route.params.subscribe(params => {
      this.init();
    });

    this.searchService.getFiltersObj()
      .subscribe((v: any) => {
        this.filters = v;
      });

    this.mobileView = window.innerWidth < 768;
    this.filtersVisible = window.innerWidth > 767;
    this.searchInProgress.next(false);

  }

  async init() {
    this.selectedType = this.activeRoute.snapshot.paramMap.get('type') || 'venues';
    if (!this.filters) {
      this.filters = new Filters();
    }
    this.filters.type = this.selectedType;
    this.filters.filters = [];
    this.searchService.updateFilters(this.filters);
    this.applyFilters(this.filters);
    this.user = this.authService.getCurrentUser();
    // tslint:disable-next-line:max-line-length
    this.neighborhoods = await this.enumService.getByTypes(neighborhood);
    this.features = await this.enumService.getByTypes(['features-general', 'features-food', 'features-good-for', 'features-parking']);
  }

  async clear() {
    this.applyFilters({ type: this.filters.type, filters: [] });
    this.searchService.updateFilters({ type: this.filters.type, filters: [] });
  }

  toggleFilters() {
    this.filtersVisible = !this.filtersVisible;
  }

  onTypeSelected(type) {
    if (this.selectedType === type) {
      return;
    }
    this.selectedType = type;
    this.router.navigateByUrl(`search/${type}`);
  }

  async applyFilters(filters) {
    this.searchInProgress.next(true);
    switch (filters.type) {
      case 'tables':
        this.venues = null;
        this.happyHour = null;
        this.events = this.searchService.searchByType(filters);
        break;
      case 'tickets':
        this.venues = null;
        this.happyHour = null;
        filters.filters.push({ type: 'boolean', name: 'entranceFee', selected: true });
        this.events = await this.searchService.searchTables(filters);
        filters.filters[0].date = { from: moment('2023-01-01T00:00:00.000Z'), to: moment() };
        filters.filters[0].eventStatuses = ['pending', 'approved', 'active', 'completed', 'paid'];
        filters.filters[0].sort = { startDate: -1 };
        const pastEvents = await this.searchService.searchTables(filters);
        this.pastEvents = pastEvents.filter(pe => moment().isAfter(moment(pe.endDate)) || ['completed', 'paid'].includes(pe.eventStatus));
        this.sortPastEvents();
        this.groupedData = this.categorizeTablesByDate(this.pastEvents);
        break;
      case 'venues':
        this.events = null;
        this.happyHour = null;
        this.searchService.searchByType(filters)
          .then((res) => {
            this.venues = res;
            this.searchInProgress.next(false);
          });
        break;
      case 'happyHour':
        this.events = null;
        this.happyHour = null;
        this.happyHour = this.searchService.searchByType(filters);
        break;
    }
  }

  categorizeTablesByDate(data: any[]) {
    const nData = [];

    data.forEach((item) => {
      const startDate = moment(item.startDate);
      const monthYear = this.formatMonthYear(startDate);

      let keyExists = nData.find(ele => ele.key === monthYear);
      if (keyExists) {
        keyExists.items.push(item);
      } else {
        nData.push({
          key: monthYear,
          items: [item]
        });
      }
    });

    return nData;
  }

  formatMonthYear(startDate: moment.Moment): string {
    return startDate.format('MMMM YYYY');
  }

  sortPastEvents() {
    this.pastEvents.sort((a, b) => {
      if (a.startDate > b.startDate) { return -1; }
      if (a.startDate < b.startDate) { return 1; }
      return 0;
    });
  }

  async doSearchByKeyword(searchKeyword: string) {
    if (!!this.searchJob) {
      clearTimeout(this.searchJob);
    }

    this.searchJob = setTimeout(() => {
      if (this.selectedType === 'venues') {
        this.getAndSetVenues(searchKeyword);
      } else if (this.selectedType === 'tickets') {
        this.getAndSetTickets(searchKeyword);
      }
    }, 200);
  }

  getAndSetVenues = (searchKeyword: string) => {
    if (!searchKeyword) {
      console.log(this.filters);
      this.applyFilters(this.filters);
    }
    if (searchKeyword) {
      this.searchInProgress.next(true);
      this.searchService.getVenuesByName(searchKeyword)
        .then((res) => {
          this.venues = res;
          this.searchInProgress.next(false);
        });
    }
  }


  getAndSetTickets = (searchKeyword: string) => {
    if (!searchKeyword) {
      this.applyFilters(this.filters);
    }
    if (searchKeyword) {
      this.searchInProgress.next(true);
      this.searchService.searchTables(this.filters, searchKeyword)
        .then((res) => {
          this.events = res;
          this.searchInProgress.next(false);
        });
    }
  }
}
