import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {GiftCard} from '../models/gift-card.model'
import { CarouselModule } from "ngx-bootstrap";
@Component({
  selector: "app-soul-campaign",
  templateUrl: "./soul-campaign.component.html",
  styleUrls: ["./soul-campaign.component.scss"],
  providers: [CarouselModule]
})
export class SoulCampaignComponent implements OnInit {
  routeImg = "/assets/images/";
  giftCards: GiftCard[] = [
    {
      price: 25,
      value: 27.5,
      link: "25-gift-card",
      title: "$25 Gift Card",
      img: "/assets/images/Help_Banner_25.png",
    },  
    {
      price: 50,
      value: 55,
      link: "50-gift-card",
      title: "$50 Gift Card",
      img: "/assets/images/Help_Banner_50.png"
    },
    {
      price: 100,
      value: 110,
      link: "100-gift-card",
      title: "$100 Gift Card",
      img: "/assets/images/Help_Banner_100.png"
    },
    {
      price: 200,
      value: 2200,
      link: "200-gift-card",
      title: "$200 Gift Card",
      img: "/assets/images/Help_Banner_200.png"
    }
  ];
  currentIndex: number = 0;
  visibleSlides: number = 3;

  constructor() {}

  ngOnInit() {}
  

  ngAfterViewInit(): void {
    this.showSlide(this.currentIndex);
  }

  showSlide(index: number): void {
    const carouselInner = document.querySelector('.cards-carousel-inner') as HTMLElement
    const totalSlides = this.giftCards.length;

    this.currentIndex = (index + totalSlides) % totalSlides;

    const offset = -this.currentIndex * (100 / this.visibleSlides);
    carouselInner.style.transform = `translateX(${offset}%)`;
  }

  nextSlide(): void {
    const carouselInner = document.querySelector('.cards-carousel-inner') as HTMLElement
    carouselInner.style.transition = 'transform 0.5s ease';
    this.showSlide(this.currentIndex + 1);
    setTimeout(() => {
      const firstSlide = carouselInner.querySelector('.cards-carousel-item') as HTMLElement;
      carouselInner.appendChild(firstSlide);
      carouselInner.style.transition = 'none';
      this.currentIndex = (this.currentIndex - 1 + this.giftCards.length) % this.giftCards.length;
      this.showSlide(this.currentIndex);
    }, 500);
  }

  prevSlide(): void {
    const carouselInner = document.querySelector('.cards-carousel-inner') as HTMLElement
    carouselInner.style.transition = 'none';
    const slides = carouselInner.querySelectorAll('.cards-carousel-item');
    const lastSlide = slides[slides.length - 1];
    carouselInner.insertBefore(lastSlide, slides[0]);
    this.currentIndex = (this.currentIndex + 1) % slides.length;
    this.showSlide(this.currentIndex);

    setTimeout(() => {
      carouselInner.style.transition = 'transform 0.5s ease';
      this.showSlide(this.currentIndex - 1);
    }, 0);
  }

}
