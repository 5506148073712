import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventModel } from 'src/app/models/event.model';
import { EventService } from '../event.service';
import { AuthService } from 'src/app/common/auth.service';
import { UserModel } from 'src/app/models/user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/user/user.service';
import { NotificationService } from 'src/app/common/notification.service';

@Component({
  selector: 'app-rsvp',
  templateUrl: './rsvp.component.html',
  styleUrls: ['./rsvp.component.scss']
})
export class RsvpComponent implements OnInit {

  event:EventModel = null;
  currentUser: UserModel = null;
  myForm: FormGroup;
  isMobile: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventService: EventService,
    private authService: AuthService,
    private fb: FormBuilder,
    private userService: UserService,
    private notificationService: NotificationService,
  ) { 
    this.myForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth < 768;
    this.route.params.subscribe(params => {
      this.init();
    });
  }

  private async init() {
    const id: string = this.route.snapshot.paramMap.get("eventId");
    this.event = await this.eventService.getPublicById(id);
  }

  async onSubmit() {
    if (this.myForm.valid) {
      const id: string = this.route.snapshot.paramMap.get("eventId");
      const firstName = this.myForm.get('firstName').value;
      const lastName = this.myForm.get('lastName').value;
      const email = this.myForm.get('email').value;
      if (!firstName || !lastName || !email) {
        this.notificationService.error('ERROR', 'All fields are required');
        return;
      }
      const user = await this.userService.createPotentialUser(
        firstName,
        lastName,
        email,
      ) as any;
      if (!user) {
        this.notificationService.error('ERROR', 'An error occurred processing the user');
        return;
      }
      this.currentUser = this.authService.setCurrentUser(user);
      const res = await this.eventService.JoinPotentialUSer(id, email);
      if (!res) {
        this.notificationService.error('ERROR', 'An error occurred processing the event');
        return;
      }
      this.notificationService.success('Successfully RSVPed.', 'Download the app to see who else is joining and to get updated information of the Table. Make sure you use the SAME EMAIL ADDRESS to sign up 👍');
      setTimeout(() => {
        this.router.navigate(["/send-download-app"]);
      }, 1000);
    }
  }

}
