import { Lookup } from '../generic-form/generic.form.component';

export const WeekDays: Lookup[] = [
    { label: 'Monday', value: 'monday', index: 0 },
    { label: 'Tuesday', value: 'tuesday', index: 1 },
    { label: 'Wednesday', value: 'wednesday', index: 2 },
    { label: 'Thursday', value: 'thursday', index: 3 },
    { label: 'Friday', value: 'friday', index: 4 },
    { label: 'Saturday', value: 'saturday', index: 5 },
    { label: 'Sunday', value: 'sunday', index: 6 }
];

export const getDaysFromRange = (startDay, endDay) => {
    const res: Lookup[] = [];

    const sd = WeekDays.find((d) => d.value === startDay);
    const ed = WeekDays.find((d) => d.value === endDay);

    if (sd && !ed) {
        res.push(sd);
    }
    if (!sd && ed) {
        res.push(ed);
    }
    if (sd && ed) {
        res.push(sd);
        if (sd.index > ed.index) {
            WeekDays.forEach((day: Lookup) => {
                if (day.index > sd.index) {
                    res.push(day);
                }
            });
            WeekDays.forEach((day: Lookup) => {
                if (day.index < ed.index) {
                    res.push(day);
                }
            });
            res.push(ed);
        }
        if (sd.index < ed.index) {
            WeekDays.forEach((day: Lookup) => {
                if (day.index > sd.index && day.index < ed.index) {
                    res.push(day);
                }
            });
            res.push(ed);
        }
    }
    return res;
};
