import { IMapperRegistry } from './mapper.registry';
import { Injectable } from '@angular/core';
import { AutomapperService } from '../common/automapper.service';

@Injectable()
export class MenuMapper implements IMapperRegistry {
    constructor(private automapper: AutomapperService) { }

    register() {
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuApi', 'MenuModel')
                .forMember('type', (opts) => opts.mapFrom('type'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('categories', (opts) => opts.sourceObject.categories.sort((x, y) => {
                    return x.position - y.position;
                }).map((c) => {
                    return {
                        name: c.name,
                        description: c.description ? c.description : null,
                        label: c.label,
                        position: c.position,
                        items: this.automapper.mapArray('MenuItemApi', 'MenuItemModel', c.items),
                    };
                }))
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuModel', 'MenuApi')
                .forMember('venueId', (opts) => opts.mapFrom('venueId'))
                .forMember('type', (opts) => opts.mapFrom('type'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('categories', (opts) => opts.sourceObject.categories.map((x) => {
                    return {
                        name: x.name,
                        description: x.description ? x.description : null,
                        position: x.position,
                        items: x.items.map((y) => y.id)
                    };
                }))
                .ignoreAllNonExisting();
        });
        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuModel', 'MenuCategoryEditForm')
                .forMember('venueId', (opts) => opts.mapFrom('venueId'))
                .forMember('type', (opts) => opts.mapFrom('type'))
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('categories', (opts) => opts.sourceObject.categories.map((x) => {
                    return {
                        name: x.name,
                        position: x.position,
                        items: x.items.map((y) => y.id)
                    };
                }))
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuCategoryEditForm', 'MenuModel')
                .forMember('name', (opts) => opts.mapFrom('name'))
                .forMember('priority', (opts) => opts.mapFrom('priority'))
                .forMember('disabled', (opts) => opts.mapFrom('disabled'))
                .ignoreAllNonExisting();
        });

        this.automapper.register((mapperCfg) => {
            mapperCfg
                .createMap('MenuModel', 'MenuCategoryCopyForm')
                .forMember('name', (opts) => opts.mapFrom('name'))
                .ignoreAllNonExisting();
        });
    }
}
