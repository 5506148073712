import { GenericFormType, GenericFormControl } from '../generic-form/generic.form.component';
import { GenericFormBuilder } from '../generic-form/generic.form.builder';
import { WorkingDayModel } from './working.hours.model';
import { Validators } from '@angular/forms';
import { HourSlots } from '../data/hour.slots.data';

export class MenuItemModel {
  id: string;
  name: string;
  images: string[];
  type: string;
  workingHours?: WorkingDayModel[];
  description: string;
  options: MenuItemOptionModel[];
  addonsCategories?: MenuItemAddonsCategory[];
  available: boolean;
  category?: string;
  species?: string;
  marketPrice?: boolean;
  alcohol?: boolean;
  createdAt?: string;
  status?: number;
  avgRating?: any;
  workingHoursAvailable?: boolean;
  minAddonQuantity?: number;
  maxAddonQuantity?: number;
}

export class MenuItemOptionModel {
  id: string;
  name?: string;
  price: PriceModel;
  quantity: number;
  specialInstructions?: string;
  optionAddons?: MenuItemAddonnModel[];
}

export class PriceModel {
  taxIncluded?: boolean;
  tipsIncluded?: boolean;
  taxTotal?: number;
  tipsTotal?: number;
  value?: number;
  total?: number;
}

export class MenuItemAddonnModel {
  id?: string;
  addonName: string;
  addonTotal?: number;
  includeAddon?: boolean;
  quantity?: number;
}

export class MenuItemAddonsCategory {
  addonsLabel: string;
  addons: MenuItemAddonnModel[];
}

export const MenuItemOptionConstraints: GenericFormControl[] = [
  GenericFormBuilder.text('name', 'Portion', []),
  GenericFormBuilder.dropdown('type', 'Type', [], [
    { label: "Glass", value: "glass" },
    { label: "Bottle", value: "bottle" },
    { label: "Cup", value: "cup" }
  ], false, null, (form) => form.species === "drink", 'inline', true),
  GenericFormBuilder.number('total', 'Price', [Validators.required], false, null, '', '0.01'),
];

export const MenuItemAddonConstraints: GenericFormControl[] = [
  GenericFormBuilder.text('addonName', 'Addon', []),
  GenericFormBuilder.number('addonTotal', 'Price', [], false, null, '', '0.01'),
  GenericFormBuilder.text('id', 'ID', [], true, () => false),
];

export const MenuItemAddonsCategoryConstraints: GenericFormControl[] = [
  GenericFormBuilder.text('addonsLabel', 'Addons Category Title', null, false, null, null, '', 'Add-ons Category'),
  GenericFormBuilder.array('addons', '', [
    GenericFormBuilder.group('menuItemAddon', null, MenuItemAddonConstraints, null, 'inline')
  ], null, true),
];

export const TimeFrameConstraints: GenericFormControl[] = [
  GenericFormBuilder.dropdown('day', 'Day', [], [
    { label: 'Select day', value: null },
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' }
  ]),
  GenericFormBuilder.dropdown('start', 'From', [], HourSlots.slice(0)),
  GenericFormBuilder.dropdown('end', 'To', [], HourSlots.slice(0))
];

export const MenuItemCreateConstraints: GenericFormType[] = [
  GenericFormBuilder.dropdown('type', 'Type', [Validators.required], [], false),
  GenericFormBuilder.text('name', 'Name', [Validators.required], false, null),
  GenericFormBuilder.switch('marketPrice', 'Market Price', null),
  GenericFormBuilder.switch('alcohol', 'Alcohol', null),
  GenericFormBuilder.dropdown('species', 'Food/Drink/Bottle', [], [
    { label: 'Food', value: 'food' },
    { label: 'Drink', value: 'drink' },
    { label: 'Bottle', value: 'bottle' }], false, null),
  GenericFormBuilder.text('optionsLabel', 'Portions Label', null, false, null, 'inline', '', 'Select Portion (default)'),
  GenericFormBuilder.array('options', 'Portions', [
    GenericFormBuilder.group('menuItemOption', null, MenuItemOptionConstraints, null, 'inline')
  ], null, true),
  GenericFormBuilder.switch('includeAddons', 'Include Addons', null),
  // tslint:disable-next-line:max-line-length
  GenericFormBuilder.array('addonsCategories', 'Addons Options', [
    GenericFormBuilder.group('addonsCategory', null, MenuItemAddonsCategoryConstraints, null, 'row')
  ], (field) => field.includeAddons === true, true, 'block'),
  GenericFormBuilder.number('minAddonQuantity', 'Min addon quantity per menu item', [], false, (field) => field.includeAddons === true, 'inline', '1'),
  GenericFormBuilder.number('maxAddonQuantity', 'Max addon quantity per menu item', [], false, (field) => field.includeAddons === true, 'inline', '1'),
  GenericFormBuilder.array('workingHours', 'Happy Hours', [
    GenericFormBuilder.group('happyHourTimeFrames', null, TimeFrameConstraints, null, 'inline')
  ], (field) => field.type === 'happyHour', false),
  GenericFormBuilder.textarea('description', 'Description', []),
];

export const MenuItemUpdateConstraints: GenericFormType[] = [
  GenericFormBuilder.dropdown('type', 'Type', [Validators.required], [], false),
  GenericFormBuilder.text('name', 'Name', [Validators.required]),
  GenericFormBuilder.switch('marketPrice', 'Market Price', null),
  GenericFormBuilder.switch('alcohol', 'Alcohol', null),
  GenericFormBuilder.dropdown('species', 'Food/Drink/Bottle', [], [
    { label: 'Food', value: 'food' },
    { label: 'Drink', value: 'drink' },
    { label: 'Bottle', value: 'bottle' }], false, null),
  GenericFormBuilder.text('optionsLabel', 'Portions Label', null, false, null, 'inline', '', 'Select Portion (default)'),
  GenericFormBuilder.array('options', 'Portions', [
    GenericFormBuilder.group('menuItemOption', null, MenuItemOptionConstraints, null, 'inline')
  ], null, true),
  GenericFormBuilder.switch('includeAddons', 'Include Addons', null),
  // tslint:disable-next-line:max-line-length
  GenericFormBuilder.array('addonsCategories', 'Addons Options', [
    GenericFormBuilder.group('addonsCategory', null, MenuItemAddonsCategoryConstraints, null, 'row')
  ], (field) => field.includeAddons === true, true, 'block'),
  GenericFormBuilder.number('minAddonQuantity', 'Min addon quantity per menu item', [], false, (field) => field.includeAddons === true, 'inline', '1'),
  GenericFormBuilder.number('maxAddonQuantity', 'Max addon quantity per menu item', [], false, (field) => field.includeAddons === true, 'inline', '1'),
  GenericFormBuilder.array('workingHours', 'Happy Hours', [
    GenericFormBuilder.group('happyHourTimeFrames', null, TimeFrameConstraints, null, 'inline')
  ], (field) => field.type === 'happyHour', false),
  GenericFormBuilder.textarea('description', 'Description', []),
];

export const MenuTypeSpeciesMap: any = {
  'Chef\'s menu': { type: 'food', label: 'Food' },
  'Soft/Juice': { type: 'drink', label: 'Drink' },
  'Happy Hour': { type: 'food', label: 'Food' },
  Brunch: { type: 'food', label: 'Food' },
  Lunch: { type: 'food', label: 'Food' },
  Dinner: { type: 'food', label: 'Food' },
  Drinks: { type: 'drink', label: 'Drink' },
  Wine: { type: 'bottle', label: 'Bottle' },
  Cocktail: { type: 'drink', label: 'Drink' },
  Liquor: { type: 'bottle', label: 'Bottle' },
  default: { type: 'food', label: 'Food' },
};
